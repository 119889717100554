import { DeserializationFunc } from "@fifthsun/ui/utils";
import { Store, deserializeStoresArray } from "./common.js";

export interface UpdateStoresVariables {
  stores: Array<{
    id: string;
    priority?: number | null;
    color?: string | null;
    tagList?: string[] | null;
  }>;
}

export type UpdateStores = Store[];
export const deserializeUpdateStores = deserializeStoresArray as DeserializationFunc<UpdateStores>;
