import React, { useMemo } from "react";
import { BatchTable, BatchTableProps } from "../../../components/index.js";
import { ErrorBoundary } from "@sentry/react";
import { IDataGridPlugin, useGridStateSearchParamPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { GridOptions, GridReadyEvent } from "ag-grid-community";
import { StringParam, useQueryParam } from "@fifthsun/ui/api/queryParams";
import { BatchStatus } from "../../../api/core/index.js";
import { ErrorBoundaryFallback } from "@fifthsun/ui/sentry";

const statuses = [BatchStatus.New, BatchStatus.InProgress, BatchStatus.Pulled,
  BatchStatus.BrandingComplete, BatchStatus.DeliveredToPrinter];

export const BatchManagePage = () => {
  const [activeBatchId, setActiveBatchId] = useQueryParam("id", StringParam);
  const gridStateExtension = useGridStateSearchParamPlugin();
  const plugins:IDataGridPlugin[] = useMemo(() => [gridStateExtension], [gridStateExtension]);

  const gridProps = useMemo<Partial<BatchTableProps>>(() => {
    return ({
      onGridReady: (e: GridReadyEvent) => {
        e.api.setFilterModel({
          status: {
            filterType: "set",
            values: statuses
          }
        });

        e.api.onFilterChanged();
        e.api.refreshHeader();
      }
    } as GridOptions<any>);
  }, []);

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback}>
      <BatchTable
        {...gridProps}
        plugins={plugins}
        activeBatchId={activeBatchId}
        setActiveBatchId={setActiveBatchId}
      />
    </ErrorBoundary>
  );
};
