import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import { FinalOrderSyncStates, OrderSync, OrderSyncResult, OrderSyncStatus } from "./OrderSync.js";
import { InfoCircleTwoTone } from "@ant-design/icons";

const { Text } = Typography;

const maxConcurrentLoad = 1;

interface OrderSyncComposite {
  id: string,
  status: OrderSyncStatus,
  result?: OrderSyncResult
}

export interface D365SyncStepThreeParams {
  ids: string[],
  onFinish: () => void
}

export const D365SyncStepThree = (props: D365SyncStepThreeParams) => {
  const [orderSyncs, setOrderSyncs] = useState(Array<OrderSyncComposite>());

  const isOrderSyncsDifferent = (newOrderSyncs: OrderSyncComposite[]) => {
    const newData = JSON.stringify(newOrderSyncs);
    const oldData = JSON.stringify(orderSyncs);
    return newData !== oldData;
  };

  const onFinish = () => {
    props.onFinish();
  };

  const isDisabled = useMemo(() => {
    return orderSyncs.length === 0 ||
     !orderSyncs.every((od) => FinalOrderSyncStates.includes(od.status));
  }, [orderSyncs]);

  const onChange = (id: string, status: OrderSyncStatus, result: OrderSyncResult) => {
    const newOrderIdentifiers = orderSyncs.map((od) => {
      if (od.id === id) {
        od = {
          ...od,
          result,
          status
        };
      }
      return od;
    });

    if (isOrderSyncsDifferent(newOrderIdentifiers)) {
      setOrderSyncs(newOrderIdentifiers);
    }
  };

  useEffect(() => {
    const newOrderIdentifiers = props.ids.map((id) => ({
      id,
      status: OrderSyncStatus.Pending,
    }));

    setOrderSyncs(newOrderIdentifiers);
  }, [props.ids]);

  useEffect(() => {
    // do nothing is there are no order identifiers
    if (orderSyncs.length === 0) {
      return;
    }

    // do nothing if all order idenfitiers are in a final state
    if (orderSyncs.every((od) => FinalOrderSyncStates.includes(od.status))) {
      return;
    }

    // do nothing if we are already loading the max concurrent order identifiers
    const loadingCount = orderSyncs.filter((od) => od.status === OrderSyncStatus.Loading).length;
    if (loadingCount >= maxConcurrentLoad) {
      return;
    }

    // if we are here, recreate the array so that we can update the state
    let loadingAllowed = maxConcurrentLoad - loadingCount;
    const newOrderIdentifiers = orderSyncs.map((od) => {
      if (loadingAllowed > 0 && od.status === OrderSyncStatus.Pending) {
        od.status = OrderSyncStatus.Loading;
        loadingAllowed--;
      }
      return od;
    });

    setOrderSyncs(newOrderIdentifiers);
  }, [orderSyncs]);

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card title="Instructions">
          <ul>
            <li>Wait for all of the orders to be processed.</li>
            <li>Review the results.</li>
            <li>If there are errors, create a support ticket for further investigation.</li>
          </ul>
        </Card>
        <Row gutter={[16, 16]}>
          <Col span={1} />
          <Col span={7}>
            <Space>
              <Text strong>ZIFT Id</Text>
              <Tooltip title="Order Id used in ZIFT">
                <InfoCircleTwoTone />
              </Tooltip>
            </Space>
          </Col>
          <Col span={6}>
            <Space>
              <Text strong>Actions</Text>
              <Tooltip title="Requests sent to D365 (e.g. create, update, cancel)">
                <InfoCircleTwoTone />
              </Tooltip>
            </Space>
          </Col>
          <Col span={10}>
            <Space>
              <Text strong>Error Message</Text>
              <Tooltip title="Detailed error messages from ZIFT or D365">
                <InfoCircleTwoTone />
              </Tooltip>
            </Space>
          </Col>
          {orderSyncs.map((os) => (
            <OrderSync
              key={os.id}
              id={os.id}
              status={os.status}
              result={os.result}
              onChange={onChange}
            />
          ))}
        </Row>
        <Button type="primary" onClick={onFinish} disabled={isDisabled}>Done</Button>
      </Space>
    </>
  );
};
