import React, { useState } from "react";
import { Layout } from "antd";
import { Sider, LineItemFilter, LineItemDashboard } from "../../../components/index.js";

const { Content } = Layout;

export const LineItemDashboardPage = () => {
  const [filter, setFilter] = useState({});

  return (
    <Layout>
      <Sider>
        <LineItemFilter
          only={["dateRange", "shippingDateRange"]}
          onChange={setFilter}
        />
      </Sider>
      <Content>
        <LineItemDashboard filter={filter} />
      </Content>
    </Layout>
  );
};
