import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { BatchScheduleManagerModalStepProps } from "./BatchScheduleManagerModalProps.js";
import { Spin } from "../../../../components/Spin.js";
import { AlertTwoTone, WarningTwoTone } from "@ant-design/icons";
import { useForm, ValidateResult } from "react-hook-form";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";
import { ErrorPayload } from "@fifthsun/ui/utils";
import { 
  BatchesApi, GetBatches_AliasMap, prepareFilter, ScheduleBatches, ScheduleBatches_Warning, 
  ScheduleBatchesVariables 
} from "../../../../api/core/index.js";
import { useTimeZone } from "@fifthsun/ui";

import "./modals.scss";

export interface SubmitStepProps extends BatchScheduleManagerModalStepProps {
}

// TO DO: Replace mock implementation once we have a proper API to submit to
export const SubmitStep = (props: SubmitStepProps) => {
  const { 
    update, 
    submit, 
    state
  } = props;

  const { api, selectMode, schedule, note, errors, warnings, overrides } = state;

  const [lastRetry, setLastRetry] = useState<number>(-1);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [spinTip, setSpinTip] = useState<string>("");
  const [scheduleBatches, { isLoading } ] = BatchesApi.useScheduleBatchesMutation();

  const tz = useTimeZone();

  // replace with username from MSAL in the future
  const [initial, setInitial] = useState<string>('');

  const toggleOverride = useCallback((warning: { name: string, message: string}) => {
    const ndx = overrides ? overrides.findIndex((val) => val.name === warning.name) : -1;
    const updated_overrides:Array<{name: string, message: string}> = overrides ? [...overrides] : [];
    if (ndx >= 0) {
      const removed = updated_overrides.splice(ndx, 1);
      
      // Move the removed override back over to the warnings array, iff it isn't already there
      // this can occur across multiple submissions where the override is provided and so the
      // server doesn't send back that warning.
      const ndx2 = warnings ? warnings.findIndex((val) => val.name === warning.name) : -1;
      if (ndx2 < 0) {
        let updated_warnings = warnings;
        if (updated_warnings?.length) {
          updated_warnings.push(removed[0]);
          updated_warnings.sort((a, b) => a.message.localeCompare(b.message));
        } else {
          updated_warnings = removed;
        }
        update({ overrides: updated_overrides, warnings: updated_warnings });
      } else {
        update({ overrides: updated_overrides });
      }
    } else {
      updated_overrides.push(warning);
      updated_overrides.sort((a, b) => a.message.localeCompare(b.message));
      update({ overrides: updated_overrides });
    }
  }, [overrides, warnings, update]);

  const isOverriden = useCallback((warning_name: string) => {
    const ndx = overrides ? overrides.findIndex((val) => val.name === warning_name) : -1;
    return (ndx >= 0);
  }, [overrides]);

  useEffect(() => {
    if (!isDone && !isLoading && lastRetry !== retryCount) {
      setSpinTip(retryCount === 0 ? "Validating the Request" : "Processing the Request");
      setLastRetry(retryCount);

      const onlyCheckForWarnings = (retryCount === 0);
      const overrideReschedulingWarning = isOverriden("rescheduling_warning");
      const overrideRecordLimitWarning = isOverriden("record_limit_warning");
      const overrideModificationOfPastScheduleWarning = isOverriden("modification_of_past_schedule_warning");
      const overrideMismatchedFacilityWarning = isOverriden("mismatched_facility_warning");

      let selectIds:string[];
      if (selectMode === "selected") {
        selectIds = schedule ? api.getSelectedRows()!.map((_) => _.id!) 
          : api.getSelectedRows().filter((_:any) => _.schedule?.id).map((_) => _.id!);
      }

      const filterModel = api.getFilterModel();

      // ugly hack to fix store filter on "schedule all" for Black Friday 2023
      // todo: come back and fix this properly!
      if (filterModel.stores) {
        filterModel['stores.id'] = filterModel.stores;
        delete filterModel.stores;
      }

      const variables:ScheduleBatchesVariables = {
        filter: selectMode === "all" ? prepareFilter(filterModel, tz, GetBatches_AliasMap) : {
          id: {
            filterType: "set",
            condition1: {
              values: selectIds!
            }
          }
        },
        schedule: {
          facilityId: schedule?.facilityId,
          date: schedule?.date
        },
        note: note ? { content: note } : undefined,
        initial,
        onlyCheckForWarnings,
        overrideReschedulingWarning,
        overrideRecordLimitWarning,
        overrideModificationOfPastScheduleWarning,
        overrideMismatchedFacilityWarning
      };

      let request:Promise<ScheduleBatches>;
      if (selectMode === "selected" && selectIds!.length === 0) {
        request = Promise.reject(new Error(
          `There are no selected records eligible for ${schedule ? "scheduling" : "unscheduling"}`));
      } else {
        request = scheduleBatches(variables).unwrap();
      } 

      // if there are any warnings, it should fail with an HTTP status of 403 Forbidden
      request.then((response) => {
        setIsDone(retryCount !== 0);
        setSpinTip("");

        if (!onlyCheckForWarnings) {
          if (schedule) {
            const { facilityId, date } = schedule;

            const dateStr = [
              date.getFullYear(),
              (`0${date.getMonth() + 1}`).slice(-2),
              (`0${date.getDate()}`).slice(-2)
            ].join('-');

            toast.success(
              <span>
                Successfully added {response.updatedIds.length || 0} batch(es) to the &nbsp;
                <a target="_blank" href={`/schedules/details/${dateStr}/${facilityId}`} rel="noreferrer">
                  schedule
                </a> 
              </span>, { autoClose: 10000 } 
            );
          } else {
            toast.success(
              <span>
                Successfully removed {response.updatedIds.length || 0} batch(es) from their schedules
              </span>, { autoClose: 10000 }
            );
          }

          submit({
            result: {
              cancelled: false,
              ziftIds: response.updatedIds || [],
              schedule
            }
          });
        }
      }, (error: ErrorPayload<ScheduleBatches>) => {
        Sentry.captureException(error);
        setIsDone(false);
        setSpinTip(`An error occurred while attempting to validate (retry ${retryCount})...`);

        const { data } = error;
        const updated_errors:ScheduleBatches_Warning[] = data?.warnings?.length ? [] : [ { 
          name: error.statusText ?? error.status.toString(), 
          message: error.message
        } ];

        const updated_warnings:ScheduleBatches_Warning[] = [...(data?.warnings ?? [])];
        updated_warnings.sort((a, b) => a.message.localeCompare(b.message));

        update({ errors: updated_errors, warnings: updated_warnings });
      });
    }
  }, [api, note, initial, schedule, selectMode, update, lastRetry, setLastRetry, retryCount, 
    isDone, setIsDone, isLoading, setSpinTip, isOverriden, submit, scheduleBatches, tz
  ]);

  const bodyStyle:React.CSSProperties = {
    minHeight: "30px"
  };

  const warningsAndOverrides = useMemo<Array<{name: string, message: string, overriden: boolean}>>(() => {
    const overridenWarnings:Array<{name:string, message:string, overriden:boolean}> = overrides
      ?.map((_) => ({ ..._, overriden: true })) ?? [];

    const unoverridenWarnings:Array<{name:string, message:string, overriden:boolean}> = warnings
      ?.filter((_) => overrides && overrides.findIndex((o) => o.name === _.name) < 0)
      .map((_) => ({ ..._, overriden: false })) ?? [];

    const result = [...overridenWarnings, ...unoverridenWarnings];
    result.sort((a, b) => a.message.localeCompare(b.message));

    return result;
  }, [overrides, warnings]);

  const { register, handleSubmit, watch, getValues, formState: { errors: formErrors } } = useForm();
  const watchIncludeNote = watch("include-note", false);

  const onSubmit = useCallback(() => {
    if (watchIncludeNote && getValues("note") !== note) {
      update({ note: getValues("note") });
    } else if (!watchIncludeNote) {
      update({ note: undefined });
    }

    // causes the useLayoutEffect above to trigger and submit the request
    setRetryCount(retryCount + 1); 
  }, [note, watchIncludeNote, update, getValues, retryCount, setRetryCount]);

  return (
    <>
      <Modal.Body>
        <Spin spinning={isLoading} tip={spinTip}>
          <div style={bodyStyle}>
            <form onSubmit={handleSubmit(onSubmit)}>
              { (isDone && (
                <div className="success">
                  <div className="message">
                    Request Successfully Processed!
                  </div>
                </div>
              )) || undefined}

              { /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */ }
              {!isDone && !isLoading && (
                <>
                  { (errors?.length && errors.map((e, _ndx) => (
                    <div key={e.name} className="error">
                      <div className="status-bar">
                        <AlertTwoTone className="icon" twoToneColor={["black", "red"]} />
                      </div>
                      <div className="message">{e.message}</div>
                    </div>
                  ))) || undefined }

                  { (warningsAndOverrides.length && warningsAndOverrides.map((w, _ndx) => (
                    <div key={w.name} className="warning field">
                      <div className="status-bar">
                        <WarningTwoTone className="icon" twoToneColor={["black", "yellow"]} />
                        <input 
                          type="checkbox" 
                          className="override" 
                          {...register(w.name, {
                            onChange: () => toggleOverride(w),
                            value: isOverriden(w.name),
                            required: "Please acknowledge before proceeding"
                          })} 
                        />
                      </div>
                      <div className="content">
                        <div className="message">
                          {w.message}. 
                          If you wish to proceed, please check the checkbox to confirm the override.
                        </div>
                        { formErrors[w.name] && (
                          <p role="alert">{formErrors[w.name]?.message as string}</p>
                        ) }
                      </div>
                    </div>
                  ))) || undefined}

                  <div id="initial-field" className="field">
                    <div className="gutter" />
                    <div className="content">
                      <label htmlFor="initial-input">User Initials: </label>
                      <input
                        id="initial-input"
                        {...register("initial", {
                          required: "User Initials Required",
                          minLength: {
                            value: 2,
                            message: "Initial must be at least 2 letters."
                          },
                          value: initial,
                          onChange: () => {
                            setInitial(getValues("initial"));
                          }
                        })}
                      />

                      { formErrors.initial && (
                        <p role="alert">{formErrors.initial.message as string}</p>
                      ) }
                    </div>
                  </div>

                  <div id="note-field" className="field">
                    <div id="include-note-field" className="field gutter">
                      <input 
                        id="include-note-checkbox" 
                        type="checkbox" 
                        {...register("include-note")} 
                        defaultChecked
                      />
                    </div>

                    <div id="note-field-content-area">
                      <label id="include-note-label" htmlFor="include-note">Include Note</label>

                      { watchIncludeNote && (
                        <>
                          <textarea
                            id="note-textarea"
                            data-testid="note"
                            {...register("note", {
                              shouldUnregister: true,
                              value: note,
                              validate: (value:string):ValidateResult => {
                                if (watchIncludeNote) {
                                  if (/^\s*$/.exec(value)) {
                                    return "Note cannot be empty";
                                  }
                                }
                                return undefined;
                              } })} 
                          />
                          { formErrors.note && (
                            <p role="alert">{formErrors.note.message as string}</p>
                          ) }
                        </>
                      )}
                    </div>
                  </div>                      
                </>
              )}
              { /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */ }
            </form>
          </div>
        </Spin>
      </Modal.Body>
      <Modal.Footer>
        {!isDone && !isLoading && (
          <Button 
            variant="primary"  
            onClick={handleSubmit(onSubmit)} 
          >
            Submit
          </Button>
        )}
      </Modal.Footer>  
    </>
  );
};

export default SubmitStep;
