import React from "react";
import {
  ColDef, ICellRendererParams, KeyCreatorParams, SetFilterValuesFuncParams, ValueFormatterParams
} from "ag-grid-enterprise";
import { DateCellRenderer, DateTimeCellRenderer, DateTimeCellRendererParams, ProgressCellRenderer, TagCellRenderer, TagsCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { StoresCellRenderer } from "./renderers/index.js";
import { Tag } from "antd";
import PinCellRenderer from "./renderers/PinCellRenderer.js";
import PinColumnHeaderTemplate from "./headers/PinColumnHeaderTemplate.js";
import { Store, Facility, BatchStatus, Batch } from "../../../api/core/index.js";
import { startCase, toLower } from "lodash";
import { DateTime } from "luxon";

export interface CreateBatchColDefsProps {
  facilities: Facility[];
  stores: Store[];
  includeCompoundPriorityInSort?: boolean;
  includeSchedulePinnedField?: boolean;
}

export const createBatchColDefs = ({ facilities, stores, includeCompoundPriorityInSort,
  includeSchedulePinnedField }: CreateBatchColDefsProps): Array<ColDef<Batch>> => {
  // statuses in the order we want them displayed in the widget (match floor view as close as possible)
  const orderedStatuses = [
    BatchStatus.New, BatchStatus.Pulled, BatchStatus.InProgress,
    BatchStatus.BrandingComplete, BatchStatus.DeliveredToPrinter,
    BatchStatus.Complete, BatchStatus.Deleted, BatchStatus.Canceled];
  // append any other statuses to the end
  const allStatuses = orderedStatuses.concat(Object.values(BatchStatus).filter((s) => !orderedStatuses.includes(s)));
  const sortIndexOffset = (includeSchedulePinnedField ? 1 : 0) + (includeCompoundPriorityInSort ? 1 : 0);

  const colDefs:Array<ColDef | null | undefined> = [
    /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
    (includeSchedulePinnedField && {
      headerName: "Pinned",
      field: "schedulePinned",
      pinned: "left",
      lockPinned: true,
      sort: "desc",
      sortIndex: 0,
      filter: false,
      floatingFilter: false,
      width: 85,
      minWidth: 85,
      headerComponentParams: { template: PinColumnHeaderTemplate },
      cellRenderer: PinCellRenderer,
    }) || undefined,
    /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

    {
      field: "status",
      width: 140,
      filter: "agSetColumnFilter",
      filterParams: {
        values: allStatuses.map((v) => v.toLocaleLowerCase()),
        suppressSorting: true, // preserve order we provide, rather than alpha sort
        keyCreator: (params: KeyCreatorParams<BatchStatus>) => {
          return params.value;
        },
        valueFormatter: (params: ValueFormatterParams<BatchStatus>) => {
          switch (params.value) {
          case BatchStatus.New.toLocaleLowerCase():
            return "Batched"; // call new batched to match floor view
          default:
            return startCase(params.value);
          }
        }
      },
      cellRenderer: TagCellRenderer,
      cellRendererParams: {
        color: ({ value }: any) => {
          switch (value.toUpperCase()) {
          case BatchStatus.New:
            return "geekblue";
          case BatchStatus.InProgress:
            return "lime";
          case BatchStatus.Complete:
            return "green";
          default:
            return "blue";
          }
        },
        label: ({ value }: any) => {
          switch (value) {
          case BatchStatus.New:
            return "Batched"; // call new batched to match floor view
          default:
            return startCase(toLower(value));
          }
        }
      }
    },
    {
      field: "compoundPriority",
      filter: "agNumberColumnFilter",
      sort: includeCompoundPriorityInSort ? "desc" : undefined,
      sortIndex: includeCompoundPriorityInSort ? (includeSchedulePinnedField ? 1 : 0) : undefined,
    },
    {
      field: "progress",
      filter: "agNumberColumnFilter",
      cellRenderer: ProgressCellRenderer,
    },
    {
      field: "stores",
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        values: (params: SetFilterValuesFuncParams<Batch, Store>) => {
          // hacky solution until I can find a way to properly assign the filter a complex value
          // complex values are supported - but the success function for asynchronously loading values
          // only takes a string[]...
          params.success(stores);
        },
        comparator: (a: Store, b: Store) => {
          if (!a.name && !b.name) { return 0; }
          if (!a.name) { return -1; }
          if (!b.name) { return 1; }
          return a.name.localeCompare(b.name);
        },
        keyCreator: (params: KeyCreatorParams<Batch, Store>) => {
          return params.value?.id;
        },
        valueFormatter: (params: ValueFormatterParams<Batch, Store>) => {
          return params.value?.name ?? "(undefined)";
        }
      },
      cellRenderer: StoresCellRenderer
    },
    {
      field: "minShipBy",
      filter: "agDateColumnFilter",
      cellRenderer: DateTimeCellRenderer,
      cellRendererParams: {
        formatOpts: DateTime.DATE_SHORT,
        icon: ({ value }: any) => {
          if ((new Date(value)).getTime() < new Date().getTime()) {
            return "warning";
          }
          return undefined;
        },
      } as DateTimeCellRendererParams
    },
    {
      field: "ziftId",
      headerName: "Batch Id",
      filter: "agTextColumnFilter"
    },
    {
      field: "orderExternalIds",
      headerName: "Sales Order",
      filter: false,
      sort: "asc",
      sortIndex: sortIndexOffset,
      cellRenderer: TagsCellRenderer
    },
    {
      field: "boxes",
      filter: false,
      sort: "asc",
      sortIndex: sortIndexOffset + 1,
      cellRenderer: (params: ICellRendererParams) => {
        if (!params.value?.length) {
          return null;
        }

        const boxes = (params.value as string[]).join(", ");
        const total = params.data.orderBoxes?.length || 0;
        const tagStyle: React.CSSProperties = {
          margin: "0px"
        };

        return (
          <span>
            <Tag style={tagStyle}>{boxes}</Tag> of <Tag style={tagStyle}>{total}</Tag>
          </span>
        );
      }
    },
    {
      headerName: "Batched Facility",
      field: "autoFacility.name",
      filter: "agSetColumnFilter",
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          params.success(
            facilities.filter((f) => f.name).map((f) => f.name!)
          );
        },
      }
    },
    {
      field: "note.content", filter: "agTextColumnFilter", sortable: false
    },
    {
      field: "schedule.date",
      filter: "agDateColumnFilter",
      cellRenderer: DateCellRenderer
    },
    {
      field: "schedule.facility.name",
      headerName: "Schedule Facility Name",
      filter: "agSetColumnFilter",
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          params.success(
            facilities.filter((f) => f.name).map((f) => f.name!)
          );
        }
      }
    },
    {
      field: "orderBoxes",
      cellRenderer: TagsCellRenderer,
      filter: false
    },
    {
      field: "flags", hide: true, sortable: false, cellRenderer: TagsCellRenderer
    },
    {
      field: "type", hide: true, filter: "agTextColumnFilter", sortable: false, cellRenderer: TagCellRenderer
    },
    {
      headerName: "Batched Facility Id",
      field: "autoFacility.id",
      hide: true,
      filter: "agSetColumnFilter",
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          // While Set Filters will supposedly work with complex data,
          // apparently they haven"t bothered to update the SetFilterValuesFuncParams
          // interface to support them... so we need to get a bit hacky and store the
          // json of each object. We can then deserialize in the KeyCreator and ValueFormatter
          // functions...
          // https://stackoverflow.com/questions/72948039/how-to-asynchronously-load-complex-set-filter-values
          params.success(facilities.map((f) => JSON.stringify(f)));
        },
        keyCreator: (params: KeyCreatorParams) => {
          const v: Facility = JSON.parse(params.value);
          return v.id;
        },
        valueFormatter: (params: ValueFormatterParams) => {
          const v: Facility = JSON.parse(params.value);
          return `[${v.name}] ${v.id}`;
        }
      }
    },
    {
      field: "schedule.id", filter: "agTextColumnFilter", hide: true
    },
    {
      field: "priority", filter: "agNumberColumnFilter"
    },
    {
      field: "createdAt", filter: "agDateColumnFilter", cellRenderer: DateTimeCellRenderer
    },
    {
      field: "maxShipBy",
      filter: "agDateColumnFilter",
      cellRenderer: DateTimeCellRenderer,
      cellRendererParams: {
        formatOpts: DateTime.DATE_SHORT,
        icon: ({ value }: any) => {
          if ((new Date(value)).getTime() < new Date().getTime()) {
            return "warning";
          }
          return undefined;
        },
      }
    },
    {
      field: "updatedAt", filter: "agDateColumnFilter", cellRenderer: DateTimeCellRenderer
    },
    {
      field: "itemsCount", filter: "agNumberColumnFilter"
    },
    {
      field: "printsCount", filter: "agNumberColumnFilter"
    },
    {
      field: "completedItemsCount", filter: "agNumberColumnFilter"
    },
    {
      field: "lastConfirmPullAt",
      filter: "agDateColumnFilter",
      cellRenderer: DateTimeCellRenderer,
      hide: true
    },
    {
      field: "lastConfirmPullUserId",
      filter: "agTextColumnFilter",
      hide: true
    },
    {
      field: "scheduledAt",
      filter: "agDateColumnFilter",
      cellRenderer: DateTimeCellRenderer,
      hide: true
    },
    {
      field: "scheduledUserId",
      filter: "agTextColumnFilter",
      hide: true
    },
    {
      field: "pullBox",
      filter: "agTextColumnFilter",
      hide: true
    },
    {
      field: "idle",
      filter: "agSetColumnFilter",
      cellRenderer: (params: ICellRendererParams) => {
        return params.value ? "Yes" : "No";
      },
      filterParams: {
        values: (params: SetFilterValuesFuncParams) => {
          params.success(["true", "false"]);
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value === "true" ? "Yes" : "No";
        }
      },
      hide: true
    }
  ];

  return colDefs.filter((_) => !!_) as [ColDef];
};
