import { ColDef, ColGroupDef } from "ag-grid-community";

export const quotumUsageColumnDefs: Array<ColDef | ColGroupDef> = [
  {
    field: "id",
    hide: true,
    filter: "agTextColumnFilter"
  },
  {
    field: "ziftId",
    filter: "agTextColumnFilter"
  }
];

export default quotumUsageColumnDefs;
