import { AgSortOrder } from "../AgGrid.js";
import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "@fifthsun/ui/utils";
import { deserializeStore, Store } from "../Stores/common.js";
import { deserializeFacility, Facility } from "../Facilities/common.js";

export interface Order {
  id: string;
  ziftId: string;
  shippingAddressId: string;
  createdAt: Date;
  updatedAt: Date;
  storeId?: string | null;
  lineItemsCount?: number | null;
  status?: OrderStatus | null; 
  shipBy?: Date | null;
  facilityId?: string | null;
  progress?: number | null;
  meta?: object | null; // jsonb - TO DO: create interface that captures potential meta data
  erpId?: string | null;
  auditedAt?: Date | null;
  completedAt?: Date | null;
  boxes?: string[] | null;
  shippingService?: string | null;
  shippingCarrier?: string | null;
  printerModelCounts?: Record<string, string> | null; // dictionary - printer model is index to counts
  externalId?: string | null;

  // associated entities
  store?: Store;
  facility?: Facility;
}

export const OrderDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  shipBy: deserializeDate,
  auditedAt: deserializeDate,
  completedAt: deserializeDate,

  store: deserializeStore,
  facility: deserializeFacility
});

export const deserializeOrder = deserialize<Order>(OrderDeserializationSchema);
export const deserializeOrdersArray = deserializeArrayOf<Order>(OrderDeserializationSchema);

export enum OrderStatus {
  New = "new",
  Active = "active",
  Pending = "pending",
  Complete = "complete",
  Canceled = "canceled",
  Invalid = "invalid",
}

export interface OrderFilterInput {
  phone?: string | null;
  from?: Date | null;
  to?: Date | null;
  shipBefore?: Date | null;
  shipAfter?: Date | null;
  status?: Date | null;
  updatedBefore?: Date | null;
  updatedAfter?: Date | null;
}

export interface OrderSort {
  createdAt?: AgSortOrder | null;
  updatedAt?: AgSortOrder | null;
  status?: AgSortOrder | null;
  progress?: AgSortOrder | null;
  shipBy?: AgSortOrder | null;
  id?: AgSortOrder | null;
  ziftId?: AgSortOrder | null;
}

export interface Address {
  attention: string;
  city: string;
  company: string;
  countryCode: string;
  name: string;
  firstName: string;
  lastName?: string | null;
  phone?: string | null;
  provinceCode: string;
  street: string;
  postalCode: string;
  lat: number;
  lon: number;

  /* Legacy
  name_first: string;
  name_last: string;
  street1: string;
  street2: string;
  */
}
