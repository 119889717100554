import { TimeseriesValue, deserializeTimeseriesValuesArray } from "../common.js";
import { DeserializationFunc } from "@fifthsun/ui/utils";
import { MetricsSource } from "./common.js";

export type GetMetricsFunction = 'count' | 'sum';
export type GetMetricsResolution = 'hour' | 'day' | 'week' | 'month' | 'year' | 'century' | 'decade';

export interface GetMetricsVariables {
  from?: Date | null;
  to?: Date | null;
  source?: MetricsSource | null;
  timeColumn?: string | null; // TO DO: Create enum
  aggColumn?: string | null;
  aggFunction?: GetMetricsFunction | null;
  resolution?: GetMetricsResolution | null;
  timezone?: string | null;
  groupBy?: string[] | null;
}

export type GetMetrics = TimeseriesValue[];
export const deserializeGetMetrics =
  deserializeTimeseriesValuesArray as DeserializationFunc<GetMetrics>;
