import { useMemo } from "react";
import { AgGridDatasourceProps, GetLineItems, GetLineItemsVariables, GetLineItemsVariables_Options, LineItemsApi, useAgGridDatasource } from "../../../api/core/index.js";
import { Zone } from "luxon";
import { useTimeZone } from "@fifthsun/ui";

export interface LineItemsDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetLineItemsVariables_Options, GetLineItemsVariables, GetLineItems>, "endpoint" | "timeZone"> {
    timeZone?: Zone | string;
  }

export const DefaultLineItemsDatasourceProps = Object.freeze<LineItemsDatasourceProps>({
  options: {
    count: true,
    include: ["store", "order", "blank"]
  }
});

export function useLineItemsDatasource(props: LineItemsDatasourceProps = DefaultLineItemsDatasourceProps) {
  const timeZone = useTimeZone();
  const activeTimeZone = props?.timeZone ?? timeZone;
  const datasourceProps = useMemo<AgGridDatasourceProps<GetLineItemsVariables_Options>>(() => ({
    timeZone: activeTimeZone,
    ...props,
    endpoint: LineItemsApi.endpoints.getLineItems,
    options: {
      ...DefaultLineItemsDatasourceProps.options,
      ...props.options
    },
  }), [props, activeTimeZone]);

  return useAgGridDatasource<GetLineItemsVariables_Options>(datasourceProps);
}

export default useLineItemsDatasource;
