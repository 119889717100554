import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Select } from 'antd';
import { AgSetFilterCondition } from "../../../api/core/index.js";
import { IFilterReactComp } from "ag-grid-react";
import { IFilterParams } from "ag-grid-community";
import PrintersApi from "../../../api/core/Printers/PrintersApi.js";

export const PrinterFilter = forwardRef((props: IFilterParams, ref) => {
  const isInitialRender = useRef(true);

  const [value, setValue] = useState<string[]>([]);

  const filterModel = useMemo<AgSetFilterCondition | null>(() => {
    // see if we can construct a valid condition
    let conditionFilter: AgSetFilterCondition | null = null;
    if (value.length) {
      conditionFilter = {
        filterType: 'set',
        values: value
      };
    }
    return conditionFilter;
  }, [value]);

  const { data, isFetching: loading } = PrintersApi.useGetPrintersQuery({ limit: 0 });

  const options = useMemo(() => {
    return data?.rows.filter((p) => p.model).map((p) => {
      return {
        label: p.model,
        value: p.id
      };
    }).sort((a, b) => a.label.localeCompare(b.label)) ?? [];
  }, [data]);

  useImperativeHandle(ref, (): IFilterReactComp => ({
    // todo: implement if we care to support front end filtering
    doesFilterPass(_params) {
      return true;
    },

    isFilterActive() {
      return filterModel !== null;
    },

    getModel(): AgSetFilterCondition | null { 
      return filterModel;
    },

    setModel(model: AgSetFilterCondition | null) {
      if (model?.values) {
        setValue(model.values);
      } else {
        setValue([]);
      }
    }
  }), [filterModel]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    props.filterChangedCallback?.();
  }, [filterModel, props]);

  return (
    <>
      <Select
        mode="multiple"
        value={value}
        onChange={setValue}
        style={{ width: '100%' }}
        loading={loading}
        options={options}
      />
    </>
  );
});

PrinterFilter.displayName = "PrinterFilter";
