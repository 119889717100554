import React, { useMemo } from "react";
import { IDataGridPlugin, useGridStateSearchParamPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { StoreDataGrid } from "../../../components/index.js";
import { Authenticated } from "@fifthsun/ui";

export const StoreManagePage = () => {
  const gridStateExtension = useGridStateSearchParamPlugin();
  const plugins:IDataGridPlugin[] = useMemo(() => [gridStateExtension], [gridStateExtension]);

  return (
    <Authenticated>
      <StoreDataGrid plugins={plugins} />
    </Authenticated>
  );
};
