import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "@fifthsun/ui/utils";

export const CORE_AUTHORITY = import.meta.env.REACT_APP_CORE_AUTHORITY || "localhost:3131";
export const CORE_PROTOCOL = import.meta.env.REACT_APP_CORE_PROTOCOL || "http";
export const CORE_BASE_URL = `${CORE_PROTOCOL}://${CORE_AUTHORITY}`;

export const MAUTHRA_AUTHORITY = import.meta.env.REACT_APP_MAUTHRA_AUTHORITY || "localhost:3333";

// The resolutions which are valid on the server-side for truncating dates
export enum DateTimeResolution {
  Millenium = 'millennium',
  Century = 'century',
  Decade = 'decade',
  Year = 'year',
  Quarter = 'quarter',
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
  Milliseconds = 'milliseconds',
  Microseconds = 'microseconds'
}

export interface DeprecatedTimeseriesMetadata {
  key: string;
  value: string;
}

export type TimeseriesMetadata = TimeseriesKeyValue[];

export interface TimeseriesKeyValue {
  key: string;
  value?: string;
}

export interface TimeseriesValue {
  time: Date;
  data: DeprecatedTimeseriesMetadata;
  metadata: TimeseriesMetadata;
  value: number;
}

export const TimeseriesValueDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  time: deserializeDate
});

export const deserializeTimeseriesValue =
  deserialize<TimeseriesValue>(TimeseriesValueDeserializationSchema);

export const deserializeTimeseriesValuesArray =
  deserializeArrayOf<TimeseriesValue>(TimeseriesValueDeserializationSchema);
