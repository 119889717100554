import { ColDef, ColGroupDef } from "ag-grid-community";
import { TagCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { DateTimeCellRenderer, TagsCellRenderer } from "@fifthsun/ui/ag-grid/renderers";

export const storeColumnDefs: /*ReadonlyArray */ Array<ColDef | ColGroupDef> = [
  {
    field: "id",
    hide: true,
    filter: "agTextColumnFilter"
  },
  {
    field: "name",
    filter: "agTextColumnFilter"
  },
  {
    field: "ziftId",
    filter: "agTextColumnFilter"
  },
  {
    field: "createdAt",
    headerName: "Created At",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "priority",
    filter: "agNumberColumnFilter"
  },
  {
    field: "color",
    cellRenderer: TagCellRenderer,
    cellRendererParams: {
      color: ({ value }: any) => value,
    }
  }, 
  {
    field: "tagList",
    cellRenderer: TagsCellRenderer
  }
];

export default storeColumnDefs;
