import CoreApi from "../CoreApi.js";
import { GetFacilities, GetFacilitiesVariables, deserializeGetFacilities } from "./GetFacilities.js";
import { transformResponse, transformErrorResponse } from "@fifthsun/ui/utils";
import { UpdateFacilityCapacity, UpdateFacilityCapacityVariables, deserializeUpdateFacilityCapacity } from "./UpdateFacilityCapacity.js";

// Extend the CoreApi with /facilities endpoints
export const FacilitiesApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilities: builder.query<GetFacilities, GetFacilitiesVariables>({
      query: (params) => ({
        method: "POST",
        url: "/facilities",
        body: params
      }),
      transformResponse: transformResponse(deserializeGetFacilities),
      transformErrorResponse: transformErrorResponse("Core", "getFacilities"),
    }),

    updateFacilityCapacity: builder.mutation<UpdateFacilityCapacity, UpdateFacilityCapacityVariables>({
      query: (params) => ({
        method: "PATCH",
        url: "/facilities",
        body: params
      }),
      transformResponse: transformResponse(deserializeUpdateFacilityCapacity),
      transformErrorResponse: transformErrorResponse("Core", "getFacilities"),
    }),
  }),
});

export default FacilitiesApi;
