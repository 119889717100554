 
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { isArray, sum } from "lodash";
import { Badge, Popover, Space } from "antd";
import "./Progress.scss";

export interface ProgressValue {
  value: number;
  color?: string;
  title?: string;
  percentage?: number;
}

export interface ProgressProps {
  className?: string;
  total: number;
  value: number | ProgressValue | ProgressValue[];
  loading?: boolean;
  showDetails?: boolean;
  title?: string;
}

export const Progress = (props: ProgressProps) => {
  const { className, loading, title, total, value, showDetails } = props;

  if (loading) return <LoadingOutlined />;

  const classNames = ["mad-progress", className];
  const values = (isArray(value) ? value : [ { value } ]) as ProgressValue[];

  if (showDetails) classNames.push("detailed");

  // Add all percentages to size the bars container
  const totalPercentage = sum(
    values.map(
      (progressValue) => progressValue 
        ? progressValue.percentage ?? (progressValue.value / total) * 100.0 
        : 0.0
    )
  );
  const totalValue = sum(values.map(({ value: v }) => v));

  const popoverContent = values.map((progressValue) => {
    return (
      <div key={progressValue.title}>
        <Badge 
          overflowCount={Number.MAX_VALUE}
          count={progressValue?.value}
          style={{ color: '#000', backgroundColor: progressValue?.color }}
          text={progressValue?.title}
        />
      </div>
    );
  });

  const popoverContentWrapper = (
    <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
      {popoverContent}
    </Space>
  );

  const bars = values.map((progressValue) => {
    const percentage =
      progressValue?.percentage ?? (progressValue?.value / total) * 100.0;
    const barPercentage = (percentage / totalPercentage) * 100.0;
    const backgroundColor = progressValue?.color ?? "blue";
    return (
      <div
        key={`progress-bar-${progressValue.title}`}
        className="progress-bar"
        style={{ backgroundColor, width: `${barPercentage}%` }}
      >
        <span key="title" className="progress-bar-title">{progressValue?.title}</span>
        <span key="value" className="progress-bar-value">{progressValue?.value}</span>
      </div>
    );
  });

  return (
    <div className={classNames.join(" ")}>
      <div className="progress-header">{title}</div>
      <div className="progress-bars-outer">
        <Popover content={popoverContentWrapper} title={title}>
          <div
            key="progress-bars"
            className="progress-bars"
            style={{ width: `${totalPercentage}%` }}
          >
            {bars}   
          </div>
        </Popover>
      </div>
      <div className="progress-footer">
        <span className="progress-total-value">{totalValue}</span>
        <span className="progress-total">{total}</span>
        <span className="progress-total-percentage">
          {totalPercentage.toFixed(0)}%
        </span>
      </div>
    </div>
  );
};
