import React from "react";
import { readableColor } from "polished";
import "./index.scss";

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  color?: string;
}

export const Tag = React.memo(({ className, color, children, style, ...rest }: TagProps) => {
  const backgroundColor = color ?? "#D9D9D9";
  const textColor = readableColor(backgroundColor);
  const defaultStyle = {
    "--mad-tag-color-canvas": backgroundColor,
    "--mad-tag-color-fg": textColor,
  };
  return (
    <span className={["mad-tag", className].join(" ").trim()} style={{ ...defaultStyle, ...style }} {...rest}>
      {children}
    </span>
  );
});

Tag.displayName = "Tag";
