import { DeserializationSchema, deserialize } from "@fifthsun/ui/utils";
import { Order, deserializeOrdersArray } from "./common.js";

export interface FindOrders extends Array<Order> {}

export interface FindOrdersVariables {
  id: string
}

export const FindOrdersDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  deserializeOrdersArray
});

export const deserializeFindOrders = deserialize<FindOrders>(FindOrdersDeserializationSchema);
