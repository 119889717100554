import React, { HTMLAttributes, useEffect, useId, useState } from "react";
import { Layout, Menu } from "antd";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { InputLabel, MenuItem, Select, TextareaAutosize, TextField, FormControl, FormControlLabel, Switch, Button, Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { RunScriptResult, ScriptsApi } from "../../api/core/Scripts/ScriptsApi.js";
import { AddressEdit, defaultAddress } from "./AddressEdit.js";

const { Sider } = Layout;
const { Content } = Layout;

interface SettingsPageProps {
  className?: string;
}

const SelfHelpPageLayout = (props: HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const { page } = useParams() as any;

  const items: Record<string, { title: string, component: () => React.ReactNode }> = {
    'quicklookup': {
      title: 'Quick Lookup',
      component: () => <QuickLookup />,
    },
    'orderstatus': {
      title: 'Order Status',
      component: () => <OrderStatus />,
    },
    'webhooks': {
      title: 'Webhooks',
      component: () => <SendWebhooks />,
    },
    'revalidate': {
      title: 'Revalidate',
      component: () => <Revalidate />,
    },
    'shipping': {
      title: 'Shipping',
      component: () => <EditPostage />,
    },
  };

  const content = items[page] || items.quicklookup;

  return (
    <SelfHelpPageContainer>
      <Layout className={props.className}>
        <Sider theme="light">
          <Menu
            mode="inline"
            selectedKeys={[page]}
            defaultSelectedKeys={[page]}
            onSelect={({ key }) => navigate(`/selfhelp/${key}`)}
            items={Object.entries(items).map(([key, item]) => ({ key, label: item.title }))}
          />
        </Sider>
        <Content>{content.component()}</Content>
      </Layout>
    </SelfHelpPageContainer>
  );
};

export const SelfHelpPage = ({ className }: SettingsPageProps) => (
  <Routes>
    <Route
      path="/:page"
      element={<SelfHelpPageLayout className={className} />}
    />
    <Route
      path="/"
      element={<SelfHelpPageLayout className={className} />}
    />
  </Routes>
);

const SelfHelpPageContainer = styled.div`
  padding: 20px;

  .ant-layout-content {
    padding: 20px;
    background-color: white;
  }
`;


// TODO: Support clicking IDs to trigger quick-lookup, similar to what ZIFT supports today
const ZiftId = ({ id }: { id: string }) => {
  return <code>{id}</code>;
};

const textToIds = (text: string): string[] => {
  return text.replace(/[\t ,]/g, '') // Eliminate whitespace and certain junk characters that don't appear in NDB IDs
    .split('\n')
    .filter((line) => line); // Filter out empty lines
};

function IdListEdit({ text, setText, recordKind, isDatastore }: 
{ text: string, setText: (text: string) => void, recordKind?: string, isDatastore?: boolean }) {
  return <pre>
    <TextareaAutosize style={{width: '100%'}} minRows={5} maxRows={25} value={text}
      placeholder={recordKind ? `Enter one ${recordKind} on each line` : "Enter one ID on each line"}
      onChange={(e) => {
        // Strip characters not permitted in datastore IDs to make entry easier
        setText(isDatastore ? e.target.value.replace(/[ \t,'"]/g, '') : e.target.value);
      }}
      onBlur={(e) => {
        setText(e.target.value);
      }}
    />
  </pre>;
}

const OrderStatus = () => {
  const [exec, result] = ScriptsApi.useExecMutation();
  const [text, setText] = useState("");

  return <Stack gap={1}>
    <Stack direction="row" gap={1}>
      <Button disabled={!text} variant='outlined' onClick={() => {
        exec({ name: 'update_status', input: { orders: textToIds(text), status: 'complete' } });
      }}>Complete orders</Button>
      <Button disabled={!text} variant='outlined' onClick={() => {
        exec({ name: 'update_status', input: { orders: textToIds(text), status: 'active' } });
      }}>Uncomplete orders</Button>
      <Button disabled={!text} variant='outlined' onClick={() => {
        exec({ name: 'update_status', input: { orders: textToIds(text), status: 'cancelled' } });
      }}>Cancel orders</Button>
    </Stack>
    <Divider />
    <IdListEdit text={text} setText={setText} recordKind="Order ID" />
    <SelfHelpOutput result={result}>
      {(output) => <>
        <Stack direction="row" justifyContent="space-evenly">
          <Stack spacing={0}>
            <span>Successfully updated</span>
            {output.updated.map((id: string) => <ZiftId key={id} id={id} />)}
          </Stack>
          <Stack spacing={0}>
            <span>Already set</span>
            {output.already_set.map((id: string) => <ZiftId key={id} id={id} />)}
          </Stack>
          <Stack spacing={0}>
            <span>Unable to set</span>
            {output.failed.map((id: string) => <ZiftId key={id} id={id} />)}
          </Stack>
        </Stack>
      </>}
    </SelfHelpOutput>
  </Stack>;
};

interface ScriptResult {
  isUninitialized: boolean,
  isLoading: boolean,
  data?: RunScriptResult,
}

const SendWebhooks = () => {
  const [exec, result] = ScriptsApi.useExecMutation();
  const [text, setText] = useState("");
  const [webhookType, setWebhookType] = useState("updated");
  const webhookTypeId = useId();

  return <Stack gap={1}>
    <Button disabled={!text} variant='outlined' onClick={async () => {
      const result = await exec({ name: 'send_webhooks', input: {
        orders: textToIds(text),
        webhook_type: webhookType,
      }});
      const output = result.data?.output;
      if (output && Object.keys(output).length > 0) {
        toast.success(<p>Sent &lsquo;{webhookType}&rsquo; webhooks:.</p>);
      } else {
        toast.warn(<p>No webhooks sent.</p>);
      }

    }}>Send webhooks</Button>
    <Divider />
    <FormControl>
      <InputLabel htmlFor={webhookTypeId}>Webhook type</InputLabel>
      <Select id={webhookTypeId} size='small' label="Webhook type" value={webhookType} onChange={(e) => setWebhookType(e.target.value)}>
        <MenuItem value='created'>created</MenuItem>
        <MenuItem value='updated'>updated</MenuItem>
        <MenuItem value='ready'>ready</MenuItem>
        <MenuItem value='cancelled'>cancelled</MenuItem>
      </Select>
    </FormControl>
    <IdListEdit text={text} setText={setText} recordKind="Order ID" />
    <SelfHelpOutput result={result}>
      {(output) => {
        return <pre>
          Results:<br/>
          {Object.entries(output).map(([id, entry]: [string, any]) => {
            return `${entry.name} [${id}]: ${entry.records} sent`;
          }).join('\n')}
        </pre>;
      }}
    </SelfHelpOutput>
  </Stack>;
};

const EditPostage = () => {
  const [exec, result] = ScriptsApi.useExecMutation();
  const [text, setText] = useState("");

  const [shouldClearShipToAddress, setShouldClearShipToAddress] = useState(false);
  const [shouldClearReturnAddress, setShouldClearReturnAddress] = useState(false);

  type ShippingCarrier = keyof typeof shippingCarriers;

  const [carrier, setCarrier] = useState<ShippingCarrier>("USPS");
  const [service, setService] = useState("Priority");

  // Default to first valid service when changing carrier selection
  useEffect(() => {
    if (!shippingCarriers[carrier].includes(service)) {
      setService(shippingCarriers[carrier][0]);
    }
  }, [carrier, service]);

  const [shipToAddress, setShipToAddress] = useState(defaultAddress());
  const [returnAddress, setReturnAddress] = useState(defaultAddress());

  const carrierId = useId();
  const serviceId = useId();

  return <Stack gap={1}>
    <Button disabled={!text} variant='outlined' onClick={async () => {
      const result = await exec({ name: 'update_postage', input: {
        orders: textToIds(text),
        'ship_to_address': shouldClearShipToAddress ? objToSnakeCase(shipToAddress) : deleteEmptyKeys(objToSnakeCase(shipToAddress)),
        'return_adddress': shouldClearReturnAddress ? objToSnakeCase(returnAddress) : deleteEmptyKeys(objToSnakeCase(returnAddress)),
        carrier,
        service,
      }});
      const output = result.data?.output;
      if (!output) { return toast.error("Error occurred"); }
      const msg = <div>
        {output.count_updated} orders updated.<br/>
        {output.count_unchanged ? <>
          {output.count_unchanged} orders needed no changes.<br/>
        </> : null}
        {output.count_failed ? <>
          {output.count_failed} could not be updated.<br/>
        </> : null}
      </div>;
      if (output.count_failed > 0 || output.count_updated === 0) {
        toast.warning(msg);
      } else {
        toast.success(msg);
      }
    }}>Update postage on selected orders</Button>
    <Divider />

    <FormControl>
      <InputLabel htmlFor={carrierId}>Shipping carrier</InputLabel>
      <Select id={carrierId} size="small" label="Shipping carrier" value={carrier} onChange={(e) => setCarrier(e.target.value as ShippingCarrier)}>
        {Object.keys(shippingCarriers).map((carrierName) => {
          return <MenuItem key={carrierName} value={carrierName}>{carrierName}</MenuItem>;
        })}
      </Select>
    </FormControl>

    <FormControl>
      <InputLabel htmlFor={serviceId}>Shipping service</InputLabel>
      <Select id={serviceId} size="small" label="Shipping service" value={service} onChange={(e) => setService(e.target.value)}>
        {shippingCarriers[carrier as keyof typeof shippingCarriers].map((serviceName) => {
          return <MenuItem key={serviceName} value={serviceName}>{serviceName}</MenuItem>;
        })}
      </Select>
    </FormControl>

    <IdListEdit text={text} setText={setText} recordKind="Order ID" />
    <Stack direction='row' gap={2}>
      <Stack flexGrow='1'>
        <p>Shipping address</p>
        <Stack direction='row'>
          <FormControlLabel label="Overwrite all fields even if left blank" control={
            <Switch checked={shouldClearShipToAddress} onChange={(e) => setShouldClearShipToAddress(e.target.checked)} />
          }/>
        </Stack>
        <AddressEdit address={shipToAddress} setAddress={setShipToAddress} />
      </Stack>
      <Stack flexGrow='1'>
        <p>Return address</p>
        <Stack direction='row'>
          <FormControlLabel label="Overwrite all fields even if left blank" control={
            <Switch checked={shouldClearReturnAddress} onChange={(e) => setShouldClearReturnAddress(e.target.checked)} />
          }/>
        </Stack>
        <AddressEdit address={returnAddress} setAddress={setReturnAddress} />
      </Stack>
    </Stack>
    <SelfHelpOutput result={result} />
  </Stack>;
};


const Revalidate = () => {
  const [exec, result] = ScriptsApi.useExecMutation();
  const [ordersText, setOrdersText] = useState("");
  const [externalIdsText, setExternalIdsText] = useState("");
  return <Stack gap={1}>
    <Button disabled={!ordersText && !externalIdsText} variant='outlined' onClick={async () => {
      const result = await exec({ name: 'revalidate_orders', input: {
        orders: textToIds(ordersText),
        'external_ids': textToIds(externalIdsText),
      }});
      const output = result.data?.output;
      if (!output) { return toast.error("Error occurred"); }
      const msg = <div>
        {output.count_updated} reprocessing now.<br/>
        {output.count_failed ? <>
          {output.count_failed} orders could not be reprocessed.<br/>
        </>: null}
      </div>;
      if (output.count_failed > 0 || output.count_updated === 0) {
        toast.warning(msg);
      } else {
        toast.success(msg);
      }
    }}>Revalidate given orders</Button>
    <Divider />
    <IdListEdit text={ordersText} setText={setOrdersText} recordKind="Order ID" />
    <IdListEdit text={externalIdsText} setText={setExternalIdsText} recordKind="External Order ID" isDatastore={false} />
    <SelfHelpOutput result={result} />
  </Stack>;
};


const SelfHelpOutput = ({ result, children }: { result: ScriptResult, children?: (output: any) => React.ReactNode, noOutput?: React.ReactNode }) => {
  if (!result) { return null; }
  if (result.isUninitialized) { return null; }
  if (result.isLoading) { return <div>Loading...</div>; }
  return <>
    {result?.data?.output && children ?
      children(result.data.output)
      :
      null
    }
    {result?.data?.stdout ? <>
      <hr />
      <pre>
        {result?.data?.stdout}
      </pre>
    </> : null}
    {!(result?.data?.stdout || result?.data?.output) ? <>
      <div>No output.</div>
    </> : null}
  </>;
};

const QuickLookup = () => {
  const [exec, result] = ScriptsApi.useExecMutation();
  const [input, setInput] = useState('');

  // TODO: display preview images for assets, etc.
  // TODO: lookup order by external ID
  // TODO: modal for quick lookup like in ZIFT?

  return <div>
    <TextField size='small' value={input} label="Record ID" onChange={(e) => {
      setInput(e.target.value);
      const [prefix, suffix] = e.target.value.split('-');
      if (suffix && suffix.length >= 22) {
        exec({name: 'quick_lookup', input: `${prefix}-${suffix}`});
      } else {
        result.reset();
      }
    }}/>
    {(() => {
      if (result.isUninitialized) { return <div>Enter a valid ID</div>; }
      if (result.isLoading) { return <div>Loading...</div>; }
      return <>
        {result?.data?.output ?
          <pre>
            {JSON.stringify(result?.data?.output, null, 4)}
          </pre>
          :
          <div>No record found for <code>{input}</code></div>
        }
        {result?.data?.stdout ? <>
          <hr />
          <pre>
            {result?.data?.stdout}
          </pre>
        </> : null}
      </>;
    })()}
  </div>;
};

// Converts the keys of an object from camelCase to snake_case
function objToSnakeCase<T extends Record<string, any>>(obj: T): Record<string, any> {
  const snakeCaseObj = Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    const snakeKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    acc[snakeKey] = obj[key];
    return acc;
  }, {});
  return snakeCaseObj;
}

function deleteEmptyKeys<T extends Record<string, any>>(obj: T): Record<string, any> {
  return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    const trimmed = obj[key].trim();
    if (!trimmed) {
      delete acc[key];
    } else {
      acc[key] = trimmed;
    }
    return acc;
  }, {});
}

const shippingCarriers = {
  "UPS": [
    "GroundAdvantage",
    "NextDayAirEarlyAM",
    "NextDayAirSaver",
    "NextDayAir",
    "2ndDayAirAM",
    "2ndDayAir",
    "3DaySelect",
    "Ground",
    "UPS Freight - Threshold Service",
    "Expedited Ground",
  ],
  "UPSSurePost": [
    "SurePostUnder1Lb",
    "SurePostOver1Lb",
  ],
  "UPSMailInnovations": [
    "ExpeditedMailInnovations",
    "UPS Mail Innovations - Standard",
    "UPS Mail Innovations - Bound Printed Matter",
    "UPS Mail Innovations - Parcel Select",
  ],
  "FedEx": [
    "FIRST_OVERNIGHT",
    "PRIORITY_OVERNIGHT",
    "STANDARD_OVERNIGHT",
    "FEDEX_2_DAY_AM",
    "FEDEX_2_DAY",
    "FEDEX_EXPRESS_SAVER",
    "FEDEX_GROUND",
    "GROUND_HOME_DELIVERY",
    "CHEAPEST",
    "INTERNATIONAL_PRIORITY",
  ],
  "FedExSmartPost": [
    "SMART_POST",
  ],
  "USPS": [
    "Priority",
    "GroundAdvantage",
    "First Class Mail",
    "Bound Printed Matter",
    "Express Mail",
    "Priority Mail",
    "First",
    "Standard",
  ],
  "AMZ_SML_PAR": [
    "AMZL_US_BULK",
    "AMZL_US_LMA",
    "AMZL_US_LMA_AIR",
    "AMZL_US_PREMIUM",
    "AMZL_US_PREMIUM_AIR",
    "USPS_ATS_BPM",
    "USPS_ATS_BPM_AIR",
    "USPS_ATS_STD",
    "USPS_ATS_STD_AIR",
    "USPS_ATS_PARCEL",
    "USPS_ATS_PARCEL_AIR",
    "AT_SC_BULK",
    "AT_SC_DIRECT",
    "JW_SC_BULK",
    "JW_SC_DIRECT",
    "LA_SC_BULK",
    "LA_SC_DIRECT",
    "OT_SC_BULK",
    "OT_SC_DIRECT",
    "PA_SC_BULK",
    "PA_SC_DIRECT",
  ]
};
