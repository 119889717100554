import { useMemo } from "react";
import { OrdersApi, GetOrders, GetOrdersVariables, useAgGridDatasource, AgGridDatasourceProps, GetOrdersVariables_Options } from "../../../api/core/index.js";
import { useTimeZone } from "@fifthsun/ui";
import { Zone } from "luxon";

export interface OrdersDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetOrdersVariables_Options, GetOrdersVariables, GetOrders>, "endpoint" | "timeZone"> 
  {
    timeZone?: Zone | string;
  }

export function useOrdersDatasource(props: OrdersDatasourceProps) {
  const timeZone = useTimeZone();
  const activeTimeZone = props?.timeZone ?? timeZone;
  const datasourceProps = useMemo<AgGridDatasourceProps<GetOrdersVariables_Options>>(() => ({
    timeZone: activeTimeZone,
    ...props,
    endpoint: OrdersApi.endpoints.getOrders,
    options: {
      count: true,
      include: ['facility', 'store'],
      ...props.options
    },
  }), [props, activeTimeZone]);

  return useAgGridDatasource<GetOrdersVariables_Options>(datasourceProps);
}

export default useOrdersDatasource;
