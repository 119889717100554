import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useMemo } from "react";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import { useGridStateSearchParamPlugin } from "@fifthsun/ui/ag-grid/plugins";
import ScansColDefs from "./ScansColDefs.js";
import useScansServerSideDatasource from "./useScansServerSideDatasource.js";
import { GetRowIdParams } from "ag-grid-community";
import { ErrorBoundaryFallback } from "@fifthsun/ui/sentry";
import { Authenticated } from "@fifthsun/ui";

export const ScansDataGridPage = () => {
  const gridStateExtension = useGridStateSearchParamPlugin();
  const datasource = useScansServerSideDatasource();
  const plugins = useMemo(() => [gridStateExtension], [gridStateExtension]);

  const getRowId = useCallback((params: GetRowIdParams) => params.data.id, []);

  const gridProps = useMemo<DataGridProps>(() => ({
    columnDefs: ScansColDefs,
    getRowId: getRowId,
    blockLoadDebounceMillis: 100,
    rowModelType: "serverSide",
  }), [getRowId]);

  return (
    <Authenticated>
      <ErrorBoundary fallback={ErrorBoundaryFallback}>
        <DataGrid
          {...gridProps}
          serverSideDatasource={datasource}
          plugins={plugins}
        />
      </ErrorBoundary>
    </Authenticated>
  );
};
