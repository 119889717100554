import React, { useCallback, useState } from "react";
import { Divider, Modal, Form, Button } from "antd";
import { DatePicker, FacilitySelect } from "../../../../components/index.js";
import { BatchUnderConstructionAPI } from "../../../../api/BatchUnderConstruction/index.js";
import "./modals.scss";

export default function InitBatchUnderConstructionModal(
  props: { visible?: boolean; close: any }
): JSX.Element {
  const [batchInitSuccess, setBatchInitSuccess] = useState<boolean>(false);
  const [batchInitError, setBatchInitError] =
    useState<{errorMessage: null | string}>({ errorMessage: null });

  const [form] = Form.useForm();

  interface formValues {
    facility: string;
    shipBy: Date;
  }

  const onFinish = (values: formValues) => {
    BatchUnderConstructionAPI.init(values.facility, values.shipBy);
    setBatchInitSuccess(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    const errorMessage = [].concat(...errorInfo.errorFields.map((e: { errors: any; }) => e.errors)).join(', ');
    setBatchInitError((prevState) => ({ ...prevState, errorMessage }));
  };

  const onClose = useCallback(() => {
    props.close();
    setBatchInitError({ errorMessage: null });
    setBatchInitSuccess(false);
  }, [props]);

  return (
    <Modal
      open={props.visible}
      closable
      centered
      footer={null}
      onCancel={onClose}
    >
      { (batchInitSuccess && (
        <div id="batchInitSuccess">
          <h1>Batch successfully initialized!</h1>
          <button type="submit" onClick={onClose}>OK</button>
        </div>
      ))
      || (batchInitError.errorMessage !== null && (
        <div id="batchInitError">
          <h1>Batch initialization failed:</h1>
          <p>{batchInitError.errorMessage}</p>
          <button type="submit" onClick={onClose}>OK</button>
        </div>
      )) || (
        <div id="batchInitForm">
          <div className="modal-title">
            <span className="prefix">RESET</span>
            <span className="suffix">BATCH</span>
          </div>
          <p>There is no way to recover a batch once it has been reset.</p>
          <Divider />
          <Form
            form={form}
            name="initialize batch"
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="facility"
              label="Facility"
              rules={[ { required: true } ]}
            >
              <FacilitySelect internal />
            </Form.Item>
            <Form.Item
              name="shipBy"
              label="Ship By"
              rules={[ { required: true } ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item>
              <Button onClick={onClose} style={{ float: "left" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                OK
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
}
