import CoreApi from "../CoreApi.js";
import { type GetLineItems, type GetLineItemsVariables, deserializeGetLineItems } from "./GetLineItems.js";
import { transformResponse, transformErrorResponse, snakeCaseKeys } from '@fifthsun/ui/utils';
import { GetLineItemCounts, GetLineItemCountsVariables, deserializeGetLineItemCounts } from "./GetLineItemCounts.js";
import { GetLineItemWaypoints, GetLineItemWaypointsVariables, deserializeGetLineItemWaypoints } from "./GetLineItemWaypoints.js";
import { GetLineItemStats, GetLineItemStatsVariables, deserializeGetLineItemStats } from "./GetLineItemStats.js";

// Extend the CoreApi with /line_items endpoints
export const LineItemsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getLineItems: builder.query<GetLineItems, GetLineItemsVariables>({
      query: (params) => ({
        url: '/line_items',
        method: "POST",
        body: {
          ...params,
          filter: params?.filter,
          sort: params?.sort
        }
      }),
      transformResponse: transformResponse(deserializeGetLineItems),
      transformErrorResponse: transformErrorResponse("Core", "getLineItems"),
    }),

    getLineItemCounts: builder.query<GetLineItemCounts, GetLineItemCountsVariables>({
      query: ({ groupBy, timeColumn, filter }) => ({
        url: '/line_items/counts',
        method: "GET",
        params: snakeCaseKeys({
          groupBy,
          timeColumn,
          ...(filter && {
            ...filter,
            from: filter.from?.toISOString(),
            to: filter.to?.toISOString(),
            shipBefore: filter.shipBefore?.toISOString(),
            shipAfter: filter.shipAfter?.toISOString(),
            completedBefore: filter.completedBefore?.toISOString(),
            completedAfter: filter.completedAfter?.toISOString(),
            printedBefore: filter.printedBefore?.toISOString(),
            printedAfter: filter.printedAfter?.toISOString(),
            updatedBefore: filter.updatedBefore?.toISOString(),
            updatedAfter: filter.updatedAfter?.toISOString(),
          })
        })
      }),
      transformResponse: transformResponse(deserializeGetLineItemCounts),
      transformErrorResponse: transformErrorResponse("Core", "getLineItemCounts"),
    }),

    getLineItemStats: builder.query<GetLineItemStats, GetLineItemStatsVariables>({
      query: (params) => ({
        url: '/line_items/stats',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          from: params.from?.toISOString(),
          to: params.to?.toISOString(),
          shipBefore: params.shipBefore?.toISOString(),
          shipAfter: params.shipAfter?.toISOString(),
        })
      }),
      transformResponse: transformResponse(deserializeGetLineItemStats),
      transformErrorResponse: transformErrorResponse("Core", "getLineItemStats"),
    }),

    getWaypoints: builder.query<GetLineItemWaypoints, GetLineItemWaypointsVariables>({
      query: (params) => ({
        url: '/line_items/waypoints',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          shipBefore: params.shipBefore?.toISOString(),
          shipAfter: params.shipAfter?.toISOString(),
        })
      }),
      transformResponse: transformResponse(deserializeGetLineItemWaypoints),
      transformErrorResponse: transformErrorResponse("Core", "getWaypoints"),
    }),

  }),
});

export default LineItemsApi;
