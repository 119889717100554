import { AgDateFilter, AgGridQueryArgs, AgGridQueryArgs_Options, AgNumberFilter, AgQueryResponse, AgTextOrSetFilter } from "../AgGrid.js";
import { deserialize, DeserializationSchema } from "@fifthsun/ui/utils";
import { Location, deserializeLocationsArray } from "./common.js";

export interface GetLocations extends AgQueryResponse<Location> {}

export const GetLocationsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeLocationsArray,
});

export const deserializeGetLocations = 
  deserialize<GetLocations>(GetLocationsDeserializationSchema, "[GetLocations]");

export type GetLocationsVariables_Include = "facilities";
export interface GetLocationsVariables_Options extends AgGridQueryArgs_Options {
  include?: GetLocationsVariables_Include[] | null | undefined;
}

export interface GetLocationsVariables extends AgGridQueryArgs<GetLocationsVariables_Options, GetLocationsFilter> {}

// TO DO: Add GetLocationsFilter_facility sub-interface if we find the need to filter by fields
// within the facility (other than the id)
export interface GetLocationsFilter {
  id?: AgTextOrSetFilter | null;
  code?: AgTextOrSetFilter | null;
  name?: AgTextOrSetFilter | null;
  createdAt?: AgDateFilter | null;
  updatedAt?: AgDateFilter | null;
  facilities?: GetLocationsFilter_Facilities | null;
}

export interface GetLocationsFilter_Facilities {
  id?: AgTextOrSetFilter | null;
  ziftId?: AgTextOrSetFilter | null;
  name?: AgTextOrSetFilter | null;
  code?: AgTextOrSetFilter | null;
  capacity?: AgNumberFilter | null;
  createdAt?: AgDateFilter | null;
  updatedAt?: AgDateFilter | null;
  timezone?: AgTextOrSetFilter | null;
  //external: boolean;  - TO DO: Add an AgBooleanFilter
  siteId?: AgTextOrSetFilter | null;
  organizationId?: AgTextOrSetFilter | null;
  status?: AgTextOrSetFilter | null;
}
