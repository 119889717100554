import React, { useMemo } from "react";
import { DatePicker, DatePickerProps } from "../DatePicker.js";
import { startOfDayLocal } from "@fifthsun/ui/utils";

export const DateLocalPicker = (props: DatePickerProps) => {
  const cleanValue = useMemo(() => {
    if (props.value === undefined || props.value === null) {
      return props.value;
    }
    return startOfDayLocal(props.value);
  }, [props.value]);

  return (
    <DatePicker
      {...props}
      value={cleanValue}
    />
  );
};
