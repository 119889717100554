import React from 'react';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/browser";
import { ToastContentProps, toast } from 'react-toastify';
import { ErrorPayload, isErrorPayload } from "@fifthsun/ui/utils";

export function ErrorToastContent(props: ToastContentProps<ErrorPayload>) {
  const payload = props.data;
  if (!payload) {
    return (
      <div>An unknown error occurred</div>
    );
  }

  const title = `${payload.endpoint || payload.url} (${payload.status})`;
  const message = payload.message || payload.statusText;
  return (
    <>
      <div className="error-card-title">{title}</div>
      <div className="error-card-message">{message}</div>
    </>
  );
}

export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    let suppress: boolean | undefined;
    if (isErrorPayload(action.payload)) {
      const ep = action.payload as ErrorPayload;
      suppress = ep.suppress;
      if (!suppress) {
        toast.error((props) => ErrorToastContent(props as ToastContentProps<ErrorPayload>), { 
          data: action.payload as ErrorPayload 
        });
      }
    } else {
      // the assumption is that the payload is a FetchBaseQueryError
      // but this can change depending upon the configuration of the API
      toast.error(`An unknown error occurred. ${JSON.stringify(action.payload)}`);
    }

    if (!suppress) {
      Sentry.captureException(action);
    }
  }

  return next(action);
};
