import { OrderStatus } from "../Orders/index.js";
import { deserializeStore } from "../Stores/index.js";
import { TimeseriesMetadata } from "../common.js";
import { DeserializationFunc, DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "@fifthsun/ui/utils";
import { LineItemStatus, Waypoint, ZiftStatus } from "./common.js";

export interface GetLineItemCountsVariables {
  groupBy?: string;
  timeColumn?: GetLineItemCountsTimeColumn;
  filter?: GetLineItemCountsFilterInput;
}

export enum GetLineItemCountsTimeColumn {
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
  ShipBy = "ship_by",
}

export interface GetLineItemCountsFilterInput {
  from?: Date | null;
  to?: Date | null;
  shipBefore?: Date | null;
  shipAfter?: Date | null;
  scannedBefore?: Date | null;
  scannedAfter?: Date | null;
  waypoint?: Waypoint | Waypoint[] | null;
  status?: LineItemStatus | LineItemStatus[] | null;
  ziftStatus?: ZiftStatus | ZiftStatus[] | null;
  minProgress?: number | null;
  maxProgress?: number | null;
  orderStatus?: OrderStatus | OrderStatus[] | null;
  facilityId?: string | string[] | null;
  batchId?: string | string[] | null;
  storeId?: string | string[] | null;
  blankId?: string | string[] | null;
  ziftId?: string | string[] | null;
  timezone?: string | null;
  hasBin?: boolean | null;
  completedBefore?: Date | null;
  completedAfter?: Date | null;
  printedBefore?: Date | null;
  printedAfter?: Date | null;
  updatedBefore?: Date | null;
  updatedAfter?: Date | null;
}

export interface LineItemCounts {
  time: Date;
  metadata: TimeseriesMetadata;
  value: number;
  data: LineItemCountsMetadata;
  prints: number;
}

export interface LineItemCountsMetadata {
  key: string; // = "store_id"
  value: string; // the store_id

  // The API project would go fetch/compute these fields - but it's not necessary
  // We can simply grab the stores separately (and more efficiently) via one request/the cache
  // and then lookup the store from there. 
  // Additionally, the query that was being computed for the URI (/line_items?store_id=<store_id>)
  // has not been valid for quite some time and needs to be re-done (TO DO)
  //store?: Store;
  //uri: string;
}

export const LineItemCountsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  time: deserializeDate,
  data: {
    store: deserializeStore
  }
});

export const deserializeLineItemCounts = 
  deserialize<LineItemCounts>(LineItemCountsDeserializationSchema);

export const deserializeLineItemCountsArray =
  deserializeArrayOf<LineItemCounts>(LineItemCountsDeserializationSchema);

export type GetLineItemCounts = LineItemCounts[];
export const deserializeGetLineItemCounts = 
  deserializeLineItemCountsArray as DeserializationFunc<GetLineItemCounts>;
