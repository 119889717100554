import { AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse, AgSortOrder } from "../AgGrid.js";
import { Batch, deserializeBatchArray } from "./Batch.js";
import { AliasMap } from "../utils/index.js";
import { deserialize, DeserializationSchema } from "@fifthsun/ui/utils";
import { BatchFilterInputV2 } from "./BatchFilterInputV2.js";

export interface BatchSortV2 {
  createdAt?: AgSortOrder | null;
  updatedAt?: AgSortOrder | null;
  ziftId?: AgSortOrder | null;
  status?: AgSortOrder | null;
  progress?: AgSortOrder | null;
  completed_at?: AgSortOrder | null;
  priority?: AgSortOrder | null;
  compoundPriority?: AgSortOrder | null;
  operatorPriority?: AgSortOrder | null;
  minShipBy?: AgSortOrder | null;
  maxShipBy?: AgSortOrder | null;
  minItems?: AgSortOrder | null;
  maxItems?: AgSortOrder | null;
  minCompletedItems?: AgSortOrder | null;
  maxCompletedItems?: AgSortOrder | null;
  scheduleId?: AgSortOrder | null;
  scheduleFacilityName?: AgSortOrder | null;
  scheduleDate?: AgSortOrder | null;
  autoFacilityId?: AgSortOrder | null;
  autoFacilityName?: AgSortOrder | null;
  lastConfirmPullAt?: AgSortOrder | null;
  lastConfirmPullUserId?: AgSortOrder | null;
  itemsCount?: AgSortOrder | null;
  completedItemsCount?: AgSortOrder | null;
  boxes?: AgSortOrder | null;
  orderBoxes?: AgSortOrder | null;
  orderExternalIds?: AgSortOrder | null;
  scheduledAt?: AgSortOrder | null;
  scheduledUserId?: AgSortOrder | null;
  pullBox?: AgSortOrder | null;
  idle?: AgSortOrder | null;
  schedulePinned?: AgSortOrder | null;
}

export interface GetBatches extends AgQueryResponse<Batch> {}

export const GetBatchesDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeBatchArray
});

export const deserializeGetBatches = deserialize<GetBatches>(GetBatchesDeserializationSchema, "[GetBatches]");

export type GetBatchesVariables_Includes = 
  "auto_facility" | "line_item_stats" | "printer" | "note" | "schedule" | "schedule_facility" | 
  "stores" | "store_stats";

export interface GetBatchesVariables_Options extends AgGridQueryArgs_Options {
  include?: GetBatchesVariables_Includes[] | null | undefined;
}

export interface GetBatchesVariables 
  extends AgGridQueryArgs<GetBatchesVariables_Options, BatchFilterInputV2> {}

// Used to process an input Filter directly from the AgGrid component into the format
// expected by the core service
export const GetBatches_AliasMap: AliasMap = {
  //note: "notes",
  //schedule: "schedules",
  //store: "stores"
};
