import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { AllocationReportSummary } from "../../../components/DemandReport/index.js";

export const AllocationSummaryPage = () => {
  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <AllocationReportSummary />
    </ErrorBoundary>
  );
};
