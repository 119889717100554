import React, { useState } from "react";
import { SiderProps as AntSiderProps } from "antd/lib/layout";
import { Layout } from "antd";
import { ResizableBox } from "react-resizable";
import "./Sider.scss";

const { Sider: AntSider } = Layout;

interface SiderProps extends AntSiderProps {
  resizable?: boolean;
}

export const Sider = (props: SiderProps) => {
  const { children, className, resizable = true, ...otherProps } = props;

  const [width, setWidth] = useState(300);
  const resizer = resizable ? (
    <ResizableBox
      width={width}
      height={100}
      minConstraints={[125, 0]}
      onResize={(e, { size }) => setWidth(size.width)}
    />
  ) : undefined;

  return (
    <AntSider
      className={`mad-sider ${className}`}
       
      {...{
        theme: "light",
        width,
        ...otherProps,
      }}
    >
      {children}

      {resizer}
    </AntSider>
  );
};
