import { Facility, deserializeFacilitiesArray } from "../Facilities/index.js";
import { deserialize, DeserializationSchema, deserializeArrayOf } from "@fifthsun/ui/utils";
import { deserializeDate } from "@fifthsun/ui/utils";

export interface Location {
  id: string;
  code: string;
  name?: string;
  createdAt: Date;
  updatedAt: Date;

  facilities?: Facility[];
}

export const LocationDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate,
  facilities: deserializeFacilitiesArray,
});

export const deserializeLocation = deserialize<Location>(LocationDeserializationSchema);
export const deserializeLocationsArray = deserializeArrayOf<Location>(LocationDeserializationSchema);
