import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-community";
import { DateTimeCellRenderer, ProgressCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { LocaleNumberCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { Order, OrderStatus } from "../../../api/core/index.js";

export const orderColumnDefs: Array<ColDef<Order> | ColGroupDef<Order>> = [
  {
    field: "id",
    hide: true,
    filter: "agTextColumnFilter"
  },
  { 
    field: "ziftId",
    filter: "agTextColumnFilter",
  },
  {
    field: "erpId",
    filter: "agTextColumnFilter"
  },
  {
    field: "status",
    filter: "agSetColumnFilter",
    filterParams: {
      values: Object.values(OrderStatus).map((v) => v.toLocaleLowerCase()),
    },
  },
  {
    field: "progress",
    filter: "agNumberColumnFilter",
    cellRenderer: ProgressCellRenderer,
  },
  {
    field: "shipBy",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "lineItemsCount",
    filter: "agNumberColumnFilter",
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: "store.name",
    filter: "agTextColumnFilter"
  },
  {
    field: "facility.name",
    filter: "agTextColumnFilter"
  },
  {
    field: "shippingService",
    filter: "agTextColumnFilter"
  },
  {
    field: "shippingCarrier",
    filter: "agTextColumnFilter"
  },
  {
    field: "boxes",
  },
  {
    field: "createdAt",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "updatedAt",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "auditedAt",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "completedAt",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "meta",
    sortable: false,
    cellRenderer: (params: ICellRendererParams<Order>) => {
      return JSON.stringify(params.data);
    }
  },
];

export default orderColumnDefs;
