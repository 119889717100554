// https://ant.design/docs/react/replace-moment#Use-date-fns

import _dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns.js";
import _generatePicker from "antd/es/date-picker/generatePicker/index.js";
import "antd/es/date-picker/style/index.js";

// Gotta get a little hacky for cross ESM / CommonJS imports
// See https://stackoverflow.com/questions/74083446/typescript-import-esm-default-export
const dateFnsGenerateConfig = _dateFnsGenerateConfig as unknown as typeof _dateFnsGenerateConfig.default;
const generatePicker = _generatePicker as unknown as typeof _generatePicker.default;

export const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
export type DatePickerProps = Parameters<typeof DatePicker<Date>>[0];
