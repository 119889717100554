import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Form, InputGroup, Button } from 'react-bootstrap';
import { SubmitHandler, useForm, FieldValues, SubmitErrorHandler } from "react-hook-form";

import { Spin } from "../../../../../components/index.js";
import { 
  CreateBatchUnderConstructionModalStepProps, 
  StepComponent 
} from "./CreateBatchUnderConstructionModalStepProps.js";

interface CreateBatchFields extends FieldValues {
  facilityId?: string;
  /**
   * The shipByDate formatted as a local date string
   */
  shipByDate?: string; 
  lineItemCount?: number;
  printerId?: string;
}

export interface SelectPrinterStepProps extends CreateBatchUnderConstructionModalStepProps {
}

export const SelectPrinterStep:StepComponent<SelectPrinterStepProps> = 
(props: SelectPrinterStepProps) => {

  // TO DO: query for the list of valid printers based upon the line items
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    state,
    update,
    submit,
    cancel
  } = props;

  const { 
    register, 
    handleSubmit, 
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors, touchedFields, isValidating, isSubmitting, isSubmitted }
  } = useForm<CreateBatchFields>();

  // keep the form and the modal state in sync
  const values = watch();
  const disablePrinterId = !values.facilityId || !values.shipByDate || !values.lineItemCount;

  useEffect(() => {
    if (!disablePrinterId) {
      update((_prev) => ({
        printerId: values.printerId
      }));
    }
  }, [disablePrinterId, values.printerId, update]);

  // update the current state if everything is valid and move to the next step
  const onValidSubmit:SubmitHandler<CreateBatchFields> = useCallback((_data, _evt) => {
    submit();
  }, [submit]);

  const onInvalidSubmit:SubmitErrorHandler<CreateBatchFields> = useCallback((_errors, _evt) => {
    // put code here to respond to a submit that failed due to form validation
  }, []);

  const onSubmit = useCallback((e: React.BaseSyntheticEvent) => {
    handleSubmit(onValidSubmit, onInvalidSubmit)(e);
  }, [handleSubmit, onValidSubmit, onInvalidSubmit]);

  useEffect(() => {
    setValue("facilityId", state.facilityId);
    setValue("shipByDate", state.shipByDate?.toLocaleDateString());
    setValue("lineItemCount", state.lineItems?.length ?? 0);
    setValue("printerId", state.printerId);
    trigger();
  }, [setValue, trigger, state]);
  
  // TO DO: remove once we are actually loading the printer list
  useEffect(() => {
    const tmr = window.setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => {
      window.clearTimeout(tmr);
    };
  }, []);

  const hasValidated = useMemo(() => isValidating || isSubmitting || isSubmitted,
    [isValidating, isSubmitting, isSubmitted]);

  return (
    <>
      <Modal.Body>
        <Spin spinning={isLoading} tip="Retrieving the list of valid printers">
          <Form noValidate>
            <InputGroup className="form-group">
              <Form.FloatingLabel
                label="Facility"
              >
                <Form.Control 
                  disabled
                  placeholder="Facility"
                  {...register('facilityId', { 
                    required: "FacilityId is required",
                  })} 
                  isValid={!!getValues().facilityId?.length}
                  isInvalid={!!errors.facilityId}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.facilityId?.message}
                </Form.Control.Feedback>
              </Form.FloatingLabel>
            </InputGroup>

            <InputGroup className="form-group">
              <Form.FloatingLabel
                label="Ship By"
              >
                <Form.Control 
                  disabled
                  placeholder="Ship By Date"
                  {...register('shipByDate', { 
                    required: "shipByDate is required",
                  })} 
                  isValid={!!getValues().shipByDate?.length}
                  isInvalid={!!errors.shipByDate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.shipByDate?.message}
                </Form.Control.Feedback>
              </Form.FloatingLabel>
            </InputGroup>

            <InputGroup className="form-group">
              <Form.FloatingLabel
                label="Line Items"
              >
                <Form.Control 
                  disabled
                  placeholder="Line Item Count"
                  {...register('lineItemCount', { 
                    validate: (value) => {
                      if (!value) {
                        return "lineItemCount is required";
                      }
                      return undefined;
                    },
                  })} 
                  isValid={!!getValues().lineItemCount}
                  isInvalid={!!errors.lineItemCount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lineItemCount?.message}
                </Form.Control.Feedback>
              </Form.FloatingLabel>
            </InputGroup>

            <InputGroup hasValidation className="form-group">
              <Form.FloatingLabel
                label="Printer"
              >
                <Form.Select 
                  {...register('printerId', { 
                    validate: (value) => {
                      if (!value?.length) {
                        return "Printer is required";
                      }
                      return undefined;
                    },
                    disabled: disablePrinterId,
                  })}
                  isValid={!disablePrinterId && 
                    (touchedFields.printerId ?? hasValidated) && !errors.printerId}
                  isInvalid={!disablePrinterId && 
                    (touchedFields.printerId ?? hasValidated) && !!errors.printerId}
                >
                  <option selected />
                  <option>Mock A</option>
                  <option>Mock B</option>
                  <option>Mock C</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.printerId?.message}
                </Form.Control.Feedback>
              </Form.FloatingLabel>
            </InputGroup>
          </Form>
        </Spin>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="secondary"  
          onClick={() => cancel()} 
          children={<>Cancel</>}
        />
        <Button 
          variant="primary"  
          onClick={onSubmit} 
          children={<>Submit</>}
        />
      </Modal.Footer>  
    </>
  );
};

export default SelectPrinterStep;
