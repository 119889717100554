import { CreateBatchUnderConstructionModalEventType } from "./CreateBatchUnderConstructionModalStepProps.js";

const _shownEventType:CreateBatchUnderConstructionModalEventType = 'shown';
export interface ShownEventArgs {}
export const createShownEvent = () => new CustomEvent<ShownEventArgs>(_shownEventType, {});

const _hiddenEventType:CreateBatchUnderConstructionModalEventType = 'hidden';
export interface HiddenEventArgs {}
export const createHiddenEvent = () => new CustomEvent<HiddenEventArgs>(_hiddenEventType, {});

const _cancelEventType:CreateBatchUnderConstructionModalEventType = 'cancel';
export interface CancelEventArgs {}
export const createCancelEvent = () => new CustomEvent<CancelEventArgs>(_cancelEventType, {});

const _submitEventType:CreateBatchUnderConstructionModalEventType = 'submit';
export interface SubmitEventArgs {}
export const createSubmitEvent = () => new CustomEvent<SubmitEventArgs>(_submitEventType, {});

const _resetEventType:CreateBatchUnderConstructionModalEventType = 'reset';
export interface ResetEventArgs {}
export const createResetEvent = () => new CustomEvent<ResetEventArgs>(_resetEventType, {});
