import CoreApi from "../CoreApi.js";
import { transformResponse, transformErrorResponse, snakeCaseKeys } from "@fifthsun/ui/utils";
import { GetBlankStats, GetBlankStatsVariables, deserializeGetBlankStats } from "./GetBlankStats.js";
import { GetBlanks, GetBlanksVariables, deserializeGetBlanks } from "./GetBlanks.js";

// Extend the CoreApi with /blanks endpoints
export const BlanksApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getBlanks: builder.query<GetBlanks, GetBlanksVariables>({
      query: (params) => ({
        method: "GET",
        url: "/blanks",
        params
      }),
      transformResponse: transformResponse(deserializeGetBlanks),
      transformErrorResponse: transformErrorResponse("Core", "getBlanks"),
    }),

    getBlankStats: builder.query<GetBlankStats, GetBlankStatsVariables>({
      query: (params) => ({
        method: "GET",
        url: "/blanks/stats",
        params: snakeCaseKeys({
          ...params,
          lineItemFrom: params.lineItemFrom?.toISOString(),
          lineItemTo: params.lineItemTo?.toISOString()
        })
      }),
      transformResponse: transformResponse(deserializeGetBlankStats),
      transformErrorResponse: transformErrorResponse("Core", "getBlankStats"),
    }),

  }),
});

export default BlanksApi;
