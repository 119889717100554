import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import { Progress, ProgressValue } from "../index.js";
import { GetQuotasVariables, QuotasApi } from "../../api/core/index.js";

interface QuotaProgressProps {
  variables: GetQuotasVariables,
  value?: number;
  add?: number;
}

export interface QuotaRef {
  refetch: () => void;
}

export const QuotaProgress = forwardRef<QuotaRef, QuotaProgressProps>((props: QuotaProgressProps, ref) => {
  // NOTE: Currently the request converts to ISO and chops off everything after the date
  // This cuts off any timezone offset information - so it assumes that the passed in date is in UTC.
  const { data, isFetching: loading, refetch: refetchQuota } = QuotasApi.useGetQuotasQuery({
    ...props.variables
  }, {
    refetchOnMountOrArgChange: true,
    skip: !props.variables.date
  });
  
  useImperativeHandle(ref, () => ({
    refetch() {
      refetchQuota();
    }
  }));

  const quota = data?.at(0);
  const usage = useMemo(() => {
    if (!props.variables.date) {
      return undefined;
    }

    const target = props.variables.date.toISOString().split("T")[0];
    return quota?.usage?.find((usage) => {
      const curr = usage.date.toISOString().split("T")[0];
      return curr === target;
    });
  }, [quota?.usage, props.variables.date]);

  const values = useMemo(() => {
    const progressiveValues: ProgressValue[] = [
      {
        title: "shipped",
        color: "#e5eb8f",
        value: usage?.shipped ?? 0,
      },
      {
        title: "printed",
        color: "#b7eb8f",
        value: usage?.printed ?? 0,
      },
      {
        title: "scheduled",
        color: "#91d5ff",
        value: usage?.scheduled ?? 0,
      },
    ];

    if (props.add && props.add > 0) {
      progressiveValues.push({
        title: "new",
        color: "#36cfc9",
        value: props.add,
      });
    }

    return progressiveValues;
  }, [props.add, usage]);

  const title = useMemo(() => {
    const parent = quota?.parent;
    return (parent && "name" in parent) ? parent.name ?? '' : '';
  }, [quota?.parent]);

  if (!loading && !quota) { return <></>; }

  return (
    <div>
      <Progress
        total={quota?.value ?? 0}
        value={values}
        title={title}
        loading={loading}
        showDetails={!!(props.add && props.add > 0)}
      />
    </div>
  );
});

QuotaProgress.displayName = "QuotaProgress";

export default QuotaProgress;
