import CoreApi from "../CoreApi.js";
import { snakeCaseKeys, transformResponse, transformErrorResponse } from "@fifthsun/ui/utils";
import { GetFloorviewBatches, GetFloorviewBatchesVariables, deserializeGetFloorviewBatches } from "./GetFloorviewBatches.js";
import { GetFloorviewSchedule, GetFloorviewScheduleVariables, deserializeGetFloorviewSchedule } from "./GetFloorviewSchedule.js";
import { createScheduleIdentifierSearchParams } from "../Schedules/index.js";
import { GetFloorviewStores, GetFloorviewStoresVariables, deserializeGetFloorviewStores } from "./GetFloorviewStores.js";
import { GetFloorviewSorts, GetFloorviewSortsVariables, deserializeGetFloorviewSorts } from "./GetFloorviewSorts.js";
import { UpdateFloorviewSort, UpdateFloorviewSortVariables } from "./UpdateFloorviewSort.js";
import { snakeCase } from 'lodash';

// Extend the CoreApi with /floorviews endpoints
export const FloorviewsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getFloorviewBatches: builder.query<GetFloorviewBatches, GetFloorviewBatchesVariables>({
      query: (params) => ({
        url: '/floorviews',
        method: 'POST',
        body: {
          ...params,
          schedule: { 
            ...snakeCaseKeys(params.schedule),
            date: params.schedule.date?.toISOString().split("T")[0] ?? undefined,
          },
          filter: params?.filter,
        }
      }),
      transformResponse: transformResponse(deserializeGetFloorviewBatches),
      transformErrorResponse: transformErrorResponse("Core", "getFloorviewBatches"),
    }),

    getFloorviewSchedule: builder.query<GetFloorviewSchedule, GetFloorviewScheduleVariables>({
      query: (params) => ({
        url: `/floorviews/schedule?${createScheduleIdentifierSearchParams(params.schedule).toString()}`,
        method: 'GET',
      }), 
      transformResponse: transformResponse(deserializeGetFloorviewSchedule),
      transformErrorResponse: transformErrorResponse("Core", "getFloorviewSchedule"),
    }),

    getFloorviewSorts: builder.query<GetFloorviewSorts, GetFloorviewSortsVariables>({
      query: (params) => {
        if (params.id) {
          return {
            url: `/floorviews/${params.id}/sort`,
            method: 'GET'
          };
        } 
        const date = params.date?.toISOString().split("T")[0] ?? undefined;
        return {
          url: `/floorviews/${date}/${params.facilityId ?? params.facilityCode}/sort`,
          method: 'GET'
        };
        
      }, 
      transformResponse: transformResponse(deserializeGetFloorviewSorts),
      transformErrorResponse: transformErrorResponse("Core", "getFloorviewSorts"),
    }),    
    
    getFloorviewStores: builder.query<GetFloorviewStores, GetFloorviewStoresVariables>({
      query: (params) => ({
        url: `/floorviews/stores?${createScheduleIdentifierSearchParams(params.schedule).toString()}`,
        method: 'GET',
      }), 
      transformResponse: transformResponse(deserializeGetFloorviewStores),
      transformErrorResponse: transformErrorResponse("Core", "getFloorviewStores"),
    }),

    updateFloorviewSort: builder.mutation<UpdateFloorviewSort, UpdateFloorviewSortVariables>({
      query: (params) => ({
        url: "/floorviews/sort",
        method: 'PATCH',
        body: {
          ids: params.scheduleIds,
          sort: params.sort?.map((s) => ({
            direction: s.direction,
            field: snakeCase(s.field)
          }))
        }
      }), 
      //transformResponse: transformResponse(deserializeUpdateFloorviewSort), - no response atm
      transformErrorResponse: transformErrorResponse("Core", "getFloorviewStores"),
    }),
  }),
});

export default FloorviewsApi;
