import { Facility, deserializeFacilitiesArray } from "./common.js";
import { deserialize, DeserializationSchema } from "@fifthsun/ui/utils";
import { AgGridQueryArgs, AgQueryResponse } from "../AgGrid.js";

export interface GetFacilities extends AgQueryResponse<Facility> {}

export interface GetFacilitiesVariables extends AgGridQueryArgs {}

export const GetFacilitiesDeserializationsSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeFacilitiesArray,
});

export const deserializeGetFacilities = 
  deserialize<GetFacilities>(GetFacilitiesDeserializationsSchema);
