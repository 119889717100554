import {container} from "tsyringe";

// ===== Redux ===== //
import {store} from "./redux/store.js";
import {Dispatch, ThunkDispatch, UnknownAction} from "@reduxjs/toolkit";
import {dispatchSymbol} from "@fifthsun/ui/redux";

container.register<ThunkDispatch<any, undefined, UnknownAction> & Dispatch<UnknownAction>>
(dispatchSymbol, {useValue: store.dispatch});

// ===== Feature Flags Service ===== //
import {ApplicationFeatureFlagsService, FeatureFlagsServiceConfig, FeatureFlagsServiceConfigSymbol, IFeatureFlagsServiceSymbol} from "@fifthsun/ui/api/featureFlags";
import {isString} from "lodash";
import { FeatureFlags, Mauthra, PageLoad } from "@fifthsun/ui/api";

if (!isString(import.meta.env.REACT_APP_APPLICATION)) {
  throw new Error(`The REACT_APP_APPLICATION environment variable is required: ${JSON.stringify(import.meta.env.REACT_APP_APPLICATION)}`);
}

if (!isString(import.meta.env.REACT_APP_FEATURE_FLAGS_AUTHORITY)) {
  throw new Error("The REACT_APP_FEATURE_FLAGS_AUTHORITY is required");
}

if (!isString(import.meta.env.REACT_APP_FEATURE_FLAGS_LIVE_AUTHORITY)) {
  throw new Error("The REACT_APP_FEATURE_FLAGS_LIVE_AUTHORITY is required");
}

const ffConfig: FeatureFlagsServiceConfig = {
  application: import.meta.env.REACT_APP_APPLICATION,
  restAuthority: import.meta.env.REACT_APP_FEATURE_FLAGS_AUTHORITY!, //"feature-flags.madengine.dev"
  liveAuthority: import.meta.env.REACT_APP_FEATURE_FLAGS_LIVE_AUTHORITY!, //"feature-flags-live.madengine.dev", 
};

container.register(FeatureFlagsServiceConfigSymbol, { useValue: ffConfig });
container.registerSingleton(IFeatureFlagsServiceSymbol, ApplicationFeatureFlagsService);

// ===== Page Loader Tasks ===== //
container.registerSingleton(PageLoad.TaskManager);
container.register(PageLoad.TaskSymbol, {useClass: Mauthra.Oauth2.InitTask});
container.register(PageLoad.TaskSymbol, {useClass: FeatureFlags.InitTask});

// DEBUG ONLY : container.register(PageLoad.TaskSymbol, { useClass: DelayTask });
