import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { DemandReportDetailsFilterModel, DetailDemandReportTable } from "../../../components/DemandReport/index.js";
import { ZiftStatus } from "../../../api/core/index.js";
import { DateTime } from "luxon";

export const CanceledReportPage = () => {
  const today = DateTime.now().startOf('day').toJSDate();
  const tomorrow = DateTime.fromJSDate(today).plus({ days: 1 }).toJSDate();

  const filterModel: DemandReportDetailsFilterModel = {
    ziftStatus: {
      filterType: 'set',
      values: [ZiftStatus.Cancelled]
    },
    updatedAt: {
      filterType: 'date',
      condition1: {
        type: 'inRange',
        dateFrom: today.toISOString(),
        dateTo: tomorrow.toISOString()
      },
      condition2: {
        type: 'notEqual',
        dateFrom: tomorrow.toISOString()
      },
      operator: 'AND'
    }
  };

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <DetailDemandReportTable filterModel={filterModel} />
    </ErrorBoundary>
  );
};
