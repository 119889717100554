import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { AllocationReportTable } from "../../../components/DemandReport/index.js";

export const AllocationReportPage = () => {
  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <AllocationReportTable />
    </ErrorBoundary>
  );
};
