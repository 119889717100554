import { DeserializationSchema, deserialize } from "@fifthsun/ui/utils";

export interface SyncOrder {
  id: string,
  status: string,
  message?: string,
  actions?: string[]
}

export interface SyncOrdersVariables {
  id: string
}

export const SyncOrderSchema: Readonly<DeserializationSchema> = Object.freeze({
});

export const deserializeSyncOrder = deserialize<SyncOrder>(SyncOrderSchema);
