import { Facility, deserializeFacility } from "../Facilities/index.js";
import { LineItem, deserializeLineItem } from "../LineItems/index.js";
import { deserialize, DeserializationSchema, deserializeArrayOf } from "@fifthsun/ui/utils";
import { deserializeDate } from "@fifthsun/ui/utils";

export interface Scan {
  id: string;
  lineItemId?: string | null;
  facilityId?: string | null;
  hostname?: string | null;
  waypoint?: string | null;
  time?: Date;

  facility: Facility;
  LineItem: LineItem;
}

export const ScanDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  time: deserializeDate,
  facility: deserializeFacility,
  lineItem: deserializeLineItem
});

export const deserializeScan = deserialize<Scan>(ScanDeserializationSchema);
export const deserializeScansArray = deserializeArrayOf<Scan>(ScanDeserializationSchema);
