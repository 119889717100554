import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { PrintReportTable } from "../../../components/DemandReport/PrintTable/index.js";

export const PrintReportPage = () => {
  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <PrintReportTable />
    </ErrorBoundary>
  );
};
