import { useAuthentication } from "@fifthsun/ui";
import React from "react";

export const HomePage = () => {
  const { authenticated } = useAuthentication();

  return (
    <div style={{ margin: "auto", textAlign: "center" }}>
      What to do here? Some kind of dashboard perhaps?
      <br />
      { (authenticated && (
        <p>You are authenticated!</p>
      )) || (
        <p>You are NOT authenticated...</p>
      )}
    </div>
  );
};
