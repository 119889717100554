import { AliasMap } from "../utils/index.js";
import { Batch } from "../Batches/Batch.js"; // explicit path to avoid circular dependencies
import { BatchFilterInputV2, deserializeBatchArray } from "../Batches/index.js";
import { ScheduleIdentifier } from "../Schedules/index.js";
import { AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse } from "../AgGrid.js";
import { deserialize, DeserializationSchema } from "@fifthsun/ui/utils";

export interface GetFloorviewBatches extends AgQueryResponse<Batch> {}

export type GetFloorviewBatches_Include = 'blank' | 'order' | 'store';
export interface GetFloorviewBatchesVariables_Options extends AgGridQueryArgs_Options {
  include?: GetFloorviewBatches_Include[] | null | undefined;
}

export interface GetFloorviewBatchesVariables 
  extends Omit<AgGridQueryArgs<GetFloorviewBatchesVariables_Options, BatchFilterInputV2>, "sort"> {
    schedule: ScheduleIdentifier;
  }

export const GetFloorviewBatchesDeserializationSchema: Readonly<DeserializationSchema> = 
  Object.freeze({
    rows: deserializeBatchArray
  });

export const deserializeGetFloorviewBatches = 
  deserialize<GetFloorviewBatches>(GetFloorviewBatchesDeserializationSchema);

// Used to process an input Filter directly from the AgGrid component into the format
// expected by the core service
export const GetFloorviewBatches_AliasMap: AliasMap = {
  note: "notes",
  schedule: "schedules",
  store: "stores"
};
