import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Progress, Spin } from "antd";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DateParam, useQueryParam } from "@fifthsun/ui/api/queryParams";
import "./index.scss";
import { AgSortOrder, GetSchedulesFilter, GetSchedules_AliasMap, ScheduleStats, SchedulesApi, prepareFilter, prepareSort } from "../../../api/core/index.js";
import { DateTime, IANAZone } from "luxon";
import { isDate } from "lodash";
import { Authenticated } from "@fifthsun/ui";

import FullCalendar from "@fullcalendar/react";
import { CalendarApi, EventClickArg } from '@fullcalendar/core';
import { useNavigate } from "react-router";

// The calendar currently operates in UTC only
const utcZone = new IANAZone("UTC");

export const ScheduleManagePage = () => {
  const navigate = useNavigate();

  const calendar = useRef<FullCalendar>(null);
  const [date, setDate] = useQueryParam("date", DateParam);
  const [filter, setFilter] = useState<{ from?: Date, to?: Date}>({});

  const fromStr = useMemo(() => {
    const fromDT = filter.from ? DateTime.fromJSDate(filter.from).toUTC() : undefined;
    return fromDT?.isValid ? fromDT.toISO() : undefined;
  }, [filter.from]);

  const toStr = useMemo(() => {
    const toDT = filter.to ? DateTime.fromJSDate(filter.to).toUTC() : undefined;
    return toDT?.isValid ? toDT.toISO() : undefined;
  }, [filter.to]);

  const sanitizedFilter = useMemo<GetSchedulesFilter>(() => {
    const f:GetSchedulesFilter = {};
    if (fromStr && toStr) {
      f.date = {
        filterType: 'date',

        // NOTE: Since the calendar sets the TO date to start of the next month,
        // we actually want the range to exclude dateTo. This option is not currently available.
        // TO DO: provide an option for the 'inRange' condition, or an alternative condition type,
        // such that query excludes schedules that have a date equal to dateTo.
        condition1: {
          type: 'inRange',
          dateFrom: fromStr,
          dateTo: toStr,
        }
      };
    } else if (fromStr) {
      f.date = {
        filterType: 'date',
        condition1: {
          type: 'greaterThan',
          dateFrom: fromStr,
        },
        operator: 'OR',
        condition2: {
          type: 'equals',
          dateFrom: fromStr,
        }
      };
    } else if (toStr) {
      f.date = {
        filterType: 'date',
        condition1: {
          type: 'lessThan',
          dateFrom: toStr,
        }
      };
    }

    return f;
  }, [fromStr, toStr]);

  const { data, isFetching } = SchedulesApi.useGetSchedulesQuery({
    offset: 0,
    limit: 0,
    filter: prepareFilter(sanitizedFilter, utcZone, GetSchedules_AliasMap),
    sort: prepareSort([
      { colId: 'date', sort: AgSortOrder.ASC }
    ], GetSchedules_AliasMap),
    options: {
      count: true,
      include: ['facility', 'schedule_stats']
    }
  }, {
    skip: !fromStr || !toStr,
    refetchOnMountOrArgChange: true
  });

  const events = useMemo(() =>
    data?.rows
      .filter(
        (schedule) =>
          schedule.scheduleStats &&
          (schedule.scheduleStats.lineItemsCount > 0 || schedule.scheduleStats.batchesCount > 0)
      )
      .map((schedule) => {
        const { date: start, facility, facilityId } = schedule;
        const startStr = DateTime.fromJSDate(start).toUTC().toISODate();
        return {
          title: facility?.name ?? '<undefined>',
          start,
          source: schedule,
          url: `/schedules/details/${startStr}/${facilityId}?scheduleForFacilityId="${facilityId}"&scheduleForDate=${startStr}`
        };
      }), [data]);

  useEffect(() => {
    if (calendar && isDate(date)) {
      const calendarApi: CalendarApi | undefined = (calendar.current as any)?.getApi();
      if (calendarApi) calendarApi.gotoDate(date);
    }
  }, [date, calendar]);

  const eventContent = useCallback(
    ({
      event: {
        title,
        extendedProps: {
          source: {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            id,
            /* eslint-enable @typescript-eslint/no-unused-vars */
            scheduleStats: { lineItemsCount, completedLineItemsCount },
          },
        },
      },
    }: { event: { title: string, extendedProps: { source: { id: string, scheduleStats: ScheduleStats} }}}) => {
      const progress = lineItemsCount === 0 ? 100.0 : (completedLineItemsCount / lineItemsCount) * 100.0;
      return (
        <div className="schedule-card">
          <div className="header">
            <div className="facility-name">{title}</div>

            <div className="filler" />

            <div className="tip">
              Items Completed &nbsp;
              <span className="percentage">{`${Math.floor(progress || 0)}%`}</span>
            </div>
          </div>

          <div className="progress-bar">
            <Progress
              size="small"
              percent={Math.floor(progress)}
              showInfo={false} /* We have better control over the layout if we format the text separately */
            />
          </div>
        </div>
      );
    },
    []
  );

  const onEventClick = useCallback((e: EventClickArg) => {
    // prevent the calendar from using its built-in navigation logic
    // we want to use the navigate function to prevent the page from needing to re-load
    e.jsEvent.preventDefault(); 
    navigate(e.event.url);
  }, [navigate]);

  return (
    <Authenticated>
      <Spin style={{ height: '100%' }} spinning={isFetching}>
        <FullCalendar
          timeZone="UTC"
          height="100%"
          ref={calendar}
          plugins={[dayGridPlugin, interactionPlugin]}
          events={events}
          eventContent={eventContent}
          eventDisplay="block"
          eventTextColor="#111"
          eventBackgroundColor="#fafafa"
          eventBorderColor="#ccc"
          initialDate={date as Date}
          showNonCurrentDates={false}
          eventClick={onEventClick}
          navLinks
          navLinkDayClick={() => { /* disable the default behavior */ }}
          datesSet={({ start, end }) => {
            setFilter({ from: start, to: end });

            if (start?.getFullYear() !== date?.getFullYear() || start?.getMonth() !== date?.getMonth()
              || start?.getDate() !== date?.getDate()) {
              setDate(start);
            }
          }}
        />
      </Spin>
    </Authenticated>
  );
};
