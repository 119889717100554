import React from "react";
import { get, isUndefined, pick, sortBy, sum, toPairs } from "lodash";
import { StatisticCard } from "../../index.js";
import { GetMetricsResolution, MetricsApi, MetricsSource, TimeseriesValue } from "../../../api/core/index.js";

interface MetricCardProps {
  title?: string;
  from: Date;
  to: Date;
  resolution?: GetMetricsResolution;
  timeColumn?: string;
  groupBy?: string[];
  source?: MetricsSource;
  seriesField?: string;
}

// Since `timeSeries` can have multiple dimensions and as such will include
// time series values for a given combination of them, we're grouping them by
// time and aggregating individual dimensions (metadata.keyValues) for easy
// access.
//
// Also condensing the resulting series into the top `limit` because
// displaying 80 dimensions isn't a good idea.
//
// TODO: test that all metadata dimensions sum up to the same total value
const condenseTimeseries = (data: TimeseriesValue[], limit = 4): any[] => {
  const seriesTotals = {} as any;
  const condensedSeries = {} as any;

  data?.forEach(({ time, value, metadata }) => {
    const timeISO = time.toISOString();
    if (isUndefined(condensedSeries[timeISO]))
      condensedSeries[timeISO] = { value, time };
    else condensedSeries[timeISO].value += value;

    metadata?.forEach((keyValue) => {
      // Since we're passing this off as a `.` delimited string to the chart
      // the actual key can't contain a dot.

      const sanitizedValue = keyValue?.value?.replace(".", "_")!;

      if (keyValue.value && value > 0) {
        if (get(condensedSeries[timeISO], [keyValue.key, sanitizedValue])) {
          condensedSeries[timeISO][keyValue.key][sanitizedValue] += value;
        } else {
          condensedSeries[timeISO][keyValue.key] = {
            ...condensedSeries[timeISO][keyValue.key],
            [sanitizedValue]: value,
          };
        }

        seriesTotals[keyValue.key] = {
          ...(seriesTotals[keyValue.key] || {}),
          [sanitizedValue]:
            (get(seriesTotals, [keyValue.key, sanitizedValue]) || 0) +
            condensedSeries[timeISO][keyValue.key][sanitizedValue],
        };
      }
    });
  });

  const condensedData = Object.values(condensedSeries);

  Object.keys(seriesTotals).forEach((seriesName: any) => {
    const rankedTotals = sortBy(toPairs(seriesTotals[seriesName]), 1).reverse();
    const keep = rankedTotals.slice(0, limit).map((value) => value[0]);
    // Sum up as "other" and delete all others

    condensedData.forEach((point: any, index) => {
      (condensedData[index] as any)[seriesName] = pick(point[seriesName], keep);
    });
  });

  return condensedData;
};

export const MetricCard = (props: MetricCardProps) => {
  const {
    title,
    source = MetricsSource.LineItems,
    from,
    to,
    resolution = "day",
    timeColumn = "created_at",
    groupBy,
    seriesField,
  } = props;

  const { data } = MetricsApi.useGetMetricsQuery({
    source,
    from,
    to,
    resolution,
    timeColumn,
    groupBy,
  });

  const series = condenseTimeseries(data ?? []);
  const total = sum(series.map(({ value }) => value));

  return (
    <StatisticCard
      title={title}
      chartType="area"
      value={total}
      series={series}
      field="value"
      seriesField={seriesField}
    />
  );
};
