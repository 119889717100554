import { DeserializationFunc } from "@fifthsun/ui/utils";
import { Blank, deserializeBlanksArray } from "./common.js";

export interface GetBlanksVariables {
  search?: string | null; // restricts the results to those where the fingerprint contains the search term
  id?: string | string[] | null;
  sort?: string | string[] | null; // TO DO: create an enum type for the fields that can be sorted on
  limit?: number | null;
}

export type GetBlanks = Blank[];
export const deserializeGetBlanks = deserializeBlanksArray as DeserializationFunc<GetBlanks>;
