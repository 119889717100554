import { AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse } from "../AgGrid.js";
import { DeserializationSchema, deserialize } from "@fifthsun/ui/utils";
import { Printer, deserializePrinterArray } from "./common.js";

export interface GetPrinters extends AgQueryResponse<Printer> {}

export const GetPrintersDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializePrinterArray
});

export const deserializeGetPrinters = deserialize<GetPrinters>(GetPrintersDeserializationSchema);

export interface PrinterFilterInput {}

export interface GetPrintersVariables extends AgGridQueryArgs<GetPrintersVariables_Options, PrinterFilterInput> {}

export interface GetPrintersVariables_Options extends AgGridQueryArgs_Options {}
