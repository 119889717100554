import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { AgConditionalOperator, AgDateFilterConditionType } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import type { RadioChangeEvent } from 'antd';
import { Radio, Select } from 'antd';
import { CustomFilterCallbacks, CustomFilterProps, useGridFilter } from "ag-grid-react";
import { IDoesFilterPassParams } from "ag-grid-community";
import { isEqual } from "lodash";

interface LocalDateFilterParams extends CustomFilterProps {
  timezoneField?: string;
}

const noDateTypes = ['blank', 'notBlank'];
const singleDateTypes = ['equals', 'notEqual', 'greaterThan', 'lessThan'];
const doubleDateTypes = ['inRange'];

const typeValues = [ {
  value: 'equals',
  label: 'Equals'
}, {
  value: 'notEqual',
  label: 'Not Equal'
}, {
  value: 'greaterThan',
  label: 'Greater Than'
}, {
  value: 'lessThan',
  label: 'Less Than'
}, {
  value: 'inRange',
  label: 'In Range'
}, {
  value: 'blank',
  label: 'Blank'
}, {
  value: 'notBlank',
  label: 'Not Blank'
} ];

export const LocalDateFilter = forwardRef((props: LocalDateFilterParams, _ref) => {
  const { model, timezoneField, onModelChange, onUiChange } = props;

  // TO DO: The form needs to have an Apply button like the built-in AgGrid Filters so that the user
  // can apply changes on the form to the underlying filter model. We also want to add a readonly
  // flag to the parameters so that we can prevent the user from modifying the filter. In the
  // interim, I will simply hard-code any LocalDateFilter as read-only.
  const readOnly  = true; 

  // default condition types and operator to match ag-grid built in filter behavior, rather than start off blank
  const [condition1Type, setCondition1Type] = useState<AgDateFilterConditionType | ''>("equals");
  const [condition1DateFrom, setCondition1DateFrom] = useState<string>('');
  const [condition1DateTo, setCondition1DateTo] = useState<string>('');
  const [condition2Type, setCondition2Type] = useState<AgDateFilterConditionType | ''>("equals");
  const [condition2DateFrom, setCondition2DateFrom] = useState<string>('');
  const [condition2DateTo, setCondition2DateTo] = useState<string>('');
  const [operator, setOperator] = useState<AgConditionalOperator | ''>("AND");

  // parse the model and update our various states accordingly
  useEffect(() => {
    setCondition1Type(model?.condition1.type ?? '');

    if (model?.condition1.dateFrom) {
      setCondition1DateFrom(DateTime.fromISO(model?.condition1.dateFrom).toFormat('yyyy-MM-dd') ?? '');
    } else {
      setCondition1DateFrom('');
    }

    if (model?.condition1.dateTo) {
      setCondition1DateTo(DateTime.fromISO(model?.condition1.dateTo).toFormat('yyyy-MM-dd') ?? '');
    } else {
      setCondition1DateTo('');
    }

    setCondition2Type(model?.condition2?.type ?? '');

    if (model?.condition2?.dateFrom) {
      setCondition2DateFrom(DateTime.fromISO(model?.condition2?.dateFrom).toFormat('yyyy-MM-dd') ?? '');
    } else {
      setCondition2DateFrom('');
    }

    if (model?.condition2?.dateTo) {
      setCondition2DateTo(DateTime.fromISO(model?.condition2?.dateTo).toFormat('yyyy-MM-dd') ?? '');
    } else {
      setCondition2DateTo('');
    }

    setOperator(model?.operator ?? '');
  }, [
    model, setCondition1Type, setCondition1DateFrom, setCondition1DateTo, 
    setCondition2Type, setCondition2DateFrom, setCondition2DateTo, setOperator
  ]);

  // inspect the model and see if we need to augment it to match what we are expecting server-side
  useEffect(() => {
    if (!model) { return; }

    const update = { ...model };

    if (update.condition1) {
      update.condition1.exact = true;
    }

    if (update.condition2) {
      update.condition2.exact = true;
    }

    update.timezoneField = timezoneField;

    if (!isEqual(update, model)) {
      onModelChange(update);
    }
  }, [model, timezoneField, onModelChange]);

  // condition 1 type is always visible, but creating a stub so the logic is already correct if this becomes conditional in the future
  const isCondition1TypeVisible = useMemo(() => {
    return true;
  }, []);

  const isCondition1DateFromVisible = useMemo(() => {
    return isCondition1TypeVisible && (doubleDateTypes.includes(condition1Type) || singleDateTypes.includes(condition1Type));
  }, [isCondition1TypeVisible, condition1Type]);

  const isCondition1DateToVisible = useMemo(() => {
    return isCondition1TypeVisible && doubleDateTypes.includes(condition1Type);
  }, [isCondition1TypeVisible, condition1Type]);

  const isOperatorVisible = useMemo(() => {
    return isCondition1TypeVisible && (noDateTypes.includes(condition1Type) ||
    (singleDateTypes.includes(condition1Type) && condition1DateFrom !== '') ||
    (doubleDateTypes.includes(condition1Type) && condition1DateFrom !== '' && condition1DateTo !== ''));
  }, [isCondition1TypeVisible, condition1Type, condition1DateFrom, condition1DateTo]);

  const isCondition2TypeVisible = useMemo(() => {
    return isOperatorVisible;
  }, [isOperatorVisible]);

  const isCondition2DateFromVisible = useMemo(() => {
    return isCondition2TypeVisible && operator !== '' && (doubleDateTypes.includes(condition2Type) || singleDateTypes.includes(condition2Type));
  }, [isCondition2TypeVisible, operator, condition2Type]);

  const isCondition2DateToVisible = useMemo(() => {
    return isCondition2TypeVisible && operator !== '' && doubleDateTypes.includes(condition2Type);
  }, [isCondition2TypeVisible, operator, condition2Type]);

  useEffect(() => {
    onUiChange();
  }, [
    onUiChange, timezoneField,
    condition1Type, condition1DateFrom, condition1DateTo,
    condition2Type, condition2DateFrom, condition2DateTo,
    isCondition1TypeVisible, isCondition1DateFromVisible, isCondition1DateToVisible, 
    isCondition2TypeVisible, isCondition2DateFromVisible, isCondition2DateToVisible
  ]);

  // validate the filter (only invoked when using the client-side row model, but always mandatory)
  const doesFilterPass = useCallback((_params: IDoesFilterPassParams) => {
    return true;
  }, []);

  const gridFilterCallbacks = useMemo<CustomFilterCallbacks>(() => ({
    doesFilterPass
  }), [doesFilterPass]);

  // register filter callbacks with the grid
  useGridFilter(gridFilterCallbacks);

  // todo: style this to better match ag-grid
  // visibility of elements should match the default ag-grid date filter
  // TO DO: As with standard ag-grid filters, we need to add an Apply button for changes to take effect
  return (
    <>
      <fieldset className="ag-filter-body-wrapper ag-simple-filter-body-wrapper" disabled={readOnly} aria-readonly={readOnly}>
        { isCondition1TypeVisible &&
          (
            <Select
              style={{ width: '100%' }}
              value={condition1Type}
              onChange={setCondition1Type}
              options={typeValues}
            />
          )
        }

        { isCondition1DateFromVisible &&
          <input type="date" value={condition1DateFrom} onChange={(e) => setCondition1DateFrom(e.target.value)} />
        }
        { isCondition1DateToVisible &&
          <input type="date" value={condition1DateTo} onChange={(e) => setCondition1DateTo(e.target.value)} />
        }

        { isOperatorVisible &&
          (
            <Radio.Group onChange={(e: RadioChangeEvent) => setOperator(e.target.value)} value={operator}>
              <Radio value="AND">AND</Radio>
              <Radio value="OR">OR</Radio>
            </Radio.Group>
          )
        }

        { isCondition2TypeVisible &&
          (
            <Select
              style={{ width: '100%' }}
              value={condition2Type}
              onChange={setCondition2Type}
              options={typeValues}
            />
          )
        }

        { isCondition2DateFromVisible &&
          <input type="date" value={condition2DateFrom} onChange={(e) => setCondition2DateFrom(e.target.value)} />
        }
        { isCondition2DateToVisible &&
          <input type="date" value={condition2DateTo} onChange={(e) => setCondition2DateTo(e.target.value)} />
        }
      </fieldset>
    </>
  );
});

LocalDateFilter.displayName = "LocalDateFilter";
