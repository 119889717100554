import React, { useMemo } from "react";
import { GetRowIdParams } from "ag-grid-enterprise";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import lineItemColumnDefs from "./lineItemColumnDefs.js";
import { GetLineItems_Include, LineItem } from "../../../api/core/index.js";
import useLineItemsDatasource, { DefaultLineItemsDatasourceProps, LineItemsDatasourceProps } from "./useLineItemsDatasource.js";
import { AgGridReactProps } from "ag-grid-react";
import { isArray } from "lodash";

export interface LineItemDataGridProps extends DataGridProps {
  include?: GetLineItems_Include[];
}

export const LineItemDataGrid = (props: LineItemDataGridProps) => {
  const { include, ...otherProps } = props;

  const options = useMemo<LineItemsDatasourceProps>(() => {
    if (isArray(include)) { 
      return {
        ...DefaultLineItemsDatasourceProps, 
        options: { 
          ...DefaultLineItemsDatasourceProps.options,
          include 
        } 
      }; 
    }
    return {};
  }, [include]);

  const datasource = useLineItemsDatasource(options);

  const gridProps = useMemo<AgGridReactProps>(() => {
    return {
      columnDefs: lineItemColumnDefs,
      rowModelType: 'serverSide',
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as LineItem).id,
      animateRows: true,
      ...otherProps
    };
  }, [otherProps]);

  return (
    <DataGrid
      {...gridProps}
      serverSideDatasource={datasource}
    />
  );
};
