import React, { useCallback, useMemo, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Button, Form, Select, Spin, notification, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";
import { DatePicker } from "../../../components/index.js";

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

export const CompletedWaypointReportPage = () => {
  const [isFreight, setFreight] = useState<boolean>(false);
  const [waypoint, setWaypoint] = useState<string>('package')
  const [startDate, setStartDate] = useState<Date | null>(
    DateTime.now().startOf('day').toJSDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    DateTime.now().startOf('day').plus({ days: 1 }).toJSDate()
  );
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [hasDownloadFailed, setDownloadFailed] = useState(false);

  const onChangeFreight = (e: CheckboxChangeEvent) => {
    setFreight(e.target.checked);
  };

  // only including the timezones that are relevant to our business, plus UTC
  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  const fileStartDate = useMemo(
    () => DateTime.fromJSDate(startDate!).setZone(timezone, { keepLocalTime: true }).toISO(),
    [startDate, timezone]
  );
  const fileEndDate = useMemo(
    () => DateTime.fromJSDate(endDate!).setZone(timezone, { keepLocalTime: true }).toISO(),
    [endDate, timezone]
  );
  const fileUrl = useMemo(() => `${CORE_BASE_URL}/scans/report/completed_waypoints?timezone=${timezone}&start_date=${fileStartDate}&end_date=${fileEndDate}&is_freight=${isFreight}&waypoint=${waypoint}`, [timezone, fileStartDate, fileEndDate, isFreight, waypoint]);

  // easier to generate file name here than alter CORS on server and parse filename from content disposition
  const fileName = useMemo(
    () => `completed_waypoint_${waypoint}_${isFreight ? 'freight' : 'non_freight'}_${DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ')}.csv`,
    [timezone, isFreight, waypoint]);

  // shenanigans to make the dowload detectable within the browser
  const download = useCallback(() => {
    setDownloadDisabled(true);
    setDownloadLoading(true);
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        setDownloadFailed(true);
        notification.open({
          key: "error",
          message: "Report Error",
          description: "Something went wrong while generating your report. Please try the manual download link.",
          duration: 0,
        });
      });
  }, [fileUrl, fileName]);

  const onClickManualDownload = useCallback(() => {
    setDownloadFailed(false);
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadDisabled(false);
      setDownloadLoading(false);
    }, 5000);
  }, []);

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Completed Waypoints Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={setTimezone}
          />
        </Form.Item>
        <Form.Item
          label="Start Date"
          extra="Generate a report between this date and the end date."
          style={{ marginBottom: 10 }}
        >
          <DatePicker value={startDate} onChange={setStartDate} />
        </Form.Item>
        <Form.Item
          label="End Date"
          extra="Generate a report between the start date and this date."
          style={{ marginBottom: 10 }}
        >
          <DatePicker value={endDate} onChange={setEndDate} />
        </Form.Item>
        <Form.Item
          label="Freight"
          extra="Generate a report for freight waypoints instead of non-freight waypoints."
          style={{ marginBottom: 10 }}
        >
          <Checkbox checked={isFreight} onChange={onChangeFreight} />
        </Form.Item>
        <Form.Item 
          label="Waypoint"
          extra="Generate a report for this waypoint."
          style={{ marginBottom: 10 }}>
          <Select
            value={waypoint}
            onChange={setWaypoint}
            options={[
              { value: 'batch', label: 'Batch' },
              { value: 'bin', label: 'Bin' },
              { value: 'branding', label: 'Branding' },
              { value: 'dry_back', label: 'Dry Back' },
              { value: 'dry_front', label: 'Dry Front' },
              { value: 'dry_largefront', label: 'Dry Large Front' },
              { value: 'dry_left', label: 'Dry Left' },
              { value: 'dry_leftfront', label: 'Dry Left Front' },
              { value: 'dry_leftleg', label: 'Dry Left Leg' },
              { value: 'dry_leftwrist', label: 'Dry Left Wrist' },
              { value: 'dry_lsleeve', label: 'Dry Left Sleeve' },
              { value: 'dry_neckhit', label: 'Dry Neck Hit' },
              { value: 'dry_right', label: 'Dry Right' },
              { value: 'dry_rightwrist', label: 'Dry Right Wrist' },
              { value: 'dry_rsleeve', label: 'Dry Right Sleeve' },
              { value: 'dry_wraparound', label: 'Dry Wraparound' },
              { value: 'hold_unit', label: 'Hold Unit' },
              { value: 'oos', label: 'Out of Stock' },
              { value: 'package', label: 'Package' },
              { value: 'postage', label: 'Postage' },
              { value: 'print_back', label: 'Print Back' },
              { value: 'print_front', label: 'Print Front' },
              { value: 'print_largefront', label: 'Print Large Front' },
              { value: 'print_left', label: 'Print Left' },
              { value: 'print_leftfront', label: 'Print Left Front' },
              { value: 'print_leftleg', label: 'Print Left Leg' },
              { value: 'print_leftwrist', label: 'Print Left Wrist' },
              { value: 'print_lsleeve', label: 'Print Left Sleeve' },
              { value: 'print_neckhit', label: 'Print Neck Hit' },
              { value: 'print_right', label: 'Print Right' },
              { value: 'print_rightwrist', label: 'Print Right Wrist' },
              { value: 'print_rsleeve', label: 'Print Right Sleeve' },
              { value: 'print_wraparound', label: 'Print Wraparound' },
              { value: 'pull', label: 'Pull' },
              { value: 'qc_issue', label: 'Quality Control Issue' },
              { value: 'receive', label: 'Receive' },
              { value: 'reprocess', label: 'Reprocess' },
              { value: 'set_box', label: 'Set Box' },
              { value: 'ship_confirm', label: 'Ship Confirm' },
              { value: 'shipping_error', label: 'Shipping Error' },
              { value: 'spray_out', label: 'Spray Out' },
              { value: 'stock_review', label: 'Stock Review' }
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={download}>Download</Button>
        </Form.Item>
        { isDownloadLoading &&
          (
            <Spin tip="Preparing Report..." size="large">
              <div className="content" />
            </Spin>
          )
        }
        { hasDownloadFailed &&
          (
            <a href={fileUrl} download onClick={onClickManualDownload}>Manual Download</a>
          )
        }
      </Form>
    </ErrorBoundary>
  );
};
