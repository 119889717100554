import { DeserializationFunc } from "@fifthsun/ui/utils";
import { Quotum, deserializeQuotumArray } from "./common.js";

export type GetQuotas = Quotum[];
export const deserializeGetQuotas = deserializeQuotumArray as DeserializationFunc<GetQuotas>;

export interface GetQuotasVariables {
  type?: string | null;
  parentId?: string | null;
  parentType?: string | null;
  date?: Date | null;
}
