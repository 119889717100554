import React, { useCallback, useMemo } from "react";
import { Button, Col, Row, Select as AntSelect, SelectProps as AntSelectProps, Tag, Tooltip } from "antd";
import { isEmpty } from "lodash";
import {
  CancelFilledRounded,
  CheckBoxRounded,
  CheckBoxOutlineRounded,
  DisabledByDefaultFilledRounded,
  ExpandMoreOutlinedRounded,
  Flaky,
} from "@fifthsun/ui";
import { DefaultOptionType } from "antd/lib/select";

import "./select.scss";

export interface SelectProps<T> extends AntSelectProps<T> {
  showSelectAll?: boolean;
}

const menuItemSelectedIcon = ({ isSelected }: any) =>
  (isSelected && <CheckBoxRounded style={{ fontSize: "18px" }} />) || (
    <CheckBoxOutlineRounded style={{ fontSize: "18px" }} />
  );

export const Select = (props: SelectProps<any>) => {
  const {
    className,
    allowClear,
    disabled,
    options,
    onChange,
    removeIcon,
    showSelectAll,
    suffixIcon,
    ...otherProps
  } = props;

  const isDisabled = disabled ?? otherProps.loading;
  const selectAllExcept = useCallback(
    (except: any) => {
      if (!options || isEmpty(options)) return;

      if (onChange)
        onChange(
          options.filter(({ value }) => value !== except).map(({ value }) => value),
          [] as any
        );
    },
    [onChange, options]
  );
  const selectAll = useCallback(
    () =>
      onChange?.(
        options?.filter(({ value }) => value !== "all").map(({ value }) => value),
        [] as any
      ),
    [onChange, options]
  );
  const selectNone = useCallback(() => onChange?.([], [] as any), [onChange]);

  const optionElements = useMemo(
    () =>
      options?.map(({ label, value, tag, ...rest }) => ({
        disabled: isDisabled,
        label:
          otherProps.mode === "multiple" ? (
            <Row style={{ width: "100%" }}>
              <Col flex="1 1 auto">{label}</Col>
              {tag !== undefined && tag !== null && (
                <Col flex="0 0 auto" className="ant-select-item-option-extras">
                  <Tag style={{ fontSize: 11 }}>{tag}</Tag>
                </Col>
              )}
              <Col flex="0 0 auto" className="ant-select-item-option-extras">
                <Tooltip placement="left" title={`Select All Except "${label}"`} mouseLeaveDelay={0}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      selectAllExcept(value);
                    }}
                    size="small"
                    type="link"
                    //color="currentColor"
                    icon={<Flaky style={{ fontSize: "1.25em" }} />}
                  />
                </Tooltip>
              </Col>
            </Row>
          ) : (
            label
          ),
        filterValue: label,
        value,
        ...rest,
      })),
    [otherProps.mode, isDisabled, options, selectAllExcept]
  );

  const dropdownRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
    <>
      {options?.length && showSelectAll && otherProps.mode === "multiple" && (
        <Tooltip
          placement="left"
          title={!otherProps.value?.length ? "Select All Options" : "Unselect All Options"}
          mouseLeaveDelay={0}
        >
          <div className="ant-select-item-toggle-all">
            {!otherProps.value?.length ? (
              <Button type="primary" disabled={isDisabled} onClick={() => selectAll()} size="small">
                Select All
              </Button>
            ) : (
              <Button type="primary" disabled={isDisabled} onClick={() => selectNone()} size="small">
                Unselect All
              </Button>
            )}
          </div>
        </Tooltip>
      )}
      {menu}
    </>
  );

  return (
    <AntSelect
      className={`mad-select ${className}`}
      allowClear={allowClear ?? { clearIcon: <CancelFilledRounded style={{ fontSize: "18px" }}/> } }
      dropdownRender={dropdownRender}
      filterOption={(inputValue: string, option: DefaultOptionType | undefined) =>
        (option?.filterValue ?? "").toString().toLowerCase().includes(inputValue.toLowerCase())
      }
      menuItemSelectedIcon={menuItemSelectedIcon}
      onChange={onChange}
      options={optionElements}
      popupClassName={className}
      removeIcon={removeIcon ?? <DisabledByDefaultFilledRounded style={{ fontSize: "16px" }} />}
      suffixIcon={suffixIcon ?? <ExpandMoreOutlinedRounded style={{ fontSize: "22px" }} />}
      {...otherProps}
    />
  );
};

