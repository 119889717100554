import CoreApi from "../CoreApi.js";
import { snakeCaseKeys, transformResponse, transformErrorResponse } from "@fifthsun/ui/utils";
import { GetPrintStats, GetPrintStatsVariables, deserializeGetPrintStats } from "./GetPrintStats.js";

// Extend the CoreApiw with /prints endpoints
export const PrintsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrintStats: builder.query<GetPrintStats, GetPrintStatsVariables>({
      query: (params) => ({
        url: '/prints/stats',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          printedBefore: params.printedBefore?.toISOString(),
          printedAfter: params.printedAfter?.toISOString()
        })
      }),
      transformResponse: transformResponse(deserializeGetPrintStats),
      transformErrorResponse: transformErrorResponse("Core", "getPrintStats"),
    }),
  }),
});

export default PrintsApi;
