// Common interfaces used for executing AgGrid queries against the Core service
// Must be maintained manually. NOTE: There is some transformation required to take the
// filters and sorts from the AgGrid component and to put it into this format. This is
// due to our previous usage of GraphQL - which required that we modify our data format to match
// what GraphQL could support.

import { SortModelItem } from "ag-grid-community";

export type AgFilterType = "text" | "number" | "date" | "set";

export type AgConditionalOperator = "AND" | "OR";

export enum AgSortOrder {
  ASC = "asc",
  DESC = "desc"
}

export interface AgFilterCondition<TFilterType extends AgFilterType> {
  filterType?: TFilterType | null;
}

// This is the expected format when submitting a query to the core service
export interface AgFilter<TFilterType extends AgFilterType, TConditionType extends AgFilterCondition<AgFilterType>> {
  filterType?: TFilterType | null;
  condition1: TConditionType;
  operator?: AgConditionalOperator | null;
  condition2?: TConditionType | null;
}

export type AgTextFilterConditionType = 'contains' | 'notContains' | 'equals' | 'notEqual' | 'startsWith' | 'endsWith' | 'blank' | 'notBlank';
export interface AgTextFilterCondition extends AgFilterCondition<"text"> {
  filter?: string | null; // not required when using type 'blank' or 'notBlank' types
  type: AgTextFilterConditionType;
}

export type AgDateFilterConditionType = 'equals' | 'notEqual' | 'greaterThan' | 'greaterThanOrEqual' | 'lessThan' | 'lessThanOrEqual' | 'inRange' | 'outOfRange' | 'blank' | 'notBlank';
export interface AgDateFilterCondition extends AgFilterCondition<"date"> {
  dateFrom?: string | null;
  dateTo?: string | null;
  type: AgDateFilterConditionType;

  /** when preparing to send a Date filter to the backend, we modify it in various ways
   * For example: it is assumed that the dates provided are in local time and are thus converted to UTC.
   * However, sometimes we have configured the filter precisely how we want it and want to bypass these
   * modifications. If you set exact to true, then prepareFilter will leave the above fields as-is.
   */
  exact?: boolean;
}

export type AgNumberFilterConditionType = 'equals' | 'notEqual' | 'lessThan' | 'lessThanOrEqual' | 'greaterThan' | 'greaterThanOrEqual' | 'inRange' | 'blank' | 'notBlank'
export interface AgNumberFilterCondition extends AgFilterCondition<"number"> {
  filter?: number | null;
  filterTo?: number | null;
  type: AgNumberFilterConditionType;
}

export interface AgSetFilterCondition extends AgFilterCondition<"set"> {
  values: string[];
}

export interface AgDateFilter extends AgFilter<"date", AgDateFilterCondition> {
  timezoneField?: string | null;
}

export interface AgNumberFilter extends AgFilter<"number", AgNumberFilterCondition> {}

export interface AgSetFilter extends AgFilter<"set", AgSetFilterCondition> {}

export interface AgTextFilter extends AgFilter<"text", AgTextFilterCondition> {}

export type AgTextOrSetFilter = AgTextFilter | AgSetFilter;

export interface AgSort<TColumns extends string> extends SortModelItem {
  /** Column Id to apply the sort to. */
  colId: TColumns;
}

export interface AgGridQueryArgs_Options {
  /** If set to true, then the query will return the total number of items in the database 
   * that satisfy the filter constraints. This is potentially an expensive operation, so it must
   * explicitly be requested.  
   */
  count?: boolean;
  
  /** If set to true, then the query will simply compute the total number of items in the database
   * which satisfy the filter constraints. No rows of data will be returned. 
   */
  countOnly?: boolean;
}

export interface AgGridQueryArgs<
  TOptions extends AgGridQueryArgs_Options = AgGridQueryArgs_Options,
  TFilter = Record<string, any>> {

  options?: TOptions | null;
  filter?: TFilter | null;
  sort?: string[] | null;
  offset?: number | null;
  limit?: number | null;
}

export interface AgQueryResponse<T = any> {
  count?: number | null | undefined;
  rows: T[];
}
