import React, { useMemo } from "react";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import orderColumnDefs from "./orderColumnDefs.js";
import { GetRowIdParams } from "ag-grid-enterprise";
import useOrdersDatasource, { OrdersDatasourceProps } from "./useOrdersDatasource.js";
import { Order } from "../../../api/core/index.js";
import { AgGridReactProps } from "ag-grid-react";

export interface OrdersDataGridProps extends Omit<DataGridProps<Order>, "datasource"> {
}

export const OrdersDataGrid = (props?: OrdersDataGridProps) => {
  const datasourceProps = useMemo<OrdersDatasourceProps>(() => ({}), []);
  const datasource = useOrdersDatasource(datasourceProps);

  const gridProps = useMemo<AgGridReactProps<Order>>(() => {
    return {
      context: {},
      rowModelType: "serverSide",
      blockLoadDebounceMillis: 100,
      multiSortKey: 'ctrl',
      columnDefs: orderColumnDefs,
      getRowId: (params: GetRowIdParams<Order>) => params.data.id,
      animateRows: true,
    };
  }, []);

  return (<DataGrid serverSideDatasource={datasource} {...gridProps} {...props} />);
};
