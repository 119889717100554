import CoreApi from "../CoreApi.js";
import { transformResponse, transformErrorResponse, snakeCaseKeys } from "@fifthsun/ui/utils";
import { GetPrinters, GetPrintersVariables, deserializeGetPrinters } from "./GetPrinters.js";

// Extends the CoreApi with /printers endpoints
export const PrintersApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getPrinters: builder.query<GetPrinters, GetPrintersVariables>({
      query: (params) => ({
        url: '/printers',
        method: "POST",
        body: {
          ...snakeCaseKeys(params),
          filter: params?.filter,
          sort: params?.sort
        }
      }),
      transformResponse: transformResponse(deserializeGetPrinters),
      transformErrorResponse: transformErrorResponse("Core", "getPrinters"),
    }),
  }),
});

export default PrintersApi;
