import { useMemo } from "react";
import { 
  ScansApi, GetScans, AgGridDatasourceProps, GetScansVariables_Options, GetScansVariables, 
  useAgGridDatasource 
} from "../../api/core/index.js";
import { Zone } from "luxon";
import { useTimeZone } from "@fifthsun/ui";

export interface ScansServerSideDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetScansVariables_Options, GetScansVariables, GetScans>, "endpoint" | "timeZone"> 
  {
    timeZone?: Zone | string;
  }

export function useScansServerSideDatasource(props?: ScansServerSideDatasourceProps) {
  const timeZone = useTimeZone();
  const activeTimeZone = props?.timeZone ?? timeZone;
  const datasourceProps = useMemo<AgGridDatasourceProps>(() => {
    return {
      timeZone: activeTimeZone,
      ...props,
      endpoint: ScansApi.endpoints.getScans,
      options: {
        count: true, // ??? - very expensive
        ...props?.options
      }
    };
  }, [props, activeTimeZone]);

  return useAgGridDatasource(datasourceProps);
}

export default useScansServerSideDatasource;
