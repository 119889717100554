import React, { useEffect } from "react";
import { OrdersApi } from "../../../../api/core/index.js";
import { Col, Row, Spin } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { OrderResult } from "./OrderResult.js";

export enum OrderIdentifierStatus {
  Pending, Loading, Confirming, Failing
}

export const FinalOrderIdentifierStates = [OrderIdentifierStatus.Confirming, OrderIdentifierStatus.Failing];

export interface OrderMapParams {
  id: string,
  status: OrderIdentifierStatus,
  orders: OrderIdentified[],
  onChange: (id: string, status: OrderIdentifierStatus, orders: OrderIdentified[]) => void
}

export interface OrderIdentified {
  ziftId: string,
  externalId: string | null | undefined,
  status: string | null | undefined,
  selected: boolean
}

export const OrderMap = (props: OrderMapParams) => {
  const { data } = OrdersApi.useFindOrdersQuery(
    { id: props.id }, 
    { skip: props.status === OrderIdentifierStatus.Pending }
  );

  const onChange = (ziftId: string, selected: boolean) => {
    const newOrders = props.orders.map((o) => {
      if (o.ziftId === ziftId) {
        o = {
          ...o,
          selected
        };
      }
      return o;
    });

    props.onChange(props.id, props.status, newOrders);
  };
  
  useEffect(() => {
    if (!data) {
      return;
    }

    const loadStatus = data.length > 0 ? OrderIdentifierStatus.Confirming : OrderIdentifierStatus.Failing;
    const ordersIdentified = data.map((o) => {
      // if we already have this order, use its selected status
      const oldData = props.orders.find((oi) => oi.ziftId === o.ziftId);
      let selected = true;
      if (oldData) {
        selected = oldData.selected;
      } else {
        selected = o.status !== 'invalid' && o.status !== 'new';
      }

      return {
        ziftId: o.ziftId,
        externalId: o.externalId,
        status: o.status,
        selected
      };
    });

    props.onChange(props.id, loadStatus, ordersIdentified);
  }, [data, props.id, props]);

  return (
    <>
      <Col span={1}>
        {props.status === OrderIdentifierStatus.Failing &&
          <CloseCircleTwoTone twoToneColor="#cc0000" /> }
        {props.status === OrderIdentifierStatus.Confirming &&
          <CheckCircleTwoTone twoToneColor="#00cc00" /> }
        {props.status === OrderIdentifierStatus.Loading &&
          <Spin tip={OrderIdentifierStatus[props.status]} size="small" /> }
      </Col>
      <Col span={7}>{props.id}</Col>
      <Col span={16}>
        <Row gutter={[16, 16]}>
          {props.orders.map((o) => (
            <OrderResult
              key={o.ziftId}
              ziftId={o.ziftId}
              externalId={o.externalId}
              status={o.status} 
              selected={o.selected}
              onChange={onChange}
            />
          ))}
        </Row>
      </Col>
    </>
  );
};

export default OrderMap;
