import { Printer, deserializePrinter } from "../Printers/index.js";
import { Schedule, deserializeSchedule } from "../Schedules/index.js";
import { Store, deserializeStoresArray } from "../Stores/index.js";
import { Facility, deserializeFacility } from "../Facilities/index.js";
import { Note, deserializeNote } from "../Notes/index.js";
import { deserialize, DeserializationSchema, deserializeArrayOf } from "@fifthsun/ui/utils";
import { BaseBatch, baseBatchDeserializationSchema } from "./BaseBatch.js";

export interface Batch extends BaseBatch {
  schedule?: Schedule | null;
  note?: Note | null;
  stores?: Store[] | null;
  autoFacility?: Facility | null;
  scheduleFacility?: Facility | null;
  lineItemStats?: Batch_lineItemStats | null;
  storeStats?: Batch_storeStats[] | null;
  printer?: Printer | null;
}

export const batchDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  ...baseBatchDeserializationSchema,  
  autoFacility: deserializeFacility,
  note: deserializeNote,
  printer: deserializePrinter,
  schedule: deserializeSchedule,
  stores: deserializeStoresArray,
});

export const deserializeBatch = deserialize<Batch>(batchDeserializationSchema);
export const deserializeBatchArray = deserializeArrayOf<Batch>(batchDeserializationSchema);

export interface LineItemStatusCount {
  new: number;
  deleted: number;
  canceled: number;
  outOfStock: number;
  defective: number;
  pulled: number;
  branding: number;
  branded: number;
  printing: number;
  printed: number;
  packaging: number;
  packaged: number;
  shipping: number;
  shipped: number;
}

export const EmptyLineItemStatusCount: Readonly<LineItemStatusCount> = Object.freeze({
  new: 0,
  deleted: 0,
  canceled: 0,
  outOfStock: 0,
  defective: 0,
  pulled: 0,
  branding: 0,
  branded: 0,
  printing: 0,
  printed: 0,
  packaging: 0,
  packaged: 0,
  shipping: 0,
  shipped: 0
});

export interface LineItemSourceCount {
  print: number;
  shelf: number;
  unknown?: number;
}

export const EmptyLineItemSourceCount: Readonly<LineItemSourceCount> = Object.freeze({
  print: 0,
  shelf: 0,
});

export interface Batch_lineItemStats {
  batchId?: string | null;
  totalCount: number;
  totalPrints: number;
  statusCount: LineItemStatusCount;
  sourceCount: LineItemSourceCount;
  printsCount: LineItemStatusCount;
  progressionCount: Exclude<LineItemStatusCount, "deleted" | "canceled">;
}

export interface Batch_storeStats {
  batchId: string | null;
  storeId: string | null;
  totalCount: number;
  totalPrints: number;
  statusCount: LineItemStatusCount;
  printsCount: LineItemStatusCount;
  progressionCount: Exclude<LineItemStatusCount, "deleted" | "canceled">;
}
