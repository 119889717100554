import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Empty,
  Space,
  Layout,
  notification,
  Form,
  Row,
  Input,
} from "antd";
import { ProCard } from "@ant-design/pro-card";
import { LoadingOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { Column } from "../../Charts/Column/index.js";
import { LiveIndicator, Grid } from "../../index.js";
import { Text } from "../../Typography/Text/index.js";
import { toAbsHumanDuration, sortTimeSeries, ErrorPayload } from "@fifthsun/ui/utils";
import { LineItemsApi, GetLineItemStatsVariables, DateTimeResolution } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import { useAuthentication } from "@fifthsun/ui";

const { Col } = Grid;

interface LineItemDashboardProps {
  filter?: Exclude<GetLineItemStatsVariables, 'seriesResolution'>;
  setFilter?: Function;

  // for unit-testing only
  testId?: string | null;
}

export const LineItemDashboard = (props: LineItemDashboardProps) => {
  const { setFilter, filter } = props;

  const [_timer, setTimer] = useState(new Date());
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  // Running a timer to allow updates to the update timer
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const { authenticated } = useAuthentication();

  const { data, error, isFetching: loading } = LineItemsApi.useGetLineItemStatsQuery({
    ...filter, seriesResolution: DateTimeResolution.Hour
  }, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 10000,
    skip: !authenticated,
  });

  useEffect(() => {
    data && setLastUpdatedAt(new Date());
  }, [data]);

  useLayoutEffect(() => {
    if (error) {
      const e = error as ErrorPayload;
      notification.open({
        key: "error",
        message: `${e.endpoint} Error: ${e.statusText ?? e.status?.toString() ?? JSON.stringify(e)}`,
        description: e.message,
        duration: 5,
      });
    }
  }, [error]);

  // TODO: use label.formatter on the chart to format, rather than letting
  // the sorter do it.
  const series = data?.scanSeries
    ? sortTimeSeries(data.scanSeries, "M/d/Y HH:mm")
    : [];

  return (
    <Layout data-testid={props.testId}>
      <Form
        style={{
          padding: 12,
        }}
      >
        <Row gutter={16}>
          <Col>
            <Input.Group compact />
          </Col>
        </Row>
      </Form>
      <ProCard.Group
        title="Scans"
        style={{ padding: 12 }}
        extra={[
          <div key="card-extras">
            <Space align="center">
              <Text small>
                <strong>Updated </strong>
                {
                  toAbsHumanDuration(DateTime.fromJSDate(lastUpdatedAt), DateTime.now())
                }
              </Text>
              {loading ? <LoadingOutlined /> : <LiveIndicator />}
            </Space>
          </div>,
        ]}
      >
        <Row gutter={16}>
          <div style={{ width: "100%", height: "70vh" }}>
            {isEmpty(series) || (
              <Column
                autoFit
                isStack
                data={series}
                xField="time"
                yField="count"
                seriesField="waypoint"
                legend={false}
                xAxis={{
                  label: {
                    autoRotate: true,
                  },
                }}
              />
            )}
            {isEmpty(series) && (
              <Empty>
                <Button
                  onClick={() => {
                    setFilter?.({});
                  }}
                >
                  Reset Filter
                </Button>
              </Empty>
            )}
          </div>
        </Row>
      </ProCard.Group>
    </Layout>
  );
};
