import React, { useEffect, useMemo, useState } from "react";
import OrderMap, { FinalOrderIdentifierStates, OrderIdentified, OrderIdentifierStatus } from "./OrderMap.js";
import { Button, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

const { Text } = Typography;

const maxConcurrentLoad = 1;

interface OrderIdentifier {
  id: string,
  status: OrderIdentifierStatus,
  orders: OrderIdentified[]
}

export interface D365SyncStepTwoParams {
  ids: string[],
  onFinish: (ids: string[]) => void
}

export const D365SyncStepTwo = (props: D365SyncStepTwoParams) => {
  const [orderIdentifiers, setOrderIdentifiers] = useState(Array<OrderIdentifier>());

  const isOrderIdentifiersDifferent = (newOrderIdentifiers: OrderIdentifier[]) => {
    const newData = JSON.stringify(newOrderIdentifiers);
    const oldData = JSON.stringify(orderIdentifiers);
    return newData !== oldData;
  };

  const onFinish = () => {
    const selectedZiftIds = orderIdentifiers.flatMap((oi) => oi.orders).filter((o) => o.selected).map((o) => o.ziftId);
    const uniqueZiftIds = selectedZiftIds.reduce((accumulator: string[], current) => {
      if (
        !accumulator.some(
          (item) => item === current
        )
      ) {
        accumulator.push(current);
      }
      return accumulator;
    }, []).filter((id) => id.length > 0);
    props.onFinish(uniqueZiftIds);
  };

  const onChange = (id: string, status: OrderIdentifierStatus, orders: OrderIdentified[]) => {
    const newOrderIdentifiers = orderIdentifiers.map((od) => {
      if (od.id === id) {
        od = {
          ...od,
          orders,
          status
        };
      }
      return od;
    });

    if (isOrderIdentifiersDifferent(newOrderIdentifiers)) {
      setOrderIdentifiers(newOrderIdentifiers);
    }
  };

  const isDisabled = useMemo(() => {
    return orderIdentifiers.length === 0 || 
      !orderIdentifiers.every((od) => FinalOrderIdentifierStates.includes(od.status));
  }, [orderIdentifiers]);

  useEffect(() => {
    const newOrderIdentifiers = props.ids.map((id) => ({
      id,
      status: OrderIdentifierStatus.Pending,
      orders: []
    }));

    setOrderIdentifiers(newOrderIdentifiers);
  }, [props.ids]);

  useEffect(() => {
    // do nothing is there are no order identifiers
    if (orderIdentifiers.length === 0) {
      return;
    }

    // do nothing if all order idenfitiers are in a final state
    if (orderIdentifiers.every((od) => FinalOrderIdentifierStates.includes(od.status))) {
      return;
    }

    // do nothing if we are already loading the max concurrent order identifiers
    const loadingCount = orderIdentifiers.filter((od) => od.status === OrderIdentifierStatus.Loading).length;
    if (loadingCount >= maxConcurrentLoad) {
      return;
    }

    // if we are here, recreate the array so that we can update the state
    let loadingAllowed = maxConcurrentLoad - loadingCount;
    const newOrderIdentifiers = orderIdentifiers.map((od) => {
      if (loadingAllowed > 0 && od.status === OrderIdentifierStatus.Pending) {
        od = {
          ...od,
          status: OrderIdentifierStatus.Loading
        };
        loadingAllowed--;
      }
      return od;
    });

    setOrderIdentifiers(newOrderIdentifiers);
  }, [orderIdentifiers]);

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card title="Instructions">
          <ul>
            <li>Wait for all of the identifiers to be processed.</li>
            <li>Review the returned orders for each identifier.</li>
            <li>There may be multiple orders per identifier, especially if an order has been split.</li>
            <li>Make sure that any orders you wish to sync are checked.</li>
            <li>Invalid and new orders are not sent to D365 and may not be checked.</li>
            <li>If no orders are found for an identifier, check that the identifier is correct.</li>
          </ul>
        </Card>
        <Row gutter={[16, 16]}>
          <Col span={1} />
          <Col span={7}>
            <Space>
              <Text strong>Identifier</Text>
              <Tooltip title="Id provided on the previous page">
                <InfoCircleTwoTone />
              </Tooltip>
            </Space>
          </Col>
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <Col span={1} />
              <Col span={9}>
                <Space>
                  <Text strong>ZIFT Id</Text>
                  <Tooltip title="Order Id used in ZIFT">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Space>
              </Col>
              <Col span={4}>
                <Space>
                  <Text strong>Status</Text>
                  <Tooltip title="Order status in ZIFT">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Space>
              </Col>
              <Col span={10}>
                <Space>
                  <Text strong>External Id</Text>
                  <Tooltip title="Id from external source of order">
                    <InfoCircleTwoTone />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Col>
          {orderIdentifiers.map((oi) => (
            <OrderMap 
              key={oi.id}
              id={oi.id}
              status={oi.status}
              orders={oi.orders}
              onChange={onChange}
            />
          ))}
        </Row>
        <Button type="primary" onClick={onFinish} disabled={isDisabled}>Continue</Button>
      </Space>
    </>
  );
};
