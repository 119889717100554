import React, { useMemo } from "react";
import { DatePicker } from "../DatePicker.js";
import { startOfDayLocal } from "@fifthsun/ui/utils";
import { RangeValueType } from "rc-picker/lib/PickerInput/RangePicker.js";

const { RangePicker } = DatePicker;
type RangePickerProps = Parameters<typeof RangePicker>[0];

export const DateLocalRangePicker = (props: RangePickerProps) => {
  const { value, ...otherProps } = props;
  
  const cleanValue = useMemo<RangeValueType<Date> | undefined>(() => {
    if (props.value === undefined || props.value === null) {
      return undefined;
    }
    const start = props.value[0];
    const end = props.value[1];

    const cleanStart = start ? startOfDayLocal(start) : start;
    const cleanEnd = end ? startOfDayLocal(end) : end;
    return [cleanStart ?? null, cleanEnd ?? null];
  }, [props.value]);

  return (
    <RangePicker
      {...otherProps}
      value={cleanValue}
    />
  );
};
