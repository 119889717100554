import React, { useMemo } from "react";
import { GetRowIdParams } from "ag-grid-community";
import { GetQuotumUsage_Include, QuotumUsage } from "../../../api/core/index.js";
import { DataGridProps, DataGrid } from "@fifthsun/ui/ag-grid";
import quotumUsageColumnDefs from "./quotumUsageColumnDefs.js";
import useQuotumUsageDatasource, { DefaultQuotumUsageDatasourceProps, QuotumUsageDatasourceProps } from "./useQuotumUsageDatasource.js";

export interface QuotumUsageDataGridProps extends DataGridProps {
  include?: GetQuotumUsage_Include[];
}

export const QuotumUsageDataGrid = (props: QuotumUsageDataGridProps) => {
  const { include, columnDefs, ...otherProps } = props;

  const datasourceProps = useMemo<QuotumUsageDatasourceProps>(() => {
    return {
      ...DefaultQuotumUsageDatasourceProps,
      options: {
        ...DefaultQuotumUsageDatasourceProps.options,
        include: include ?? DefaultQuotumUsageDatasourceProps.options?.include
      }
    };
  }, [include]);

  const datasource = useQuotumUsageDatasource(datasourceProps);

  const defaultGridProps = useMemo<DataGridProps>(() => {
    return {
      rowModelType: "serverSide",
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => `${(param.data as QuotumUsage).quotumId}-${(param.data as QuotumUsage).date}`,
      animateRows: true,
    };
  }, [datasource]);

  return (
    <DataGrid 
      {...defaultGridProps} 
      {...otherProps} 
      columnDefs={columnDefs ?? quotumUsageColumnDefs}
    />
  );
};

export default QuotumUsageDataGrid;
