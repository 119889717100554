import { ColDef, ColGroupDef } from "ag-grid-community";
import { LocaleNumberCellRenderer } from "@fifthsun/ui/ag-grid/renderers";

export const orderStatsColumnDefs: Array<ColDef | ColGroupDef> = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "name",
  },
  {
    field: "orderStats.ordersCount",
    headerName: "Orders",
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: "orderStats.lineItemsCount",
    headerName: "Line Items",
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: "orderStats.statusCount.active",
    headerName: "Active",
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: "orderStats.statusCount.pending",
    headerName: "Pending",
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: "orderStats.statusCount.complete",
    headerName: "Complete",
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: "orderStats.statusCount.canceled",
    headerName: "Canceled",
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: "orderStats.statusCount.invalid",
    headerName: "Invalid",
    cellRenderer: LocaleNumberCellRenderer,
  }
];

export default orderStatsColumnDefs;
