import { Quotum } from "../Quotas/index.js";
import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "@fifthsun/ui/utils";
import { Facility, deserializeFacility } from "../Facilities/index.js";
import { Store, deserializeStore } from "../Stores/index.js";

export interface QuotumUsage {
  quotumId: string;
  date: Date;
  shipped: number;
  printed: number;
  scheduled: number;
  quotum?: Quotum;
  store?: Store;
  facility?: Facility;
}

export const quotumUsageDeserializationSchema: DeserializationSchema = {
  date: deserializeDate,
  facility: deserializeFacility,
  store: deserializeStore,
};

export const deserializeQuotumUsage = deserialize<QuotumUsage>(quotumUsageDeserializationSchema);
export const deserializeQuotumUsageArray = deserializeArrayOf<QuotumUsage>(quotumUsageDeserializationSchema);
