import React, { useEffect, useMemo, useState } from "react";
import { Select, Form, Button, Space, Popover, Card, Slider } from "antd";
import { isEmpty, isEqual, isNil, omitBy } from "lodash";
import {
  useQueryParam,
  useQueryParams,
  StringParam,
  DateTimeParam,
  ArrayParam,
  NumberParam,
  useQueryParamsValues,
  createSchema,
} from "@fifthsun/ui/api/queryParams";
import { InfoCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import { DatePicker } from "../../DatePicker.js";
import { BlankSelect, FacilitySelect, StoreSelect } from "../../index.js";
import { LineItemStatus, OrderStatus, Waypoint } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker.js";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface LineItemFilterProps {
  onChange?: (filter: LineItemFilterInput) => void;
  only?: string[];
  except?: string[];
}

export interface LineItemFilterInput {
  from?: any | null;
  to?: any | null;
  shipBefore?: any | null;
  shipAfter?: any | null;
  scannedBefore?: any | null;
  scannedAfter?: any | null;
  waypoint?: Waypoint[] | null;
  status?: LineItemStatus[] | null;
  minProgress?: number | null;
  maxProgress?: number | null;
  orderStatus?: OrderStatus[] | null;
  facilityId?: string[] | null;
  batchId?: string[] | null;
  storeId?: string[] | null;
  blankId?: string[] | null;
  ziftId?: string[] | null;
  timezone?: string | null;
  hasBin?: boolean | null;
  completedBefore?: any | null;
  completedAfter?: any | null;
  printedBefore?: any | null;
  printedAfter?: any | null;
  updatedBefore?: any | null;
  updatedAfter?: any | null;
}

const filterParams = createSchema({
  facilityId: ArrayParam,
  storeId: ArrayParam,
  blankId: ArrayParam,
  orderStatus: ArrayParam,
  status: ArrayParam,
  waypoint: ArrayParam,
  from: DateTimeParam,
  to: DateTimeParam,
  shipBefore: DateTimeParam,
  shipAfter: DateTimeParam,
  scannedBefore: DateTimeParam,
  scannedAfter: DateTimeParam,
  minProgress: NumberParam,
  maxProgress: NumberParam,
});

type FilterSchemaType = typeof filterParams;

// Maintain empty filter object so we don't have to use `replace` on
// `setFilter`, because that just wipes out the entire query and not just
// params on `filter`
const emptyFilter = { ...filterParams } as any;
Object.keys(emptyFilter).forEach((key) => {
  emptyFilter[key] = undefined;
});

export const LineItemFilter = ({
  only,
  except,
  onChange,
}: LineItemFilterProps) => {
  const [preset, setPreset] = useQueryParam("preset", StringParam);
  const [filter, setFilter] = useQueryParams(filterParams);
  const [progress, setProgress] = useState<number[]>([0, 100]);

  const updateFilter = (newFilter: Partial<useQueryParamsValues<FilterSchemaType>>) => {
    setFilter((currentFilter) => {
      const updatedFilter = {
        ...currentFilter,
        ...newFilter,
      };
      for (const key of Object.keys(updatedFilter) as Array<keyof typeof updatedFilter>) {
        const value = updatedFilter[key];
        // Replace empty arrays or arrays containing a single undefined element with 'undefined'
        // so that they are not included in the query parameters
        if (value instanceof Array && value[0] === undefined) {
          updatedFilter[key] = undefined;
        }
      }
      onChange?.(updatedFilter as any);
      return updatedFilter;
    });
  };

  const handleDateChange = (dates: NoUndefinedRangeValueType<Date> | null, _dateStrings: [string, string]) => {
    updateFilter({
      from: dates?.[0] ? DateTime.fromJSDate(dates[0]).startOf('day').toJSDate() : undefined,
      to: dates?.[1] ? DateTime.fromJSDate(dates[1]).endOf('day').toJSDate() : undefined,
    });
  };

  const handleShippingDateChange = (dates: any) => {
    updateFilter({
      shipAfter: dates?.[0] ? DateTime.fromJSDate(dates[0]).startOf('day').toJSDate() : undefined,
      shipBefore: dates?.[1] ? DateTime.fromJSDate(dates[1]).endOf('day').toJSDate() : undefined,
    });
  };

  const handleScanDateChange = (dates: any, _dateStrings: string[]) => {
    updateFilter({
      scannedAfter: dates?.[0] ? DateTime.fromJSDate(dates[0]).startOf('day').toJSDate() : undefined,
      scannedBefore: dates?.[1] ? DateTime.fromJSDate(dates[1]).endOf('day').toJSDate() : undefined,
    });
  };

  const filterPresets = useMemo(
    () => [
      {
        name: "Stuck Items",
        key: "stuck",
        filter: {
          orderStatus: [OrderStatus.Active, OrderStatus.Pending],
          minProgress: 1,
          scannedBefore: DateTime.now().minus({ days: 2 }).toJSDate(),
          scannedAfter: undefined,
        },
        sortBy: "lastScanAt",
        sort: "ASC",
      },
    ],
    []
  );

  const applyPreset = (preset: any) => {
    setPreset(preset);
    if (preset) {
      const filterPreset = filterPresets.find((p) => p.key === preset);
      if (filterPreset) {
        setFilter((_prev) => ({ ...emptyFilter, ...filterPreset.filter }));
      }
    }
  };

  useEffect(() => {
    onChange?.(filter as any);

    // We need the intermediary state for quickly changing inputs such as
    // sliders to not cause a ruckus. It's sad, there has to be a better way.
    setProgress([
      filter?.minProgress ?? 0,
      (filter?.maxProgress ?? 100) as number,
    ]);

    // Set preset to matching preset
    const matchingFilter = filterPresets.find((obj) =>
      isEqual(omitBy(obj.filter, isNil), omitBy(filter, isNil))
    );
    if (preset !== matchingFilter?.key) setPreset(matchingFilter?.key);
  }, [onChange, filter, filterPresets, setPreset, preset]);

  const filterComponents: Record<string, any> = {
    preset: {
      label: "Preset",
      render: (
        <Space>
          <Select
            key="preset"
            popupMatchSelectWidth={false}
            value={preset!}
            onChange={(value) => applyPreset(value)}
          >
            {filterPresets.map((value: any) => (
              <Option key={value.key} value={value.key}>
                {value.name}
              </Option>
            ))}
          </Select>
          <Popover
            placement="right"
            content={
              <Card size="small" title="Presets">
                <ul>
                  <li>
                    <b>Stuck Items</b>: items that were started but left
                    untouched for at least two days.
                  </li>
                </ul>
              </Card>
            }
          >
            <InfoCircleOutlined style={{ cursor: "pointer" }} />
          </Popover>
        </Space>
      ),
    },
    storeId: {
      label: "Store",
      render: (
        <StoreSelect
          value={filter?.storeId}
          onChange={(storeId: string) => {
            updateFilter({ storeId: [storeId] });
          }}
        />
      ),
    },
    facilityId: {
      label: "Facility",
      render: (
        <FacilitySelect
          value={filter?.facilityId}
          onChange={(facilityId: string) => {
            updateFilter({ facilityId: [facilityId] });
          }}
        />
      ),
    },
    blankId: {
      label: "Blank",
      render: (
        <BlankSelect
          value={filter?.blankId}
          onChange={(blankId) => {
            updateFilter({ blankId });
          }}
        />
      ),
    },
    status: {
      label: "Status",
      render: (
        <Select
          key="status"
          mode="multiple"
          popupMatchSelectWidth={false}
          value={filter?.status as string[]}
          onChange={(status) => updateFilter({ status })}
        >
          {Object.entries(LineItemStatus).map(([key, value]) => (
            <Option key={key} value={value}>
              {key}
            </Option>
          ))}
        </Select>
      ),
    },
    progress: {
      label: "Progress",
      render: (
        <Slider
          range
          min={0}
          max={100}
          value={progress}
          onChange={setProgress}
          onChangeComplete={(value: number[]) => {
            updateFilter({
              minProgress: value[0],
              maxProgress: value[1],
            });
          }}
        />
      ),
    },
    orderStatus: {
      label: "Order Status",
      render: (
        <Select
          key="order-status"
          mode="multiple"
          popupMatchSelectWidth={false}
          value={filter?.orderStatus as string[]}
          onChange={(orderStatus) => updateFilter({ orderStatus })}
        >
          {Object.entries(OrderStatus).map(([key, value]) => (
            <Option key={key} value={value}>
              {key}
            </Option>
          ))}
        </Select>
      ),
    },
    waypoint: {
      label: "Waypoint",
      render: (
        <Select
          key="waypoint"
          mode="multiple"
          popupMatchSelectWidth={false}
          value={filter?.waypoint as string[]}
          onChange={(waypoint) => updateFilter({ waypoint })}
        >
          {Object.entries(Waypoint).map(([key, value]) => (
            <Option key={key} value={value}>
              {key}
            </Option>
          ))}
        </Select>
      ),
    },
    dateRange: {
      label: "Order Date Range",
      render: (
        <RangePicker
          placeholder={["Ordered After", "Ordered Before"]}
          allowClear
          allowEmpty={[true, true]}
          value={[filter?.from, filter?.to]}
          ranges={{
            Today: [DateTime.now().startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()],
            "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
            "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
            "This Year": [DateTime.now().startOf('year').toJSDate(), DateTime.now().endOf('year').toJSDate()],
          }}
          onChange={handleDateChange}
        />
      ),
    },
    scanRange: {
      label: "Scan Date Range",
      render: (
        <RangePicker
          placeholder={["Scanned After", "Scanned Before"]}
          allowClear
          allowEmpty={[true, true]}
          value={[filter?.scannedAfter, filter?.scannedBefore] as [any, any]}
          ranges={{
            Today: [DateTime.now().startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()],
            "This Hour": [DateTime.now().startOf('hour').toJSDate(), DateTime.now().endOf('hour').toJSDate()],
            "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
          }}
          onChange={handleScanDateChange}
        />
      ),
    },
    shippingDateRange: {
      label: "Shipping Date Range",
      render: (
        <RangePicker
          placeholder={["Ship After", "Ship Before"]}
          allowClear
          allowEmpty={[true, true]}
          value={[filter?.shipAfter, filter?.shipBefore] as [any, any]}
          ranges={{
            Today: [DateTime.now().startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()],
            "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
            "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
            "This Year": [DateTime.now().startOf('year').toJSDate(), DateTime.now().endOf('year').toJSDate()],
          }}
          onChange={handleShippingDateChange}
        />
      ),
    },
  };

  const filterItems = Object.keys(filterComponents).map((name) => {
    if (
      (only && !isEmpty(only) && !only.includes(name)) ||
      (except && !isEmpty(except) && except.includes(name))
    ) {
      return <></>;
    }

    const { render, label } = filterComponents[name];
    // Note: don't give Form.Item a `name` if it's purely used for
    // visuals or it'll screw w/ the context.
    return (
      <Form.Item key={label} label={label} style={{ marginBottom: 10 }}>
        {render}
      </Form.Item>
    );
  });

  return (
    <Form
      layout="vertical"
      style={{
        padding: 12,
      }}
    >
      <Form.Item>
        <Button
          icon={<RollbackOutlined />}
          onClick={() => {
            applyPreset(undefined);
            setFilter(emptyFilter);
          }}
        >
          Reset
        </Button>
      </Form.Item>

      {filterItems}
    </Form>
  );
};
