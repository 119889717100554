import React from "react";
import { Progress, Statistic, StatisticProps } from "antd";
import { ProCard } from "@ant-design/pro-card";
import "./index.scss";
import { get, isObject, uniq } from "lodash";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import { lighten } from "polished";
import { styled } from "styled-components";
import { DateTime } from "luxon";

interface StatisticCardProps extends StatisticProps {
  className?: string;
  series?: any[];
  field?: string | string[];
  seriesField?: string | string[];
  maxValue?: number;
  size?: "small" | "normal";
  chartType?: "area" | "line";
  chartColor?: string;
}

// `connectNulls` looks janky and has no auto fill, but recommend filling nulls
// See https://github.com/recharts/recharts/issues/702#issuecomment-303704716
const fillNulls = (data: any[]) => {
  const allSeries = uniq(
    data
      ?.map((point) =>
        isObject(point?.series) ? Object.keys(point?.series) : []
      )
      .flat()
  );

  const emptySeries = allSeries.reduce(
    (acc, key) => ({ ...acc, [key]: 0 }),
    {}
  );

  return data.map((point) => ({
    ...point,
    series: { ...emptySeries, ...point?.series },
  }));
};

export const StatisticCard = styled(
  ({
    className,
    title,
    value,
    maxValue,
    series,
    field,
    seriesField,
    suffix,
    size = "normal",
    precision,
    chartColor = "#1890ff",
  }: StatisticCardProps) => {
    let chart;
    let progressBar;
    let suffixValue = suffix;

    if (value && maxValue) {
      const percent = ((value as number) / maxValue) * 100.0;
      progressBar = <Progress key="progress-bar" percent={Math.floor(percent)} />;
      if (!suffix)
        suffixValue = ` / ${maxValue.toLocaleString("en-US")} (${percent.toFixed(1)}%)`;
    }

    if (series && field) {
      const sortedSeries = fillNulls(
        series
          .slice()
          .map((row) => {
            if (typeof row.time == "string")
              return {
                ...row,
                time: new Date(row.time),
              };
            return row;
          })
          .sort((a, b) => {
            if (!a) { return b ? -1 : 0; }
            if (!b) { return 1; }
            return (a.time as Date).getTime() - (b.time as Date).getTime();
          })
          .map((row: any) => ({
            series: seriesField ? get(row, seriesField) : undefined,
            time: row.time && DateTime.fromJSDate(row.time).toFormat("Y-MM-dd HH:mm"),
            value: get(row, field),
          }))
      );

      const areas = [<Area key="area-0" type="monotoneX" dataKey="value" />];
      if (seriesField) {
        uniq(
          sortedSeries
            .map((point) => (point?.series ? Object.keys(point?.series) : []))
            .flat()
        ).forEach((key: string, index) => {
          areas.push(
            <Area
              key={`area-${index + 1}`}
              connectNulls
              type="monotoneX"
              stroke={lighten(0.1 * index, chartColor)}
              fill={lighten(0.1 * index, chartColor)}
              dataKey={["series", key].join(".")}
              stackId="1"
            />
          );
        });
      }

      chart = (
        <ResponsiveContainer key="chart" width="100%" height={200}>
          <AreaChart
            data={sortedSeries}
            margin={{ top: 5, left: 1, right: 1, bottom: 2 }}
          >
            <Tooltip labelFormatter={(index) => sortedSeries[index]?.time} />
            {areas}
          </AreaChart>
        </ResponsiveContainer>
      );
    }

    return (
      <ProCard className={`mad-statistic-card size-${size} ${className}`}>
        <Statistic
          key="statistic"
          title={title}
          value={value}
          suffix={suffixValue}
          precision={precision}
        />
        {chart}
        {progressBar}
      </ProCard>
    );
  }
)`
  .recharts-wrapper {
    border: 1px solid ${({ chartColor }) => chartColor};
  }
`;
