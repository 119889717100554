import { SortModelItem } from "ag-grid-enterprise";
import { AliasMap } from "./AliasMap.js";
import lookupAliases from "./lookupAliases.js";
import { snakeCase } from "lodash";

// TO DO: Update core to accept a SortModelItem[] directly (after we have finished removing GraphQL)
export function prepareSort(sortModel: SortModelItem[] | null | undefined, aliasMap?: AliasMap) {
  return sortModel?.flatMap((x) => {
    const parts = lookupAliases(aliasMap, x.colId.split(".")).map(snakeCase);
    return `${parts.join(".")} ${x.sort}`;
  });
}

export default prepareSort;
