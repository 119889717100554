import React from "react";
import { Layout } from "antd";
import { DeprecatedAuthButton } from "../Button/index.js";
import { Logo, useFeatureFlag } from "@fifthsun/ui";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { FF_MAUTHRA_ENABLE } from "@fifthsun/ui/api/mauthra/oauth2";

export interface DeprecatedAuthenticatedParams {
}

export const DeprecatedAuthenticated = (props: React.PropsWithChildren<DeprecatedAuthenticatedParams>) => {
  // Mauthra is the new service for handling authentication.
  // if it is enabled, then we'll just ignore MSAL
  const mauthraEnabledFF = useFeatureFlag(FF_MAUTHRA_ENABLE);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  if (mauthraEnabledFF?.enabled || isAuthenticated) {
    return (<>{props.children}</>);
  }

  // Don't briefly flash login screen before the authentication state loads
  const loading = inProgress !== "none" && !isAuthenticated;
  if (loading) {
    return null;
  }

  return (
    <Layout.Content
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "black",
      }}
    >
      <div style={{ textAlign: "center", color: "white" }}>
        <div style={{ width: "15vw" }}>
          <Logo />
        </div>
        <br />

        <DeprecatedAuthButton />
      </div>
    </Layout.Content>
  );
};
