import { BatchScheduleManagerModalEventType, BatchScheduleManagerModalState } from "./BatchScheduleManagerModalProps.js";

const _shownEventType:BatchScheduleManagerModalEventType = 'shown';
export type ShownEventArgs = Readonly<BatchScheduleManagerModalState>;
export const createShownEvent = (state: Readonly<BatchScheduleManagerModalState>) => 
  new CustomEvent<ShownEventArgs>(_shownEventType, { detail: state });

const _hiddenEventType:BatchScheduleManagerModalEventType = 'hidden';
export type HiddenEventArgs = Readonly<BatchScheduleManagerModalState>;
export const createHiddenEvent = (state: Readonly<BatchScheduleManagerModalState>) => 
  new CustomEvent<HiddenEventArgs>(_hiddenEventType, { detail: state });

const _cancelEventType:BatchScheduleManagerModalEventType = 'cancel';
export type CancelEventArgs = Readonly<BatchScheduleManagerModalState>;
export const createCancelEvent = (state: Readonly<BatchScheduleManagerModalState>) => 
  new CustomEvent<CancelEventArgs>(_cancelEventType, { detail: state });

const _submitEventType:BatchScheduleManagerModalEventType = 'submit';
export type SubmitEventArgs = Readonly<BatchScheduleManagerModalState>;
export const createSubmitEvent = (state: Readonly<BatchScheduleManagerModalState>) => 
  new CustomEvent<SubmitEventArgs>(_submitEventType, { detail: state });

const _resultEventType:BatchScheduleManagerModalEventType = 'result';
export type ResultEventArgs = Readonly<BatchScheduleManagerModalState>;
export const createResultEvent = (state: Readonly<BatchScheduleManagerModalState>) => 
  new CustomEvent<ResultEventArgs>(_resultEventType, { detail: state });

const _resetEventType:BatchScheduleManagerModalEventType = 'reset';
export type ResetEventArgs = Readonly<BatchScheduleManagerModalState>;
export const createResetEvent = (state: Readonly<BatchScheduleManagerModalState>) => 
  new CustomEvent<ResetEventArgs>(_resetEventType, { detail: state });
