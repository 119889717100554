import { FetchArgs, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CORE_BASE_URL } from "./common.js";
import { FeatureFlags, Mauthra } from "@fifthsun/ui/api";
import { FF_MAUTHRA_ENABLE } from "@fifthsun/ui/api/mauthra/oauth2";
import { isString } from "lodash";
import { container } from "tsyringe";

// This contains the static configuration. However, we'll require a wrapper around it in order
// to adjust the baseUrl dynamically to reflect the tenant.
const generateBaseQuery = (baseUrl: string, oauth2: Mauthra.Oauth2.IOauth2Api, 
  ffService: FeatureFlags.IFeatureFlagsService) => fetchBaseQuery({
  baseUrl, // Need to pass this in as it needs to adjust with the tenant

  // Waiting on a PR that will allow us to configure endpoints with `extraOptions` that can
  // in turn be accessed here in prepareHeaders to grab an appropriately scoped access token
  // See
  prepareHeaders: async (headers, _api) => {
    const mauthraEnabledFF = ffService.enabled(FF_MAUTHRA_ENABLE);

    if (mauthraEnabledFF) {
      const csrf = await oauth2.acquire_csrf();
      headers.set("X-CSRF-Token", csrf);

      const accessToken = await oauth2.acquire_token();
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },

  credentials: "include"
});

// wrapper around baseQuery that dynamically constructs the baseUrl based upon the current tenant
const tenantAwareQuery: ReturnType<typeof fetchBaseQuery> = (inputArgs, api, extraOptions) => {
  const oauth2 = container.resolve<Mauthra.Oauth2.IOauth2Api>(Mauthra.Oauth2.IOauth2ApiSymbol);
  const ffService = container.resolve<FeatureFlags.IFeatureFlagsService>(FeatureFlags.IFeatureFlagsServiceSymbol);

  const mauthraEnabled = ffService.enabled(FF_MAUTHRA_ENABLE);
  const tenantCode = mauthraEnabled ? oauth2.tenantCode : ""; //selectTenantCode(api.getState() as RootState);

  // gracefully handle scenarios where data to generate the URL is missing
  if (tenantCode === undefined) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'Tenant Code Required',
        error: "Tenant Code Required",
      },
    };
  }

  const subdomain = tenantCode?.length ? `${tenantCode}` : "";
  const url = new URL(CORE_BASE_URL);
  if (subdomain) {
    url.hostname = `${subdomain}.${url.hostname}`;
  }

  // TO DO: Remove this once the CORs for the Core Service is updated to accept credentials
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
  const args: FetchArgs = isString(inputArgs) ? { url: inputArgs } : inputArgs;
  args.credentials = 'omit';

  const baseUrl = url.toString();
  const baseQuery = generateBaseQuery(baseUrl, oauth2, ffService);
  return baseQuery(args, api, extraOptions);
};

// All of the endpoints for Core should be registred under this API.
// We use the injectEndpoints functionality to break-up the definition of the CoreAPI
// across many files.
//
// See https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
export const CoreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: tenantAwareQuery,
  endpoints: (_builder) => ({
    /* The actual endpoints are added in via other files, but required to put a placeholder here */
  }),
});

export default CoreApi;
