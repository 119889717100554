import React from "react";
import { Spin as AntSpin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { LoadingMessage } from "./index.js";

const indicator = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const Spin = (props: SpinProps & { children: any, tip?: string }) => (
  <AntSpin
     
    {...{
      tip: props.tip ?? LoadingMessage.random(),
      indicator,
      ...props,
    }}
  />
);
