import React, { useState } from "react";
import { IStatusPanelParams } from "ag-grid-community";
import FloorviewSaveSortModal from "../Modals/FloorviewSaveSortModal/index.js";
import { Link } from "react-router-dom";

import "./FloorviewSortStatusPanel.scss";

export interface FloorviewSortStatusPanelParams extends IStatusPanelParams {
  scheduleIds: string[];
}

// TO DO: Add a Modal for loading the floorview sort
export const FloorviewSortStatusPanel = (params:FloorviewSortStatusPanelParams) => {
  const { api, scheduleIds } = params;

  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);

  return (
    <>
      <div className="floorview-sort-status-panel">
        <Link to="#" onClick={() => setOpenSaveModal(true)}>Save Sort Order</Link>
      </div>

      <FloorviewSaveSortModal 
        scheduleIds={scheduleIds}
        api={api}
        open={openSaveModal}
        close={() => setOpenSaveModal(false)}
      />

    </>
  );
};

export default FloorviewSortStatusPanel;
