import React, { useMemo } from "react";
import { GetRowIdParams } from "ag-grid-enterprise";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import storeColumnDefs from "./storeColumnDefs.js";
import { StoreDatasourceProps, useStoreDatasource } from "./useStoreDatasource.js";
import { GetStoresVariables_Options_Includes, Store } from "../../../api/core/index.js";

export interface StoreDataGridProps extends DataGridProps {
  include?: GetStoresVariables_Options_Includes[];
}

export const StoreDataGrid = (props: StoreDataGridProps) => {
  const { include, ...otherProps } = props;
  const options = useMemo<StoreDatasourceProps>(() => ({
    include: include ?? undefined
  }), [include]);

  const datasource = useStoreDatasource(options);

  const defaultGridProps = useMemo<DataGridProps>(() => {
    return {
      columnDefs: storeColumnDefs,
      rowModelType: "serverSide",
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as Store).id,
      animateRows: true,
    };
  }, [datasource]);

  return (
    <DataGrid {...defaultGridProps} {...otherProps} />
  );
};

export default StoreDataGrid;
