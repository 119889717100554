import CoreApi from "../CoreApi.js";
import { GetSettings, GetSettingsVariables, deserializeGetSettings } from "./GetSettings.js";
import { snakeCaseKeys, transformResponse, transformErrorResponse } from '@fifthsun/ui/utils';
import { UpdateSettings, UpdateSettingsVariables, deserializeUpdateSettings } from "./UpdateSettings.js";

// Extends the CoreApi with /settings endpoints
export const SettingsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<GetSettings, GetSettingsVariables>({
      query: (_params) => ({
        url: '/settings',
        method: 'GET'
      }),
      transformResponse: transformResponse(deserializeGetSettings),
      transformErrorResponse: transformErrorResponse("Core", "getSettings"),
    }),

    updateSettings: builder.mutation<UpdateSettings, UpdateSettingsVariables>({
      query: (params) => ({
        url: '/settings',
        method: 'PATCH',
        body: snakeCaseKeys(params)
      }),
      transformResponse: transformResponse(deserializeUpdateSettings),
      transformErrorResponse: transformErrorResponse("Core", "getSettings"),
    }),
  }),
});

export default SettingsApi;
