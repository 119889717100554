import { Menu } from 'antd';
import React, { useCallback, useState, useEffect, HTMLAttributes, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { MenuClickEventHandler, MenuInfo } from 'rc-menu/lib/interface.js';
import Logo from "./Logo/index.js";
import { useMenuItems } from "./useMenuItems.js";
import { Link } from 'react-router-dom';
import { ThemeColorModeToggle } from "@fifthsun/ui/theme";
import { ItemType } from 'antd/lib/menu/interface.js';
import { HidingEventArgs, TimeZoneModal, TimeZoneModalResults, useAuthentication, useFeatureFlag, useOauth2Service, UserTimeZone, useTimeZone } from '@fifthsun/ui';
import { FF_MAUTHRA_ENABLE } from '@fifthsun/ui/api/mauthra/oauth2';
import { msalClient } from '../../DeprecatedAuth/index.js';
import { Zone } from 'luxon';
import { FacilitiesApi } from '../../../api/core/index.js';
import { TimeZoneModalContext } from '@fifthsun/ui/dist/components/TimeZoneModal/TimeZoneModalContext.js';

const pathSegment = /\/[^/?]*/g;

export interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  setTimeZone: (tz: string | Zone) => void;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { setTimeZone, ...htmlAttrs } = props;

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const oauth2 = useOauth2Service();
  const navigate = useNavigate();
  const location = useLocation();
  const items = useMenuItems();

  const mauthraEnabledFF = useFeatureFlag(FF_MAUTHRA_ENABLE);
  const { authenticated } = useAuthentication();

  const activeTz = useTimeZone();
  const [showTimeZoneModal, setShowTimeZoneModal] = useState<boolean>(false);

  const onTimeZoneModalHidden = (e: CustomEvent<HidingEventArgs<TimeZoneModalResults>>) => {
    const results = e.detail.results;
    if (results?.zone) {
      setTimeZone(results.zone);
    }
  };

  const { data: facilityData } = FacilitiesApi.useGetFacilitiesQuery(
    { limit: 0 }, 
    { skip: mauthraEnabledFF?.enabled && !authenticated }
  );

  const curatedTimeZones = useMemo<string[] | undefined>(() => {
    if (!facilityData?.rows.length) { return undefined; }
    const timeZones = [...facilityData.rows.map((r) => r.timezone), "UTC", activeTz.name, UserTimeZone.name];
    return [...new Set<string>(timeZones)].sort();
  }, [activeTz, facilityData]);

  const timeZoneModalContext = useMemo<TimeZoneModalContext>(() => ({
    curatedTimeZones
  }), [curatedTimeZones]);

  useEffect(() => {
    let key = '';
    let curr: ItemType[] = items;
    let prev: any;
    const parts = location.pathname.match(pathSegment) ?? [];

    for (let i = 0; i < parts?.length; i++) {
      const nextKey = key + parts[i];
      const ndx = curr.findIndex((item) => item?.key === nextKey);
      if (ndx === -1) { break; }
      key = nextKey;
      prev = curr[ndx];
      if (!prev.children) { break; }
      curr = prev.children;
    }
    setSelectedKeys([key]);
  }, [items, location]);

  const onMenuClick: MenuClickEventHandler = useCallback((e : MenuInfo) => {
    if (e.key === "login") {
      if (mauthraEnabledFF?.enabled) {
        oauth2.login(false);
      } else {
        msalClient.loginPopup();
      }
    } else if (e.key === "logout") {
      if (mauthraEnabledFF?.enabled) {
        navigate(e.key);
      } else {
        msalClient.logoutRedirect();
      }
    } else if (e.key === "/settings/timezone") {
      setShowTimeZoneModal(true);
    } else {
      navigate(e.key);
    }
  }, [oauth2, mauthraEnabledFF?.enabled, navigate]);

  return (
    <div id="mad-page-header" {...htmlAttrs}>
      <TimeZoneModal 
        key="timezone-modal"
        open={showTimeZoneModal}
        close={() => setShowTimeZoneModal(false)}
        onHidden={onTimeZoneModalHidden}
        context={timeZoneModalContext}
      />
      <Link key="logo" id="mad-nav-menu-logo" to="/">
        <Logo />
      </Link>
      <div key="menu" id="mad-nav-menu-wrapper">
        <Menu
          id="mad-nav-menu"
          mode="horizontal"
          //disabledOverflow
          items={items}
          onClick={onMenuClick}
          selectedKeys={selectedKeys}
        />
      </div>
      <ThemeColorModeToggle />
    </div>
  );
};

export default PageHeader;
