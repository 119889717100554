import React, { useCallback, useEffect, useState } from "react";
import { Layout, Space, Row } from "antd";
import { startCase, capitalize } from "lodash";
import { ProCard } from "@ant-design/pro-card";
import { LoadingOutlined } from "@ant-design/icons";
import { Text } from "../../../components/Typography/Text/index.js";
import {
  LiveIndicator,
  StatisticCard,
  Grid,
  BatchFilter,
  Sider,
} from "../../../components/index.js";
import { BatchesApi, GetBatchStatsVariables } from "../../../api/core/index.js";
import { toAbsHumanDuration } from "@fifthsun/ui/utils";
import { DateTime } from "luxon";

const { Col } = Grid;
const { Content } = Layout;

export const BatchDashboardPage = () => {
  const [filter, setFilter] = useState<GetBatchStatsVariables>({});
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const { data, isFetching: loading } = BatchesApi.useGetBatchStatsQuery(filter, {
    pollingInterval: 10000,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => data && setLastUpdatedAt(new Date()), [data]);

  const statisticCards = ["new", "complete", "completed", "inProgress"].map(
    (status) => (
      <Col key={status}>
        <StatisticCard
          title={capitalize(startCase(status))}
          value={
            data ? (data as any)[`${status}Count`] : 0
          }
          field={`${status}Count`}
        />
      </Col>
    )
  );

  // Because we're lazy we're passing limit around on the input
  const updateFilter = useCallback((dirtyFilter: any) => {
    const cleanFilter = { ...dirtyFilter };
    delete cleanFilter.limit;
    setFilter(cleanFilter);
  }, []);

  return (
    <Layout>
      <Sider style={{ padding: 20 }}>
        <BatchFilter only={["dateRange"]} onChange={updateFilter} />
      </Sider>
      <Content
        style={{
          overflow: "hidden", // Why do we even have to? Someone uncomment and debug that flopping sizing :)
        }}
      >
        <ProCard.Group
          title="Stats"
          extra={[
            <div key="stats-extras">
              <Space align="center">
                <Text small>
                  <strong>Updated </strong>
                  {toAbsHumanDuration(DateTime.fromJSDate(lastUpdatedAt), DateTime.now())}
                </Text>
                {loading ? <LoadingOutlined /> : <LiveIndicator />}
              </Space>
            </div>,
          ]}
        >
          <Row gutter={16}>{statisticCards}</Row>
        </ProCard.Group>
      </Content>
    </Layout>
  );
};
