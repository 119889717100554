import React, { useEffect, useMemo } from "react";
import { Select, SelectProps, Spin } from "antd";
import { useDebouncedCallback } from "use-debounce";
import { BlanksApi } from "../../../api/core/index.js";

const { Option } = Select;

interface BlankSelectProps extends SelectProps<any> {}

export const BlankSelect = ({ value, onChange }: BlankSelectProps) => {
  const [loadBlanks, { isFetching, data } ] = BlanksApi.useLazyGetBlanksQuery();

  const searchBlanks = useDebouncedCallback(
    (search: string) => loadBlanks({ search }).unwrap(), 500);

  // Load initial selected value
  useEffect(() => {
    if (value) { loadBlanks({ id: value }); }
  }, [loadBlanks, value]);

  const options = useMemo(
    () =>
      data?.map((blank: any) => (
        <Option key={blank.id} value={blank.id}>
          {blank.fingerprint}
        </Option>
      )),
    [data]
  );

  return (
    <Select
      key="blank-id"
      placeholder="Select Blank"
      value={value}
      loading={isFetching}
      popupMatchSelectWidth={false}
      onChange={onChange}
      showSearch
      onSearch={searchBlanks}
      optionFilterProp="children"
      virtual={false}
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      defaultActiveFirstOption={false}
      allowClear
    >
      {options}
    </Select>
  );
};
