import React, { useMemo } from "react";
import { ColDef } from "ag-grid-enterprise";
import { FirstDataRenderedEvent, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { formatTwoDigitDate } from "@fifthsun/ui/utils";
import { LineItemDataGrid, LineItemDataGridProps } from "../../index.js";

import {
  AgDateFilter, AgSetFilterCondition, AgTextFilterCondition, LineItem, LineItemStatus,
  OrderStatus, ZiftStatus
} from "../../../api/core/index.js";

import { IDataGridPlugin, useSetFilterModelPlugin, useSetFilterModelPluginParams } from "@fifthsun/ui/ag-grid/plugins";
import { FacilityFilter, LocalDateFilter, StoreFilter } from "../../DataGrid/filters/index.js";
import { useLocation } from "react-router-dom";

export interface DemandReportDetailsFilterModel {
  'order.shipBy'?: AgDateFilter,
  'store.id'?: AgSetFilterCondition,
  'facility.id'?: AgSetFilterCondition,
  'order.status'?: AgSetFilterCondition,
  'status'?: AgSetFilterCondition,
  'bin.id'?: AgTextFilterCondition,
  'updatedAt'?: AgDateFilter,
  'ziftStatus'?: AgSetFilterCondition
}

export interface DetailDemandReportTableProps {
  filterModel?: DemandReportDetailsFilterModel
}

export const DetailDemandReportTable = (props : DetailDemandReportTableProps) => {
  const state = useLocation().state as DemandReportDetailsFilterModel;
  const filterModel = useMemo<DemandReportDetailsFilterModel>(() => ({
    ...state, ...props.filterModel
  }), [state, props.filterModel]);

  const setFilterModelPluginProps = useMemo<useSetFilterModelPluginParams>(() => ({filterModel}), [filterModel]);
  const setFilterModelPlugin = useSetFilterModelPlugin(setFilterModelPluginProps);
  const plugins = useMemo<IDataGridPlugin[]>(() => [setFilterModelPlugin], [setFilterModelPlugin]);

  const dateValueGetter = (params : ValueGetterParams<LineItem>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const date = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    return date ? formatTwoDigitDate(date, params.data?.facility?.timezone ?? 'UTC') : null;
  };

  const dateTimeValueGetter = (params : ValueGetterParams<LineItem>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const date = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    return date ? formatTwoDigitDate(date, params.data?.facility?.timezone ?? 'UTC', true) : null;
  };

  const defaultColDef: ColDef<LineItem> = useMemo(() => ({
    sortable: true,
    enableCellChangeFlash: true,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'left', fontWeight: 'bold'
    })
  }), []);

  const columnDefs = useMemo<Array<ColDef<any>>>(() => {
    return [ {
      field: 'store.name',
      headerName: 'Store',
      width: 200
    }, {
      field: 'facility.name',
      headerName: 'Facility',
      width: 200
    }, {
      field: 'batch.ziftId',
      headerName: 'Batch ID',
      width: 250,
      sort: 'asc',
      sortIndex: 0
    }, {
      field: 'order.ziftId',
      headerName: 'Order ID',
      width: 250,
      sort: 'asc',
      sortIndex: 1
    }, {
      field: 'ziftId',
      headerName: 'Order Product ID',
      width: 250,
      sort: 'asc',
      sortIndex: 2
    }, {
      field: 'blank.sku',
      headerName: 'SKU',
      width: 150
    }, {
      field: 'blank.color',
      headerName: 'Color',
      width: 150
    }, {
      field: 'blank.size',
      headerName: 'Size',
      width: 150
    }, {
      field: 'quantity',
      headerName: 'Total in Order',
      width: 100
    }, {
      field: 'printsScore',
      headerName: 'Prints',
      width: 100,
      valueGetter: (params: ValueGetterParams<LineItem>) => {
        // new values will eventually round up from core, but this makes old values a little more accurate
        return Math.ceil(params.data?.printsScore ?? 0);
      }
    }, {
      field: 'batch.lastConfirmDeliveredToPrinterAt',
      headerName: 'Delivered to Printer',
      width: 100,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<LineItem, boolean>) => {
        if (!params.value) {
          return '-';
        }
        return 'True';
      }
    }, {
      field: 'batch.lastConfirmBrandedAt',
      headerName: 'Branding Complete',
      width: 100,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<LineItem, boolean>) => {
        if (!params.value) {
          return '-';
        }
        return 'True';
      }
    }, {
      field: 'ziftStatus',
      headerName: 'Zift Status',
      width: 100,
      valueFormatter: (params: ValueFormatterParams<LineItem, string | null>) => {
        if (!params.value) {
          return '';
        }
        // enum case to human readable
        return params.value
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
          .join(" ");
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.values(ZiftStatus).map((v) => v.toLocaleLowerCase()),
      }
    }, {
      field: 'status',
      headerName: 'Status',
      width: 100,
      valueFormatter: (params: ValueFormatterParams<LineItem, string | null>) => {
        if (!params.value) {
          return '';
        }
        // enum case to human readable
        return params.value
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
          .join(" ");
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.values(LineItemStatus).map((v) => v.toLocaleLowerCase()),
      }
    }, {
      field: 'order.status',
      headerName: 'Order Status',
      width: 100,
      valueFormatter: (params: ValueFormatterParams<LineItem, string | null>) => {
        if (!params.value) {
          return '';
        }
        // camel case to human readable
        return params.value.replace(/([a-z0-9])([A-Z])/g, '$1_$2')
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1))
          .join(" ");
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.values(OrderStatus).map((v) => v.toLocaleLowerCase()),
      }
    }, {
      field: 'order.shipBy',
      headerName: 'Ship By',
      valueGetter: dateValueGetter,
      width: 125,
      cellClass: 'dateFormat',
      filter: LocalDateFilter,
      filterParams: {
        timezoneField: 'facility.timezone'
      }
    }, {
      field: 'updatedAt',
      headerName: 'Updated At',
      valueGetter: dateValueGetter,
      width: 125,
      hide: true,
      cellClass: 'dateFormat',
      filter: LocalDateFilter,
      filterParams: {
        timezoneField: 'facility.timezone'
      }
    }, {
      field: 'lastScanAt',
      headerName: 'Last Waypoint At',
      valueGetter: dateTimeValueGetter,
      width: 175,
      cellClass: 'dateFormat'
    }, {
      field: 'waypoint',
      headerName: 'Last Waypoint',
      width: 150,
      valueFormatter: (params: ValueFormatterParams<LineItem, string | null>) => {
        if (!params.value) {
          return '';
        }
        // convert oos to human readable
        if (params.value.toLocaleUpperCase() === 'OOS') {
          return 'Out of Stock';
        }
        // enum case to human readable
        return params.value
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
          .join(" ");
      }
    }, {
      field: 'bin.externalId',
      headerName: 'Bin Location',
      width: 150
    }, {
      field: 'bin.ziftId',
      headerName: 'Bin Id',
      width: 250
    }, {
      field: 'binSection.ziftId',
      headerName: 'Bin Section Id',
      width: 250
    }, {
      field: 'binFacility.name',
      headerName: 'Bin Facility',
      width: 200
    }, {
      field: 'bin.id',
      headerName: 'Bin',
      hide: true,
      filter: 'agTextColumnFilter',
    }, {
      field: 'facility.id',
      headerName: 'Facility',
      hide: true,
      filter: FacilityFilter,
    }, {
      field: 'store.id',
      headerName: 'Store',
      hide: true,
      filter: StoreFilter,
    } ];
  }, []);

  const gridProps = useMemo<Partial<LineItemDataGridProps>>(() => ({
    include: ['order', 'blank', 'store', 'facility', 'bin', 'batch', 'bin_section', 'bin_facility'],
    cacheBlockSize: 250000,
    defaultColDef,
    columnDefs,
    getRowStyle: (params) => {
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      }
      return { background: '#E2EFDA' };
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
    rowSelection: {
      mode: "multiRow",
      checkboxes: false,
      headerCheckbox: false,
      enableClickSelection: false,
    }
  }), [columnDefs, defaultColDef]);

  return (
    <LineItemDataGrid
      {...gridProps}
      plugins={plugins}
    />
  );
};
