import React, { useMemo } from "react";
import { Select, SelectProps } from "../../../components/index.js";
import { startCase, toLower } from "lodash";
import { FloorViewStatus } from "./index.js";

export interface FloorViewStatusSelectProps extends SelectProps<any> {
  statusCounts: Map<FloorViewStatus, number>;
}

export const FloorViewStatusSelect = ({ statusCounts, ...props }: FloorViewStatusSelectProps) => {
  const options = useMemo(() => {
    return Object.values(FloorViewStatus).map((optionValue) => {
      const labelText = startCase(toLower(optionValue));
      return {
        label: labelText,
        tag: statusCounts.get(optionValue) ?? 0,
        value: optionValue,
      };
    });
  }, [statusCounts]);

  return (
    <Select
      popupMatchSelectWidth={false}
      key="store-select"
      maxTagCount="responsive"
      maxTagPlaceholder="..."
      mode="multiple"
      options={options}
      placeholder="Select Status(s)"
      showSelectAll
      showSearch={false}
      {...props}
    />
  );
};
