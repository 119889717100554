import React, { useCallback, useMemo, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Button, Form, Select, Spin, notification } from "antd";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

export const PendingPrintDownloadReportPage = () => {

  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [hasDownloadFailed, setDownloadFailed] = useState(false);

  // only including the timezones that are relevant to our business, plus UTC
  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  const fileUrl = useMemo(() => `${CORE_BASE_URL}/line_items/report/pending_prints?timezone=${timezone}`, [timezone]);

  // easier to generate file name here than alter CORS on server and parse filename from content disposition
  const fileName = useMemo(
    () => `pending_prints_${DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ')}.csv`,
    [timezone]);

  // shenanigans to make the dowload detectable within the browser
  const download = useCallback(() => {
    setDownloadDisabled(true);
    setDownloadLoading(true);
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        setDownloadFailed(true);
        notification.open({
          key: "error",
          message: "Report Error",
          description: "Something went wrong while generating your report. Please try the manual download link.",
          duration: 0,
        });
      });
  }, [fileUrl, fileName]);

  const onClickManualDownload = useCallback(() => {
    setDownloadFailed(false);
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadDisabled(false);
      setDownloadLoading(false);
    }, 5000);
  }, []);

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Pending Print Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={setTimezone}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={download}>Download</Button>
        </Form.Item>
        { isDownloadLoading &&
          (
            <Spin tip="Preparing Report..." size="large">
              <div className="content" />
            </Spin>
          )
        }
        { hasDownloadFailed &&
          (
            <a href={fileUrl} download onClick={onClickManualDownload}>Manual Download</a>
          )
        }
      </Form>
    </ErrorBoundary>
  );
};
