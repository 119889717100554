import CoreApi from "../CoreApi.js";
import { snakeCaseKeys, transformResponse, transformErrorResponse } from '@fifthsun/ui/utils';
import { CreatePicksheet, CreatePicksheetVariables, deserializeCreatePicksheet } from "./CreatePicksheet.js";

// Extend the CoreApiw with /picksheets endpoints
export const PicksheetsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    createPicksheet: builder.mutation<CreatePicksheet, CreatePicksheetVariables>({
      query: (params) => ({
        url: '/picksheets',
        method: "POST",
        body: snakeCaseKeys(params)
      }),
      transformResponse: transformResponse(deserializeCreatePicksheet),
      transformErrorResponse: transformErrorResponse("Core", "createPicksheet"),
    }),
  }),
});

export default PicksheetsApi;
