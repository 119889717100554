export enum BatchFlag {
  CompleteOrders = "complete_orders",
  IsNeckhitEligible = "is_neckhit_eligible",
  MultiItemOrders = "multi_item_orders",
  SingleItemOrders = "single_item_orders"
}

export enum BatchStatus {
  BrandingComplete = "branding_complete",
  Canceled = "canceled",
  Complete = "complete",
  Deleted = "deleted",
  DeliveredToPrinter = "delivered_to_printer",
  InProgress = "in_progress",
  New = "new",
  Pulled = "pulled"
}

export enum BatchEvent {
  Start = "start",
  Reset = "reset",
  ConfirmPull = "confirm_pull",
  ConfirmBrandingComplete = "confirm_branding_complete",
  ConfirmDeliveredToPrinter = "confirm_delivered_to_printer",
  Complete = "complete"
}

export enum BatchType {
  Freight = "FreightBatch",
  Production = "ProductionBatch"
}
