import React from "react";
import { motion } from "framer-motion";

export const LiveIndicator = () => (
  <motion.div
    style={{
      backgroundColor: "red",
      borderRadius: "100%",
      width: ".5rem",
      height: "fit-content",
      alignSelf: "center",
      display: "inline-flex",
    }}
    transition={{ delay: 0, duration: 1, repeat: Infinity }}
    animate={{
      scale: [1, 1.1, 1],
      boxShadow: [
        "0px 0px 0px 0 rgba(123, 123, 123, 1.0)",
        "0px 0px 3px 4px rgba(123, 123, 123, 0.0)",
      ],
    }}
  >
    <div
      style={{
        width: "1rem",
        paddingBottom: "100%",
      }}
    />
  </motion.div>
);
