import { deserializeFacility } from "../Facilities/index.js";
import { LineItemStatus } from "../LineItems/index.js";
import { OrderStatus } from "../Orders/index.js";
import { deserializePrinter } from "../Printers/index.js";
import { DeserializationFunc, DeserializationSchema, deserialize, deserializeArrayOf } from "@fifthsun/ui/utils";
import { PrintArea } from "./common.js";

export interface PrintStats {
  area: PrintArea;
  facilityName?: string;
  printerModel?: string;
  count: number;
}

export const PrintStatsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  facility: deserializeFacility,
  printer: deserializePrinter
});

export const deserializePrintStats = deserialize<PrintStats>(PrintStatsDeserializationSchema);
export const deserializePrintStatsArray = deserializeArrayOf<PrintStats>(PrintStatsDeserializationSchema);

export type GetPrintStats = PrintStats[];
export const deserializeGetPrintStats = deserializePrintStatsArray as DeserializationFunc<GetPrintStats>;

export interface GetPrintStatsVariables {
  printedBefore?: Date | null;
  printedAfter?: Date | null;
  area?: PrintArea | PrintArea[] | null;
  lineItemStatus?: LineItemStatus | LineItemStatus[] | null;
  orderStatus?: OrderStatus | OrderStatus[] | null;
  facilityId?: string | string[] | null;
  printerId?: string | string[] | null;
  timezone?: string | null;
}
