import { ColDef, ColGroupDef } from "ag-grid-community";
import { DateTimeCellRenderer, TagCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { Waypoint } from "../../api/core/index.js";

export const ScansColDefs: /*ReadonlyArray */ Array<ColDef | ColGroupDef> = [
  {
    field: "id",
    hide: true,
    filter: "agTextColumnFilter"
  },
  {
    field: "lineItemId",
    filter: "agTextColumnFilter"
  },
  {
    field: "facilityId",
    filter: "agTextColumnFilter"
  },
  {
    field: "hostname",
    filter: "agTextColumnFilter"
  },
  {
    field: "waypoint",
    filter: "agSetColumnFilter",
    filterParams: {
      values: Object.values(Waypoint).map((v) => v.toLocaleLowerCase()),
    },
    cellRenderer: TagCellRenderer
  },
  {
    field: "time",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
];

export default ScansColDefs;
