import { GetSchedules_AliasMap, Schedule, ScheduleIdentifier, ScheduleStats, deserializeSchedule } from "../Schedules/index.js";
import { DeserializationFunc } from "@fifthsun/ui/utils";

export interface GetFloorviewSchedule extends Schedule {
  scheduleStats: ScheduleStats; // always present
}

export const deserializeGetFloorviewSchedule = 
  deserializeSchedule as DeserializationFunc<GetFloorviewSchedule>;

export interface GetFloorviewScheduleVariables {
  schedule: ScheduleIdentifier;
}

export const GetFloorviewSchedule_AliasMap = GetSchedules_AliasMap;
