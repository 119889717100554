import { DateTimeResolution } from "../common.js";
import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate } from "@fifthsun/ui/utils";
import { Waypoint } from "./common.js";

export interface GetLineItemStatsVariables {
  timezone?: string | null;
  from?: Date | null;
  to?: Date | null;
  storeId?: string | string[] | null;
  seriesResolution?: DateTimeResolution | null;
  shipBefore?: Date | null;
  shipAfter?: Date | null;
}

export interface GetLineItemStats {
  from: Date;
  to: Date;
  waypointStats: WaypointStats[];
  scanSeries: ScanTimeseriesValue[];
}

export interface WaypointStats {
  waypoint: Waypoint;
  lineItemCount: number;
}

export interface ScanTimeseriesValue {
  time: Date;
  waypoint: Waypoint;
  count: number;
}

export const ScanTimeseriesValueDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  time: deserializeDate
});

export const deserializeScanTimeseriesValue = 
  deserialize<ScanTimeseriesValue>(ScanTimeseriesValueDeserializationSchema);

export const deserializeScanTimeseriesValuesArray =
  deserializeArrayOf<ScanTimeseriesValue>(ScanTimeseriesValueDeserializationSchema);

export const GetLineItemStatsDeserializationSchema: Readonly<DeserializationSchema> = 
  Object.freeze({
    from: deserializeDate,
    to: deserializeDate,
    scanSeries: deserializeScanTimeseriesValuesArray
  });

export const deserializeGetLineItemStats = 
  deserialize<GetLineItemStats>(GetLineItemStatsDeserializationSchema);
