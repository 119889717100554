import { GetSchedules_AliasMap, ScheduleIdentifier } from "../Schedules/index.js";
import { Store, deserializeStoresArray } from "../Stores/index.js";
import { DeserializationFunc } from "@fifthsun/ui/utils";

export type GetFloorviewStores = Store[];

export const deserializeGetFloorviewStores = 
  deserializeStoresArray as DeserializationFunc<GetFloorviewStores>;

export interface GetFloorviewStoresVariables {
  schedule: ScheduleIdentifier;
}

export const GetFloorviewStores_AliasMap = GetSchedules_AliasMap;
