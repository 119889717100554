import React, { useMemo } from "react";
import { ColDef, FirstDataRenderedEvent, GridOptions } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Form, Spin } from "antd";
import { ArrayParam, DateLocalParam, useQueryParam, useQueryParams, withDefault } from "@fifthsun/ui/api/queryParams";
import { LocationFacilitySelect } from "../../Facility/index.js";
import { now } from "@fifthsun/ui/utils";
import { DateLocalRangePicker } from "../../DateLocalRangePicker/index.js";
import { LineItemStatus, OrderStatus, PrintArea, PrintStats, PrintsApi } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import { styled } from "styled-components";

// Styles used by the component
// NOTE: Styles must be created outside the component itself
const FilterWrapper = styled.div`
    flex: 0 1 auto;
  `;

const GridWrapper = styled.div`
    flex: 1 1 auto;
  `;

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
  `;

export const PrintReportTable = () => {
  const defaultStart = useMemo(() => DateTime.now().minus({ days: 7 }).toJSDate(), []);
  const defaultEnd = useMemo(() => now(), []);

  const [facilityId, setFacilityId] = useQueryParam("facilityId", ArrayParam);
  const [filter, setFilter] = useQueryParams({
    from: withDefault(DateLocalParam, defaultStart),
    to: withDefault(DateLocalParam, defaultEnd)
  });

  const updateFilter = (newFilter: any) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter,
    }));
  };

  const cleanFacilityId = useMemo(() => {
    const validFacilityId = facilityId?.filter((element): element is string => element !== null);
    return validFacilityId?.length ? validFacilityId : undefined;
  }, [facilityId]);

  const startDate = useMemo(() =>
    DateTime.fromJSDate(filter.from!).startOf('day').toJSDate(), [filter.from]);

  const endDate = useMemo(() =>
    DateTime.fromJSDate(filter.to!).startOf('day').toJSDate(), [filter.to]);

  const { data, isFetching: loading } = PrintsApi.useGetPrintStatsQuery({
    printedAfter: startDate,
    printedBefore: endDate,
    facilityId: cleanFacilityId,
    lineItemStatus: [LineItemStatus.Shipped, LineItemStatus.Shipping],
    orderStatus: [OrderStatus.Complete],
    area: [PrintArea.Front, PrintArea.Back]
  }, {
    refetchOnMountOrArgChange: true
  });

  const defaultColDef = useMemo<ColDef<PrintStats>>(() => ({
    sortable: true,
    enableCellChangeFlash: true,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'left', fontWeight: 'bold'
    })
  }), []);

  const columnDefs: Array<ColDef<PrintStats>> = useMemo(() => {
    return [ {
      field: 'facilityName',
      headerName: 'Facility',
      width: 150,
      sort: 'desc',
      sortIndex: 1
    }, {
      field: 'printerModel',
      headerName: 'Printer',
      width: 150,
      sort: 'desc',
      sortIndex: 2
    }, {
      field: 'area',
      headerName: 'Area',
      width: 150,
      sort: 'desc',
      sortIndex: 0
    }, {
      field: 'count',
      headerName: 'Count',
      width: 150
    } ];
  }, []);

  const rowData = useMemo(() => data ?? [], [data]);

  const gridOptions: GridOptions<PrintStats> = useMemo(() => ({
    rowData,
    defaultColDef,
    columnDefs,
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'left',
        }
      ]
    },
    getRowStyle: (params) => {
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      }
      return { background: '#E2EFDA' };
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [columnDefs, defaultColDef, rowData]);

  return (
    <>
      <Wrapper>
        <FilterWrapper>
          <Form
            size="small"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            layout="inline"
          >
            <Form.Item label="Facilities" style={{ minWidth: 240 }}>
              <LocationFacilitySelect
                value={cleanFacilityId}
                onChange={(facilityId: string[]) => {
                  setFacilityId(facilityId);
                }}
              />
            </Form.Item>
            <Form.Item label="Date Range">
              <DateLocalRangePicker
                placeholder={["Start", "End"]}
                allowClear={false}
                allowEmpty={[false, false]}
                value={[filter.from, filter.to] as [Date, Date]}
                ranges={{
                  "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
                  "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
                }}
                onChange={(values) => {
                  updateFilter({
                    from: values?.[0] ? values[0] : defaultStart,
                    to: values?.[1] ? values[1] : defaultEnd
                  });
                }}
              />
            </Form.Item>
          </Form>
        </FilterWrapper>
        <GridWrapper>
          <Spin spinning={loading}>
            <AgGridReact
              className="ag-theme-madengine"
              {...gridOptions}
            />
          </Spin>
        </GridWrapper>
      </Wrapper>
    </>
  );
};
