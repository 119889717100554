import { OrderStatus } from "../Orders/index.js";
import { DeserializationFunc, DeserializationSchema, deserialize, deserializeArrayOf } from "@fifthsun/ui/utils";

export interface GetLineItemWaypointsVariables {
  facilityId?: string | string[] | null;
  shipBefore?: Date | null;
  shipAfter?: Date | null;
  orderStatus?: OrderStatus | OrderStatus[] | null;
}

export interface LineItemWaypoint {
  value: number;
  waypoint: string;
}

export const LineItemWaypointDeserializationSchema: Readonly<DeserializationSchema> = 
  Object.freeze({});

export const deserializeLineItemWaypoint = 
  deserialize<LineItemWaypoint>(LineItemWaypointDeserializationSchema);

export const deserializeLineItemWaypointsArray =
  deserializeArrayOf<LineItemWaypoint>(LineItemWaypointDeserializationSchema);

export type GetLineItemWaypoints = LineItemWaypoint[];
export const deserializeGetLineItemWaypoints = 
  deserializeLineItemWaypointsArray as DeserializationFunc<GetLineItemWaypoints>;
