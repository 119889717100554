import CoreApi from "../CoreApi.js";
import { snakeCaseKeys, transformResponse, transformErrorResponse } from "@fifthsun/ui/utils";
import { GetQuotas, GetQuotasVariables, deserializeGetQuotas } from "./GetQuotas.js";

// Extend the CoreApiw with /quotas endpoints
export const QuotasApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuotas: builder.query<GetQuotas, GetQuotasVariables>({
      // This serialize the query args for the purpose of generating a cache key
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          ...queryArgs,
          date: queryArgs.date?.toISOString().split('T')[0]
        };
      },
      query: (params) => ({
        url: '/quotas',
        method: "GET",
        params: {
          ...snakeCaseKeys(params),
          date: params.date?.toISOString().split("T")[0], // YYYY-MM-DD
        }
      }),
      transformResponse: transformResponse(deserializeGetQuotas),
      transformErrorResponse: transformErrorResponse("Core", "getQuotas"),
    }),
  }),
});

export default QuotasApi;
