import { IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import * as Sentry from "@sentry/browser";

import BUC, { 
  BatchUnderConstructionItemsChangedEvent, 
  BatchUnderConstructionResetEvent 
} from "../../../../api/BatchUnderConstruction/index.js";

import { useCallback, useEffect } from "react";
import { IGridRefPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { LineItemsApi } from "../../../../api/core/index.js";

export const useBUCServerSideDatasource = 
  (gridRefPlugin: IGridRefPlugin): IServerSideDatasource => {
    const [trigger] = LineItemsApi.useLazyGetLineItemsQuery();
  
    const onReset = useCallback((_e:BatchUnderConstructionResetEvent) => {
      const api = gridRefPlugin.apiRef.current;
      if (!api) { return; }
      api.refreshServerSide();
    }, [gridRefPlugin]);
  
    const onItemsChanged = useCallback((e:BatchUnderConstructionItemsChangedEvent) => {
      const api = gridRefPlugin.apiRef.current;
      if (!api) { return; }

      if (e.detail.removedItems.length) {
        api.applyServerSideTransaction({
          remove: e.detail.removedItems.map((_) => ({ ziftId: _.ziftId })),
        });
      }

      if (e.detail.addedItems.length) {
        api.applyServerSideTransaction({
          add: e.detail.addedItems.map((_) => ({ ziftId: _.ziftId })),
        });      
      }
    }, [gridRefPlugin]);
  
    useEffect(() => {
      BUC.addEventListener('reset', onReset);
      BUC.addEventListener('items-changed', onItemsChanged);
      return () => {
        BUC.removeEventListener('reset', onReset);
        BUC.removeEventListener('items-changed', onItemsChanged);
      };
    }, [onReset, onItemsChanged]);

    return {
      getRows(params : IServerSideGetRowsParams) {
        if (!BUC.isInitialized() || !BUC.getLineItems()?.length) {
          params.success({
            rowData: [],
            rowCount: 0
          });
          return;
        }

        const start = params.request.startRow ?? 0;
        const end = params.request.endRow ?? 0;
        const offset = start;
        const limit = (end > start) ? end - start : 0;
        const ziftIds = BUC.getLineItems()?.slice(start, end).map((_) => _.ziftId) ?? [];

        const query = trigger({
          filter: {
            ziftId: {
              filterType: 'set',
              condition1: {
                values: ziftIds
              }
            }
          },
          options: {
            include: ["store", "order", "blank"]
          }
        }, false);

        query.then(
          (response) => {
            try {
              const { count, rows } = response.data!;

              let rowCount: number | undefined;
              if ((count === undefined || count === null)) {
                if (limit > 0 && limit > rows.length) {
                  rowCount = rows.length + offset;
                } else if (limit === 0) {
                  rowCount = rows.length + offset;
                }
              } else {
                rowCount = count;
              }

              // TO DO: Validate that all requested line items were retrieved...
              // if some ziftId was not found, then report an error/remove it
              params.success({
                rowCount, 
                rowData: rows
              });

            } catch(err) {
              params.fail();
              Sentry.captureException(err);
            }
          }, (err) => {
            params.fail();
            Sentry.captureException(err);
          });
      }, // getRows

      destroy: () => { /* perform any cleanup here */ }
    };
  };

export default useBUCServerSideDatasource;
