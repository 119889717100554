import React from "react";
import { styled } from "styled-components";
import { BatchScheduleManager } from "../../../../../pages/Batch/ManagePage/BatchScheduleManager.js";
import { CustomToolPanelProps } from "ag-grid-react";
import { Batch } from "../../../../../api/core/index.js";

const Container = styled.div`
  margin: 0px auto;
  padding: 10px;
`;

export interface BatchSchedulingToolPanelProps {
  hideSchedule?: boolean
  hideUnschedule?: boolean
}

export const BatchSchedulingToolPanel = 
(props: CustomToolPanelProps<Batch> & BatchSchedulingToolPanelProps) => {
  const { api, hideSchedule, hideUnschedule } = props;

  return (
    <Container>
      <BatchScheduleManager
        api={api}
        hideSchedule={hideSchedule}
        hideUnschedule={hideUnschedule}
      />
    </Container>
  );
};
