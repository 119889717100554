import {
  PublicClientApplication,
} from "@azure/msal-browser";

const baseUrl = (import.meta.env.REACT_APP_BASE_URL || "http://localhost:3000/").replace(/\/?$/, '/');

export const msalClient = new PublicClientApplication({
  auth: {
    clientId: "51343670-e742-428d-b89f-b7616fd7a34c",
    redirectUri: `${baseUrl}login/callback`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});
