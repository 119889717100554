import CoreApi from '../CoreApi.js';

export interface RunScriptParams {
  name: string,
  input?: any,
}

export interface RunScriptResult {
  output: any,
  stdout: string,
}

// Extends the CoreApi with /schedules endpoints
export const ScriptsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    exec: builder.mutation<RunScriptResult, RunScriptParams>({
      query: (params) => ({
        url: '/scripts',
        method: "POST",
        body: {
          ...params,
          input: params.input != null ? JSON.stringify(params.input) : null,
        },
      }),
      transformResponse: (body: RunScriptResult) => {
        return { ...body, output: body.output ? JSON.parse(body.output) : body.output };
      },
    }),
    script: builder.query<RunScriptResult, RunScriptParams>({
      query: (params) => ({
        url: '/scripts',
        method: "POST",
        body: {
          ...params,
          input: params.input != null ? JSON.stringify(params.input) : null,
        }
      }),
      transformResponse: (body: RunScriptResult) => {
        return { ...body, output: body.output ? JSON.parse(body.output) : body.output };
      },
    }),
  }),
});

export default ScriptsApi;
