import { AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse } from "../AgGrid.js";
import { DeserializationSchema, deserialize } from "@fifthsun/ui/utils";
import { Order, deserializeOrdersArray } from "./common.js";
import { AliasMap } from "../utils/index.js";

export type GetOrders_Include = 'facility' | 'store';

export interface GetOrdersVariables_Options extends AgGridQueryArgs_Options {
  include?: GetOrders_Include[] | null | undefined;
}

export interface GetOrdersVariables extends AgGridQueryArgs<GetOrdersVariables_Options> {}

export interface GetOrders extends AgQueryResponse<Order> {}

export const GetOrdersDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeOrdersArray
});

export const deserializeGetOrders = deserialize<GetOrders>(GetOrdersDeserializationSchema);

export const GetOrders_AliasMap: AliasMap = {};
