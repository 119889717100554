 
 
import React from "react";
import { Row as AntRow, RowProps as AntRowProps } from "antd";
import { isArray } from "lodash";

export interface RowProps extends AntRowProps {
  auto?: boolean;
}

export const Row = ({ auto = true, children, ...props }: RowProps) => {
  if (auto) {
    const childrenArray = isArray(children) ? children : [children];
    const maxSpan = Math.floor(24 / childrenArray.length);
    const spans = {
      xs: Math.min(24, Math.floor(maxSpan * 10)),
      sm: Math.min(24, Math.floor(maxSpan * 5)),
      md: Math.min(24, Math.floor(maxSpan * 5)),
      lg: Math.min(24, Math.floor(maxSpan * 2)),
      xl: Math.min(24, Math.floor(maxSpan * 2)),
      xxl: Math.min(24, maxSpan),
    };

    const cols = childrenArray?.map((child: any) =>
      React.cloneElement(child, spans)
    );

    return <AntRow {...props}>{cols}</AntRow>;
  }

  return <AntRow {...props}>{children}</AntRow>;
};
