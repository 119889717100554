import React, { useMemo } from "react";
import { OrdersDataGrid } from "../../../components/Order/DataGrid/OrdersDataGrid.js";
import { useGridStateSearchParamPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { Authenticated } from "@fifthsun/ui";

export const OrderManagePage = () => {
  const gridStatePlugin = useGridStateSearchParamPlugin();
  const plugins = useMemo(() => [gridStatePlugin], [gridStatePlugin]);

  return (
    <Authenticated>
      <OrdersDataGrid plugins={plugins} />
    </Authenticated>
  );
};
