import { ColDef, ColGroupDef } from "ag-grid-community";
import { DateTimeCellRenderer, ProgressCellRenderer, TagCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { LineItemStatus } from "../../../api/core/index.js";

export const lineItemColumnDefs: /*ReadonlyArray */ Array<ColDef | ColGroupDef> = [
  {
    field: "id",
    hide: true,
    filter: "agTextColumnFilter"
  },
  {
    field: "ziftId",
    filter: "agTextColumnFilter"
  },
  {
    field: "status",
    filter: "agSetColumnFilter",
    filterParams: {
      values: Object.values(LineItemStatus).map((v) => v.toLocaleLowerCase()),
    },
    cellRenderer: TagCellRenderer
  },
  {
    field: "progress",
    filter: "agNumberColumnFilter",
    hide: true,
    cellRenderer: ProgressCellRenderer,
  },
  {
    field: "store.name",
    headerName: "Store::Name",
    filter: "agTextColumnFilter"
  },
  {
    field: "order.ziftId",
    headerName: "Order::Zift Id",
    filter: "agTextColumnFilter"
  },
  {
    field: "order.shipBy",
    headerName: "Order::Ship By",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "compoundPriority",
    headerName: "Compound Priority",
    filter: "agNumberColumnFilter"
  },
  {
    field: "blank.fingerprint",
    headerName: "Blank::Fingerprint",
    filter: "agTextColumnFilter",
  },
  {
    field: "lastScanAt",
    headerName: "Last Scan At",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "createdAt",
    headerName: "Created At",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    filter: "agDateColumnFilter",
    cellRenderer: DateTimeCellRenderer
  }
];

export default lineItemColumnDefs;
