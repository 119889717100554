import CoreApi from "../CoreApi.js";
import { transformResponse, transformErrorResponse, snakeCaseKeys } from '@fifthsun/ui/utils';
import { GetMetrics, GetMetricsVariables, deserializeGetMetrics } from "./GetMetrics.js";

// Extend the CoreApi with /metrics endpoints
export const MetricsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({

    getMetrics: builder.query<GetMetrics, GetMetricsVariables>({
      query: (params) => ({
        url: '/metrics',
        method: "GET",
        params: snakeCaseKeys({
          ...params,
          from: params.from?.toISOString(),
          to: params.to?.toISOString()
        })
      }),
      transformResponse: transformResponse(deserializeGetMetrics),
      transformErrorResponse: transformErrorResponse("Core", "getMetrics"),
    }),

  }),
});

export default MetricsApi;
