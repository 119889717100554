import { useMemo } from "react";
import { AgGridDatasourceProps, GetQuotumUsage, GetQuotumUsageVariables, GetQuotumUsageVariables_Options, QuotumUsageApi, useAgGridDatasource } from "../../../api/core/index.js";
import { useTimeZone } from "@fifthsun/ui";
import { Zone } from "luxon";

export interface QuotumUsageDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetQuotumUsageVariables_Options, GetQuotumUsageVariables, GetQuotumUsage>, "endpoint" | "timeZone"> {
    timeZone?: Zone | string;
  }

export const DefaultQuotumUsageDatasourceProps = Object.freeze<QuotumUsageDatasourceProps>({
  options: {
    include: ['quotum', 'store']
  }
});

export function useQuotumUsageDatasource(props: QuotumUsageDatasourceProps = DefaultQuotumUsageDatasourceProps) {
  const timeZone = useTimeZone();
  const activeTimeZone = props?.timeZone ?? timeZone;
  const datasourceProps = useMemo<AgGridDatasourceProps<GetQuotumUsageVariables_Options>>(() => ({
    timeZone: activeTimeZone,
    ...props,
    endpoint: QuotumUsageApi.endpoints.getQuotumUsage,
    options: {
      count: true,
      ...DefaultQuotumUsageDatasourceProps.options,
      ...props.options
    },
  }), [props, activeTimeZone]);

  return useAgGridDatasource<GetQuotumUsageVariables_Options>(datasourceProps);
}

export default useQuotumUsageDatasource;
