import CoreApi from "../CoreApi.js";
import { transformResponse, transformErrorResponse, snakeCaseKeys } from "@fifthsun/ui/utils";
import { GetScans, GetScansVariables, deserializeGetScans } from "./GetScans.js";

// Extends the CoreApi with /scans endpoints
export const ScansApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getScans: builder.query<GetScans, GetScansVariables>({
      query: (params) => ({
        url: '/scans',
        method: "POST",
        body: {
          ...snakeCaseKeys(params),
          filter: params?.filter,
          sort: params?.sort
        }
      }),
      transformResponse: transformResponse(deserializeGetScans),
      transformErrorResponse: transformErrorResponse("Core", "getScans"),
    }),
  }),
});

export default ScansApi;
