import { DeserializationSchema, deserialize, deserializeArrayOf, deserializeDate, deserializeJson } from "@fifthsun/ui/utils";
import { OrderTimeColumn } from "./OrderTimeColumn.js";

export interface OrderStatusCounts {
  new: number;
  active: number;
  pending: number;
  complete: number;
  canceled: number;
  invalid: number;
}

export const EmptyOrderStatusCounts: Readonly<OrderStatusCounts> = Object.freeze({
  new: 0,
  active: 0,
  pending: 0,
  complete: 0,
  canceled: 0,
  invalid: 0
});

export interface OrderStats {
  timeColumn: OrderTimeColumn; // the time column used when generating these stats
  time: Date; // the date time (truncated as part the seriesResolution in the request) for this OrderStats
  from: Date; // all orders considered for computing these stats had to satisify: [time_column] >= [from]
  to: Date; // all orders considered for computing these stats had to satisfy: [time_column] < [to]
  storeIds: string[]; // all of the storeIds associated with the orders for this OrderStats block
  ordersCount: number; // the number of orders found in the specified date range with respects [time_column]
  lineItemsCount: number; // the number of associated line_items
  statusCount: OrderStatusCounts; // a break-down of the line_items in terms of their statuses
  firstOrderAt: Date | null; // the smallest [timeColumn] value amongst the Orders for this OrderStats block
  lastOrderAt: Date | null; // the greatest [timeColumn] value amongst the Orders for this OrderStats block
}

export const OrderStatsDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  time: deserializeDate,
  from: deserializeDate,
  to: deserializeDate,
  statusCount: deserializeJson<OrderStatusCounts>,
  firstOrderAt: deserializeDate,
  lastOrderAt: deserializeDate
});

export const deserializeOrderStats = deserialize<OrderStats>(OrderStatsDeserializationSchema);
export const deserializeOrderStatsArray = deserializeArrayOf<OrderStats>(OrderStatsDeserializationSchema);
