import { deserializeDate } from "@fifthsun/ui/utils";
import { DeserializationSchema, deserialize, deserializeArrayOf } from "@fifthsun/ui/utils";

export enum FacilityStatus {
  Active = "active", 
  Deleted = "deleted", 
  Disabled = "disabled"
}

export interface Facility {
  id: string;
  timezone: string;
  status: FacilityStatus;
  capacity: number;
  createdAt: Date;
  updatedAt: Date;

  ziftId?: string | null;
  name?: string | null;
  code?: string | null;
  external?: boolean | null;
  siteId?: string | null;
  locationId?: string | null;
  organizationId?: string | null;
}

export const facilityDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  createdAt: deserializeDate,
  updatedAt: deserializeDate
});

export const deserializeFacility = deserialize<Facility>(facilityDeserializationSchema);
export const deserializeFacilitiesArray = deserializeArrayOf<Facility>(facilityDeserializationSchema);
