import { AgDateFilter, AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse, AgTextOrSetFilter } from "../AgGrid.js";
import { AliasMap } from "../utils/index.js";
import { deserialize, DeserializationSchema } from "@fifthsun/ui/utils";
import { Schedule, deserializeScheduleArray } from "./common.js";

export interface GetSchedules extends AgQueryResponse<Schedule> {}

export const GetSchedulesDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeScheduleArray,
});

export const deserializeGetSchedules = 
  deserialize<GetSchedules>(GetSchedulesDeserializationSchema, "[GetSchedules]");

export type GetSchedulesVariables_Include = "facility" | "schedule_stats" | "stores";
export interface GetSchedulesVariables_Options extends AgGridQueryArgs_Options {
  include?: GetSchedulesVariables_Include[] | null | undefined;
}

export interface GetSchedulesVariables extends AgGridQueryArgs<GetSchedulesVariables_Options, GetSchedulesFilter> {}

// TO DO: Add GetSchedulesFilter_facility sub-interface if we find the need to filter by fields
// within the facility (other than the id)
export interface GetSchedulesFilter {
  id?: AgTextOrSetFilter | null;
  date?: AgDateFilter | null;
  facilityId?: AgTextOrSetFilter | null;
  createdAt?: AgDateFilter | null;
  updatedAt?: AgDateFilter | null;
}

// TO DO: Add "facility.xxx" fields for sorting as well, should we need it
export type GetSchedulesSortField = "id" | "date" | "facilityId" | "createdAt" | "updatedAt";

export const GetSchedules_AliasMap: AliasMap = {
};
