import React from "react";
import { List, Tag } from "antd";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  WrappedComponentFactory,
} from "react-sortable-hoc"; // TO DO: Remove, this is the only component that relies upon this deprecated library
import { arrayMoveImmutable } from "array-move";

export interface SortableItem {
  value: string;
  key: string | number;
}


export interface FactoryProps {
  key: string;

  index: number;
  idx: number;

  item: SortableItem;
  value?: any;
}

const Factory: WrappedComponentFactory<FactoryProps> = (
  props: FactoryProps
) => (
  <List.Item
    key={props.key}
    style={{
      margin: 5,
      backgroundColor: "rgba(0,0,0,.04)",
    }}
  >
    <Tag color="blue">{props.idx + 1}</Tag> {props.value}
  </List.Item>
);

// I don't know, sortable-hoc is pretty nasty. Might want to use something
// else.
// https://github.com/clauderic/react-sortable-hoc/issues/128
// https://github.com/clauderic/react-sortable-hoc/issues/529
const SortableListItem = SortableElement<FactoryProps>(Factory);

interface SortableListContainerProps {
  items: any[];
}

const SortableListContainer = SortableContainer<SortableListContainerProps>(
  ({ items }: SortableListContainerProps) => (
    <List bordered size="small">
      {items.map((item: any, index: number) => (
        <SortableListItem
          key={`item-${item?.key}`}
          index={index}
          idx={index}
          item={item}
          value={item?.value}
        />
      ))}
    </List>
  )
);

interface SortableListProps extends SortableContainerProps {
  items: any[];
  onChange?: Function;
  loading?: boolean;
}

/** @deprecated Avoid using this, as it relies upon a deprecated dependency that we want to remove */
export const SortableList = SortableContainer<SortableListProps>(
  ({ items, onChange, shouldCancelStart }: SortableListProps) => {
    const onSortEnd = ({ oldIndex, newIndex }: any) => {
      if (onChange) {
        onChange(arrayMoveImmutable(items, oldIndex, newIndex));
      }
    };

    return (
      <SortableListContainer
        items={items}
        onSortEnd={onSortEnd}
        shouldCancelStart={shouldCancelStart}
      />
    );
  }
);
