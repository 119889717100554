import React, { useMemo, useState } from "react";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-enterprise";
import BUC, { useBatchUnderConstruction } from "../../../api/BatchUnderConstruction/index.js";
import InitBatchUnderConstructionModal from "./modals/InitBatchUnderConstructionModal.js";
import { SplitPane } from "@fifthsun/ui";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import { useGridRefPlugin, useShowNoRowsOverlayPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { lineItemColumnDefs } from "../../../components/LineItem/DataGrid/lineItemColumnDefs.js";
import useBUCServerSideDatasource from "./hooks/useBUCServerSideDatasource.js";
import NoLineItemsOverlay from "./components/NoLineItemsOverlay.js";
import { CreateBatchUnderConstructionModal } from "./modals/CreateBatchModal/index.js";

import "./index.scss";

const PreviewHeader = () => {
  const bucState = useBatchUnderConstruction();
  const [initializeBatchModalVisible, setInitializeBatchModalVisible] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const resetTxt = bucState.isInitialized ? "Reset" : "Initialize";

  return (
    <div id="batch-preview-header-container">
      <InitBatchUnderConstructionModal
        visible={initializeBatchModalVisible}
        close={() => setInitializeBatchModalVisible(false)}
      />
      <CreateBatchUnderConstructionModal
        isVisible={showCreateModal}
        hide={() => setShowCreateModal(false)}
      />
      <div id="first-row" className="header-row">
        <div className="page-title">
          <span className="prefix">Preview</span>
          <span className="suffix">Batch</span>
        </div>
        <div id="facility" className="readonly-field">
          <label htmlFor="facility-value" className="readonly-field-name">Facility</label>
          <span id="facility-value" className="readonly-field-value">{ bucState.facilityId }</span>
        </div>
        <div id="ship-by-date" className="readonly-field">
          <label htmlFor="ship-by-date-value" className="readonly-field-name">Ship By Date</label>
          <span id="ship-by-date-value" className="readonly-field-value">
            { bucState.shipByDate?.toLocaleDateString() }
          </span>
        </div>
        <div id="actions">
          <button id="reset-btn" type="button" onClick={() => setInitializeBatchModalVisible(true)}>
            { resetTxt }
          </button>
          <button id="auto-btn" type="button">
            Auto
          </button>
          <button id="create-btn" type="button" onClick={() => setShowCreateModal(true)}>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export const BatchPreviewPage = () => {
  const gridRefPlugin = useGridRefPlugin();
  const showNoRowsPlugin = useShowNoRowsOverlayPlugin();
  const plugins = [gridRefPlugin, showNoRowsPlugin];

  const datasource = useBUCServerSideDatasource(gridRefPlugin);

  const gridProps = useMemo<DataGridProps>(() => ({
    columnDefs: lineItemColumnDefs,
    rowModelType: "serverSide",
    serverSideDatasource: datasource,
    getRowId: (param) => param.data.ziftId,
    noRowsOverlayComponent: NoLineItemsOverlay,
    getContextMenuItems: (params: GetContextMenuItemsParams): Array<string | MenuItemDef> => {
      const results: Array<string | MenuItemDef> = [...(params.defaultItems ?? [])];

      const selected = params.api.getSelectedNodes();

      results.push({
        name: "Remove Selected LineItem(s)",
        disabled: selected.length === 0,
        tooltip: "Removes the selected Line Item(s) from the Batch Under Construction",
        action: () => {
          const ziftIds = selected.map((_) => _.data.ziftId);
          BUC.remove(ziftIds);
        }
      } as MenuItemDef);

      return results;
    },
    animateRows: true,
  }), [datasource]);

  return (
    <SplitPane direction="vertical">
      <PreviewHeader />
      <DataGrid {...gridProps} plugins={plugins} />
    </SplitPane>
  );
};

export default BatchPreviewPage;
