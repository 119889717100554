import React from "react";
import { Col as AntCol, ColProps as AntColProps } from "antd";

export interface ColProps extends AntColProps {
  size?: "large" | "small";
  spans?: Record<string, number>;
}

const sizeMap = {
  large: {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 6,
  },
  small: {
    xs: 8,
    sm: 6,
    md: 6,
    lg: 4,
    xl: 4,
    xxl: 2,
  },
} as Record<string, any>;

export const Col = (props: ColProps) => {
  const { 
    children, 
    spans, 
    size = "large", 
    ...otherProps 
  } = props;

  const map = {
    ...sizeMap[size || "large"],
    ...spans,
  };
  return (
    <AntCol
      xs={map.xs}
      sm={map.sm}
      md={map.md}
      lg={map.lg}
      xl={map.xl}
      xxl={map.xxl}
       
      {...otherProps}
    >
      {children}
    </AntCol>
  );
};
