import React from "react";
import { ErrorBoundary } from "@sentry/react";
import { DemandReportTable } from "../../../components/DemandReport/Table/index.js";
import { ZiftStatus } from "../../../api/core/index.js";

export const SummaryDemandReportPage = () => {
  const ziftStatus = [
    ZiftStatus.Pending, ZiftStatus.Active
  ];

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <DemandReportTable ziftStatus={ziftStatus} />
    </ErrorBoundary>
  );
};
