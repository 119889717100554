/* import this first! It should only be imported once in the entire application, 
 * and it must be imported prior to any Dependency Injection via tsyringe!
*/
import "reflect-metadata";

/** Import the container file next; it will configure the Dependency Injection Container */
import "./container.js";

import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration, captureConsoleIntegration } from "@sentry/browser";
import reportWebVitals from "./reportWebVitals.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

// https://madengine.atlassian.net/wiki/x/BIAPE
const sentryEnvironment = import.meta.env.REACT_APP_MAD_ENV;

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN as string,
  integrations: [browserTracingIntegration(), captureConsoleIntegration()],
  tracesSampleRate: 0.7,
  environment: sentryEnvironment,
  release: import.meta.env.REACT_APP_VERSION as string,
});

import App from "./app.js";
const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(<App />);
} else {
  console.error("No 'root' element found to render the React Application");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
