import React from "react";
import { Button } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

interface LoginProps {
   
  type?: any;
}

export const DeprecatedAuthButton = ({ type = Button }: LoginProps) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const loading = inProgress !== "none" && !isAuthenticated;

  if (isAuthenticated) {
    return React.createElement(
      type as any,
      {
        onClick: () => instance.logout(),
        loading: loading ? loading.toString() : undefined,
        to: "",
      },
      "Logout"
    );
  }

  return React.createElement(
    type as any,
    {
      onClick: () =>
        instance.loginPopup({
          scopes: ["user.read"],
          prompt: "select_account",
        }),
      loading: loading ? loading.toString() : undefined,
      to: "",
    },
    "Login"
  );
};
