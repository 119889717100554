import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { ScheduleDetail } from "../../../components/index.js";
import { StringParam, useQueryParam } from "@fifthsun/ui/api/queryParams";

export const SchedulePage = () => {
  const { date: dateStr, facilityId } = useParams(); // url path parameters
  const [activeBatchId, setActiveBatchId] = useQueryParam("id", StringParam);

  const date = useMemo(() => dateStr ? new Date(dateStr) : undefined, [dateStr]);

  return (
    <ScheduleDetail 
      activeBatchId={activeBatchId} 
      setActiveBatchId={setActiveBatchId} 
      date={date} 
      facilityId={facilityId} 
    />
  );
};
