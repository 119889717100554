import { ScheduleIdentifier } from "../Schedules/index.js";
import { deserialize, DeserializationSchema } from "@fifthsun/ui/utils";
import { FloorviewSort, deserializeFloorviewSortsArray } from "./common.js";

export interface GetFloorviewSorts {
  scheduleId: string;
  isDefault: boolean; // is it the default sort, or a custom one?
  sorts: Array<Partial<FloorviewSort>>
}

export const GetFloorviewSortsDeserializationSchema: Readonly<DeserializationSchema> = 
  Object.freeze({
    sorts: deserializeFloorviewSortsArray
  });

export const deserializeGetFloorviewSorts = 
  deserialize<GetFloorviewSorts>(GetFloorviewSortsDeserializationSchema);

export type GetFloorviewSortsVariables = ScheduleIdentifier;
