import React, { useState } from "react";
import { Divider, Steps } from "antd";
import { D365SyncStepOne } from "./components/D365SyncStepOne.js";
import { D365SyncStepTwo } from "./components/D365SyncStepTwo.js";
import { D365SyncStepThree } from "./components/D365SyncStepThree.js";

const steps = [
  {
    title: 'Provide Identifiers',
    content: <div>1</div>,
  },
  {
    title: 'Select Orders to Sync',
    content: <div>2</div>,
  },
  {
    title: 'Review Sync Results',
    content: <div>3</div>,
  },
];

export const SyncPage = () => {
  const [current, setCurrent] = useState(0);
  const [orderIdentifiers, setOrderIdentifiers] = useState(Array<string>());
  const [ziftIds, setZiftIds] = useState(Array<string>());

  const onFinishStepOne = (ids: string[]) => {
    setOrderIdentifiers(ids);
    setCurrent(1);
  };

  const onFinishStepTwo = (ids: string[]) => {
    setZiftIds(ids);
    setCurrent(2);
  };

  const onFinishStepThree = () => {
    setCurrent(0);
    setOrderIdentifiers([]);
    setZiftIds([]);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <div style={{ margin: 60 }}>
        <Steps
          current={current}
          items={items}
        />
        <Divider />
        {current === 0 && (<D365SyncStepOne onFinish={onFinishStepOne} />)}
        {current === 1 && (<D365SyncStepTwo onFinish={onFinishStepTwo} ids={orderIdentifiers} />)}
        {current === 2 && (<D365SyncStepThree onFinish={onFinishStepThree} ids={ziftIds} />)}
      </div>
    </>
  );
};
