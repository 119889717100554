import React, { useMemo } from "react";

import { default as _AntdText, TextProps as AntdTextProps } from "antd/lib/typography/Text.js";
const AntdText = _AntdText as unknown as typeof _AntdText.default;

export interface TextProps extends Partial<Pick<AntdTextProps, "strong" | "copyable">> {
  style?: any;
  small?: boolean;
  tiny?: boolean;
  children: any;
}

export const Text = React.memo(
  ({ style, small, tiny, strong, copyable, children }: TextProps) => {
    const newStyle = useMemo(() => {
      if (small) {
        return { ...style, fontSize: 12 };
      }

      if (tiny) {
        return { ...style, fontSize: 9 };
      }

      return style;
    }, [small, style, tiny]);

    return (
      <AntdText style={newStyle} strong={strong} copyable={copyable}>
        {children}
      </AntdText>
    );
  }
);

Text.displayName = "Text";
