import React, { useCallback, useEffect, useState } from "react";
import { INoRowsOverlayParams } from "ag-grid-community";
import BUC from "../../../../api/BatchUnderConstruction/index.js";

export const NoLineItemsOverlay = (_props: INoRowsOverlayParams) => {
  const [initialized, setInitialized] = useState<boolean>(BUC.isInitialized());

  const onInitOrReset = useCallback(() => {
    setInitialized(BUC.isInitialized());
  }, [setInitialized]);

  useEffect(() => {
    BUC.addEventListener("init", onInitOrReset);
    BUC.addEventListener("reset", onInitOrReset);
    return () => {
      BUC.removeEventListener("init", onInitOrReset);
      BUC.removeEventListener("reset", onInitOrReset);
    };
  }, [onInitOrReset]);

  return (
    <div> 
      { (initialized && (
        <>
          <h3>Start adding LineItems to the Batch</h3>
        </>
      )) || (
        <>
          <h3>Initialize the Batch To Proceed</h3>
        </>
      ) }
    </div>
  );
};

export default NoLineItemsOverlay;
