import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { TenantProvider, AuthenticationProvider, UserInfoProvider, FeatureFlagsServiceProvider, PageLoader, useFeatureFlag } from "@fifthsun/ui";
import { LoginPage, LogoutPage, LoginCallbackPage, LogoutCallbackPage } from "@fifthsun/ui/pages";
import { IntlProvider } from "react-intl";
import { LicenseManager } from "ag-grid-enterprise";
import { Page } from "./components/Page/index.js";
import { store } from "./redux/store.js";
import { Provider } from 'react-redux';
import { FF_MAUTHRA_ENABLE } from "@fifthsun/ui/api/mauthra/oauth2";
import { DeprecatedAuthenticated, DeprecatedAuthProvider } from "./components/DeprecatedAuth/index.js";
import { ToastContainer } from "react-toastify";

// Set ag-grid enterprice license. I don't consider this a secret :)
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-063962}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Mad_Engine_Global}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Mad_Engine_Platform}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Mad_Engine_Platform}_need_to_be_licensed___{Mad_Engine_Platform}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_September_2025}____[v3]_[01]_MTc1ODkyNzYwMDAwMA==9fe7a6d42cdb58b66d08a53dc1beab5e"
);

function App() {
  const mauthraFF = useFeatureFlag(FF_MAUTHRA_ENABLE);
  const mauthraEnabled = mauthraFF?.enabled ?? false;

  return (
    <React.StrictMode>
      <IntlProvider locale="en">
        <ToastContainer
          newestOnTop={false}
          hideProgressBar={false}
          autoClose={5000}
          pauseOnHover
          pauseOnFocusLoss
          closeOnClick={false}
          theme="light"
          position="top-right"
          draggable={false} // draggable = drag/swipe to remove toast
        />

        <Provider store={store}>
          <PageLoader>
            <DeprecatedAuthProvider>
              <FeatureFlagsServiceProvider>
                <TenantProvider>
                  <AuthenticationProvider>
                    <UserInfoProvider>
                      <Router>
                        <Routes>
                          { /* Public Authentication Routes */ }
                          <Route path="/login" element={<LoginPage />} />
                          <Route path="/logout" element={<LogoutPage />} />
                          <Route path="/login/callback" element={
                            (mauthraEnabled && (
                              <LoginCallbackPage />
                            )) || (
                              <>Logged In!</>
                            )}
                          />

                          <Route path="/logout/callback" element={
                            (mauthraEnabled && (
                              <LogoutCallbackPage />
                            )) || (
                              <>Logged Out!</>
                            )}
                          />

                          { /* Deprecated Authentication Routes */ }
                          <Route path="/login/msal/callback" />

                          { /* Other Public + Private Routes */}
                          <Route
                            path="/*"
                            element={
                              <DeprecatedAuthenticated>
                                <Page />
                              </DeprecatedAuthenticated>
                            }
                          />

                          { /* TO DO: Error Routes (404 Not Found) */}
                        </Routes>
                      </Router>
                    </UserInfoProvider>
                  </AuthenticationProvider>
                </TenantProvider>
              </FeatureFlagsServiceProvider>
            </DeprecatedAuthProvider>
          </PageLoader>
        </Provider>
      </IntlProvider>
    </React.StrictMode>
  );
}

export default App;
