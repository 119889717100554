import CoreApi from "../CoreApi.js";
import { transformResponse, transformErrorResponse } from "@fifthsun/ui/utils";
import { GetSchedules, GetSchedulesVariables, deserializeGetSchedules } from "./GetSchedules.js";

// Extends the CoreApi with /schedules endpoints
export const SchedulesApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSchedules: builder.query<GetSchedules, GetSchedulesVariables>({
      query: (params) => ({
        url: '/schedules',
        method: "POST",
        body: {
          ...params,
          filter: params?.filter,
          sort: params?.sort
        }
      }),
      transformResponse: transformResponse(deserializeGetSchedules),
      transformErrorResponse: transformErrorResponse("Core", "getSchedules"),
    }),
  }),
});

export default SchedulesApi;
