import React, { useCallback, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import PageHeader from "./PageHeader/index.js";
import ErrorBoundaryFallback from "./ErrorBoundaryFallback.js";
import "./index.scss";
import { Route, Routes } from "react-router";

import {
  HomePage,
  SummaryDemandReportPage,
  BatchDashboardPage, BatchManagePage, BatchPreviewPage, BinDemandReportPage,
  InsightsDashboardPage, InsightsExplorePage, LineItemDashboardPage, LineItemDemandPage,
  LineItemManagePage, OrderDashboardPage, OrderManagePage, OutOfStockDemandReportPage,
  PrintedDemandReportPage, Scheduler, SettingsPage, StoreManagePage, WaypointDemandReportPage,
  DetailDemandReportPage,
  ScansDataGridPage,
  BlankDemandReportPage,
  LineItemReportPage,
  BatchReportPage,
  OrderReportPage,
  SyncPage,
  DuplicateOrderReportPage,
  ProductVariantPage,
  SelfHelpPage,
  PendingPrintDownloadReportPage,
  CompletedWaypointReportPage,
  PrintWaypointReportPage
} from "../../pages/index.js";

import { ProgressDemandReportPage } from "../../pages/DemandReport/ProgressDemandReportPage/index.js";
import { CanceledReportPage } from "../../pages/DemandReport/CanceledDemandReportPage/index.js";
import { PullReportPage } from "../../pages/DemandReport/PullReportPage/index.js";
import { PrintReportPage } from "../../pages/DemandReport/PrintReportPage/index.js";

import 'react-toastify/dist/ReactToastify.css';
import { ActivePrintReportPage } from "../../pages/DemandReport/ActivePrintReportPage/index.js";
import { AllocationReportPage } from "../../pages/DemandReport/AllocationReportPage/index.js";
import { AllocationSummaryPage } from "../../pages/DemandReport/AllocationSummaryPage/index.js";
import { PendingPrintReportPage } from "../../pages/DemandReport/PendingPrintDemandReportPage/index.js";

import { AuthenticatedRoutes } from "@fifthsun/ui/react-router";
import { IANAZone, Zone } from "luxon";
import { isString } from "lodash";
import { TimeZoneProvider } from "@fifthsun/ui";

export const Page = () => {
  // TO DO: Preserve selection via localStorage
  const [userTimezone, setUserTimezone] = useState<Zone | string | undefined>(undefined);

  const onTimezoneSelected = useCallback((tz: Zone | string | undefined) => {
    if (isString(tz)) {
      const zone = new IANAZone(tz);
      if (!zone.isValid) { throw new Error(`Invalid Timezone Selected: "${tz}"`); }
      setUserTimezone(zone);
    } else if (tz) {
      setUserTimezone(tz);
    } else {
      setUserTimezone(undefined);
    }
  }, [setUserTimezone]);


  return (
    <div id="mad-page-container">
      <TimeZoneProvider zone={userTimezone}>
        <PageHeader setTimeZone={onTimezoneSelected} />
        <div id="mad-page-content">
          <ErrorBoundary fallback={ErrorBoundaryFallback}>
            <Routes>
              <Route
                path="/"
                element={<HomePage />}
              />

              <Route element={<AuthenticatedRoutes />}>
                <Route
                  path="/orders"
                  element={<OrderManagePage />}
                />
                <Route
                  path="/insights/orders"
                  element={<OrderDashboardPage />}
                />
                <Route
                  path="/line_items"
                  element={<LineItemManagePage />}
                />
                <Route
                  path="/insights/line_items"
                  element={<LineItemDashboardPage />}
                />
                <Route
                  path="/insights/demand"
                  element={<LineItemDemandPage />}
                />
                <Route
                  path="/insights/batches"
                  element={<BatchDashboardPage />}
                />
                <Route
                  path="/batches"
                  element={<BatchManagePage />}
                />
                <Route
                  path="/batches/preview"
                  element={<BatchPreviewPage />}
                />
                <Route
                  path="/scans/*"
                  element={<ScansDataGridPage />}
                />
                <Route
                  path="/schedules/*"
                  element={<Scheduler />}
                />
                <Route
                  path="/settings/*"
                  element={<SettingsPage />}
                />
                <Route
                  path="/stores"
                  element={<StoreManagePage />}
                />
                <Route
                  path="/insights"
                  element={<InsightsDashboardPage />}
                />
                <Route
                  path="/insights/explore"
                  element={<InsightsExplorePage />}
                />
                <Route
                  path="/reports/demand/summary"
                  element={<SummaryDemandReportPage />}
                />
                <Route
                  path="/reports/demand/printed"
                  element={<PrintedDemandReportPage />}
                />
                <Route
                  path="/reports/demand/oos"
                  element={<OutOfStockDemandReportPage />}
                />
                <Route
                  path="/reports/demand/bin"
                  element={<BinDemandReportPage />}
                />
                <Route
                  path="/reports/demand/waypoint"
                  element={<WaypointDemandReportPage />}
                />
                <Route
                  path="/reports/demand/detail"
                  element={<DetailDemandReportPage />}
                />
                <Route
                  path="/reports/demand/progress"
                  element={<ProgressDemandReportPage />}
                />
                <Route
                  path="/reports/demand/canceled"
                  element={<CanceledReportPage />}
                />
                <Route
                  path="/reports/pull"
                  element={<PullReportPage />}
                />
                <Route
                  path="/reports/batch"
                  element={<BatchReportPage />}
                />
                <Route
                  path="/reports/print"
                  element={<PrintReportPage />}
                />
                <Route
                  path="/reports/active_print"
                  element={<ActivePrintReportPage />}
                />
                <Route
                  path="/reports/blanks"
                  element={<BlankDemandReportPage />}
                />
                <Route
                  path="/reports/allocations/details"
                  element={<AllocationReportPage />}
                />
                <Route
                  path="/reports/allocations/summary"
                  element={<AllocationSummaryPage />}
                />
                <Route
                  path="/reports/ops/line_item"
                  element={<LineItemReportPage />}
                />
                <Route
                  path="/reports/ops/line_item/pending_prints"
                  element={<PendingPrintDownloadReportPage />}
                />
                <Route
                  path="/reports/ops/order"
                  element={<OrderReportPage />}
                />
                <Route
                  path="/reports/ops/order/duplicates"
                  element={<DuplicateOrderReportPage />}
                />
                <Route
                  path="/reports/ops/scans/completed_waypoints"
                  element={<CompletedWaypointReportPage />}
                />
                <Route
                  path="/reports/ops/scans/print_waypoints"
                  element={<PrintWaypointReportPage />}
                />
                <Route
                  path="/reports/pending_print"
                  element={<PendingPrintReportPage />}
                />
                <Route
                  path="/selfhelp/*"
                  element={<SelfHelpPage />}
                />
              </Route>
              <Route
                path="/d365/sync"
                element={<SyncPage />}
              />
              <Route
                path="/d365/product"
                element={<ProductVariantPage />}
              />
            </Routes>
          </ErrorBoundary>
        </div>
      </TimeZoneProvider>
    </div>
  );
};
