import { AgDateFilter, AgGridQueryArgs, AgGridQueryArgs_Options, AgNumberFilter, AgQueryResponse, AgSortOrder, AgTextOrSetFilter } from "../AgGrid.js";
import { Store, deserializeStoresArray } from "./index.js";
import { deserialize, DeserializationSchema } from "@fifthsun/ui/utils";

export interface GetStores extends AgQueryResponse<Store> {}

export const GetStoresDeserializationSchema: Readonly<DeserializationSchema> = Object.freeze({
  rows: deserializeStoresArray
});

export const deserializeGetStores = deserialize<GetStores>(GetStoresDeserializationSchema);

export interface StoreFilterInputV2 {
  name?: AgTextOrSetFilter | null;
  batchId?: AgTextOrSetFilter | null;
  priority?: AgNumberFilter | null;
  createdAt?: AgDateFilter | null;
  updatedAt?: AgDateFilter | null;
}

export interface StoreSortInputV2 {
  createdAt?: AgSortOrder | null;
  updatedAt?: AgSortOrder | null;
  priority?: AgSortOrder | null;
  name?: AgSortOrder | null;
  orderStats?: StoreSortInputV2_OrderStats | null;
}

export interface StoreSortInputV2_OrderStats {
  ordersCount?: AgSortOrder | null;
  lineItemsCount?: AgSortOrder | null;
  activeCount?: AgSortOrder | null;
  pendingCount?: AgSortOrder | null;
  completeCount?: AgSortOrder | null;
  canceledCount?: AgSortOrder | null;
  invalidCount?: AgSortOrder | null;
}

export interface GetStoresVariables extends AgGridQueryArgs<GetStoresVariables_Options, StoreFilterInputV2> {}

export interface GetStoresVariables_Options extends AgGridQueryArgs_Options {
  include?: GetStoresVariables_Options_Includes[] | null;
  orderStats?: GetStoresVariables_Options_OrderStats | null;
}

export type GetStoresVariables_Options_Includes = "order_stats" | "tag_list";

export interface GetStoresVariables_Options_OrderStats {
  from?: Date | null;
  to?: Date | null;
}
