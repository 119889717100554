import { DeserializationFunc } from "@fifthsun/ui/utils";
import { Picksheet, deserializePicksheet } from "./common.js";

export interface CreatePicksheetVariables {
  lineItemIds: string[];
}

export type CreatePicksheet = Picksheet;
export const deserializeCreatePicksheet = 
  deserializePicksheet as DeserializationFunc<CreatePicksheet>;
