import { AliasMap } from "./AliasMap.js";
import { isString } from "lodash";

/**
 * Returns a copy of the specified path, but where each segment of the path has been replaced
 * with its corresponding alias from the AliasMap. If no alias is found for a segment, then it
 * is copied over to the resulting path unmodified.
 * @param map 
 * @param path 
 * @returns 
 */
export function lookupAliases(map: AliasMap | undefined, path: string[]) {
  if (!map) { return [...path]; }

  const aliasedPath: string[] = [];

  let curr = map;
  let p: string;
  let v: AliasMap | string | undefined;
  let i = 0;

  for (; i < path.length; i++) {
    p = path[i];
    v = curr[p];
    if (!v) {
      break;
    } else if (isString(v)) {
      aliasedPath.push(v);
      i++;
      break;
    } else {
      curr = v;
      aliasedPath.push(curr[""] ?? path[i]);
    }
  }

  for (; i < path.length; i++) {
    aliasedPath.push(path[i]);
  }

  return aliasedPath;
}

export default lookupAliases;
