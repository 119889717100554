import { useMemo } from "react";

import { 
  AgGridDatasourceProps, GetStores, GetStoresVariables, GetStoresVariables_Options, 
  GetStoresVariables_Options_Includes, StoresApi, useAgGridDatasource 
} from "../../../api/core/index.js";
import { Zone } from "luxon";
import { useTimeZone } from "@fifthsun/ui";

export interface StoreDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetStoresVariables_Options, GetStoresVariables, GetStores>, "endpoint" | "timeZone"> {
    include?: GetStoresVariables_Options_Includes[] | null | undefined;
    timeZone?: Zone | string;
  }

export const DefaultStoreDatasourceProps = Object.freeze<StoreDatasourceProps>({
  options: {
    count: true,
    include: ["tag_list"]
  }
});

export function useStoreDatasource(props: StoreDatasourceProps) {
  const timeZone = useTimeZone();
  const activeTimeZone = props?.timeZone ?? timeZone;
  const datasourceProps = useMemo<AgGridDatasourceProps<GetStoresVariables_Options>>(() => ({
    timeZone: activeTimeZone,
    ...DefaultStoreDatasourceProps,
    ...props,
    endpoint: StoresApi.endpoints.getStores,
    options: {
      ...DefaultStoreDatasourceProps.options,
      ...props.options
    },
  }), [props, activeTimeZone]);

  return useAgGridDatasource<GetStoresVariables_Options>(datasourceProps);
}

export default useStoreDatasource;
